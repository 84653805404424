import React, { Component } from "react";
import { Link } from 'react-router-dom';    
import axios from "axios";
import PrintComponents from "react-print-components";

import moment from 'moment';
export default class clientPrintPdf extends Component { 
 constructor(props) {
    super(props); 
      this.state = { 
          clientType: '',
          clientNo: '',
          companyName: '',
          companyNumber: '',
          companyWebsite: '',
          clientTypeError: '',
          companyNameError: '',
          companyNumberError: '',
          companyWebsiteError: '',


          firstName: '',
          middleName: '',
          lastName: '',
          gender: '',
          title: '',
          dob: '',
          nationality: '',
          firstNameError: '',
          middleNameError: '',
          lastNameError: '',
          genderError: '',
          titleError: '',
          dobError: '',
          nationalityError: '',


          companyRegistration: '',
          vatRef: '',
          tin: '',
          taxRef: '',
          companyType: '',
          companyRegistrationError: '',
          vatRefError: '',
          tinError: '',
          taxRefError: '',
          companyTypeError: '',


          regAddress: '',
          postCode: '',
          city: '',
          country: '',
          createdBy: '',
          createdAt: '',
          lastModificationUser: '',
          lastDateCollection: '',
          lastDateRecollection: '',
          regAddressError: '',
          postCodeError: '',
          cityError: '',
          countryError: '',
          createdByError: '',
          createdAtError: '',
          lastModificationUserError: '',
          lastDateCollectionError: '',
          lastDateRecollectionError: '',


          phoneNumber: '',
          email: '',
          refBy: '',
          phoneNumberError: '',
          emailError: '',
          refByError: '',


          successMessage: '',
          success: false,
          errorMessage: '',
          error: false,
          emailShow: true,
          mobileShow: false,
          buttonData: false,
          Details: '',
          popularCategoryId: '',
          UpdateData: false,
          EditDetails: [],
          showSweetAlert: false,
          requestedId: '',
          filterDetails: [],
          showItems: '',
          modalDialog: '',
          modalStatus: false,
          sort: {
              column: null,
              direction: 'desc',
          },
          pageLimit: 10,
          pageCount: 0,
          totalCount: 0,
          showingFrom: 0,
          showingTo: 0,
          paginationCountText: '',
          delteId: '',
          token:'',
           validationErrors:{
              clientTypeError: '',
              companyNameError: '',
              companyNumberError: '',
              companyWebsiteError: '',
              firstNameError: '',
              middleNameError: '',
              lastNameError: '',
              genderError: '',
              titleError: '',
              dobError: '',
              nationalityError: '',
              companyRegistrationError: '',
              vatRefError: '',
              tinError: '',
              taxRefError: '',
              companyTypeError: '',
              regAddressError: '',
              postCodeError: '',
              cityError: '',
              countryError: '',
              createdByError: '',
              createdAtError: '',
              lastModificationUserError: '',
              lastDateCollectionError: '',
              lastDateRecollectionError: '',
              phoneNumberError: '',
              emailError: '',
              refByError: ''
          },
          companybox:false,
          createdAt:'',
          updatedAt:'',
          updatedBy:'',
          status:'',
          ProfileImg: null,
          profileImageInDB: '',
          userId:'', 
          profileImageUpload:false,
          pagelaoder:true 
      }  
    };   
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            //console.log(checkToken.name,'d')
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
        }
        
        if (this.props.location.state) {
            //console.log("===================="+this.props.location.state.clientId); 
            //console.log('++++++++++++++++++++', this.props.match.params.clientId)
            
        }else{
          this.props.history.push(`/client`);
        }
        this.getclientList()

    }


    async getclientList()
    {   
        if(this.props.location.state)
        {
            let clientId=this.props.location.state.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
        
            let accessToken = checkToken.access_token;
        
          //get listing
          await axios.get(`${window.$URL}client/edit/`+clientId,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              console.log(response.data.data, 'response by edit')
              if(response.data.status){  
                if(response.data.data.client_type === "1" || response.data.data.client_type === 1)
                {
                     //console.log('corporate_master')
                    this.setState({pagelaoder:false}); 
                    this.setState({
                                clientType: response.data.data.client_type,
                                clientNo: response.data.data.client_no,
                                companyName: response.data.data.get_corporate_master.company_name,
                                companyNumber: response.data.data.get_corporate_master.company_number,
                                companyWebsite: response.data.data.get_corporate_master.company_website, 
                                firstName: response.data.data.get_corporate_signatories.first_name,
                                middleName: response.data.data.get_corporate_signatories.middle_name,
                                lastName: response.data.data.get_corporate_signatories.surname,
                                gender: response.data.data.get_corporate_signatories.gender,
                                title: response.data.data.get_corporate_signatories.title,
                                
                                dob: response.data.data.get_corporate_signatories.dob,
                                nationality: response.data.data.get_corporate_signatories.nationality,
                                companyRegistration: response.data.data.get_corporate_general.registration_date,

                                // tin: response.data.data.get_corporate_signatories.nationality,
                                taxRef: response.data.data.get_corporate_general.tax_reference_jurisdiction,
                                vatRef: response.data.data.get_corporate_general.vat_reference,
                                tin: response.data.data.get_corporate_general.tin_tic,
                                regAddress: response.data.data.get_corporate_address.address_1,
                                postCode : response.data.data.get_corporate_address.post_code,
                                city : response.data.data.get_corporate_address.city,
                                country : response.data.data.get_corporate_address.country,
                                email:response.data.data.get_corporate_contact.email,
                                phoneNumber:response.data.data.get_corporate_contact.contact_number,
                                refBy : response.data.data.referred_by,
                                companybox:true,
                                companyType:response.data.data.get_corporate_general.type_of_company,
                                createdBy:response.data.data.created_by,
                                updatedBy:response.data.data.updated_by,
                                updatedAt:moment(response.data.data.updated_at).format("YYYY-MM-DD"),
                                createdAt:moment(response.data.data.created_at).format("YYYY-MM-DD"),
                                status:response.data.data.status,
                                prevProfileImg:response.data.data.get_user.profile_image,
                                userId:response.data.data.get_user.id,
                    })
                }else if(response.data.data.client_type === "2" || response.data.data.client_type === 2)
                { 
                  this.setState({pagelaoder:false});
                    this.setState({
                                 clientNo: response.data.data.client_no,
                                clientType: response.data.data.client_type, 
                                firstName: response.data.data.get_individual_master.first_name,
                                middleName: response.data.data.get_individual_master.middle_name,
                                lastName: response.data.data.get_individual_master.surname,
                                gender: response.data.data.get_individual_master.gender,
                                title: response.data.data.get_individual_master.title,
                                dob: response.data.data.get_individual_master.dob,
                                nationality: response.data.data.get_individual_master.nationality,

                                regAddress: response.data.data.get_individual_address.address_1,
                                postCode : response.data.data.get_individual_address.post_code,
                                city : response.data.data.get_individual_address.city,
                                country : response.data.data.get_individual_address.country,

                                
                                companyRegistration: response.data.data.get_individual_general.registration_date,
                                vatRef: response.data.data.get_individual_general.vat_reference,
                                tin: response.data.data.get_individual_general.tin_tic,
                                taxRef: response.data.data.get_individual_general.tax_reference_jurisdiction,
                                companyType:response.data.data.get_individual_general.type_of_company,

                                email:response.data.data.get_individual_contact.email,
                                phoneNumber:response.data.data.get_individual_contact.contact_number, 
                                createdBy:response.data.data.created_by,
                                
                                updatedBy:response.data.data.updated_by,
                                updatedAt:moment(response.data.data.updated_at).format("YYYY-MM-DD"),
                                createdAt:moment(response.data.data.created_at).format("YYYY-MM-DD"),
                                status:response.data.data.status,
                                prevProfileImg:response.data.data.get_user.profile_image,
                                userId:response.data.data.get_user.id, 
                      })
                }  
            }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }

        }
    } 
  render() { 
    return (
      <> 
         <div className="receipt-print-wrap"> 
          <table style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr style={{ textAlign:'center' }}>
                  <td >
                    <img src={require('../../assets/images/logo.png')} style={{ width:"150px" }}alt="" className="print-logo" />
                    <div style={{ margin:"15px 0px" }}> 
                      <p><label className="print-label">Client Number </label>: {this.state.clientNo}</p>
                    </div>  
                  </td> 
              </tr>
            </tbody>
          </table>
          <h2 style={{ background:"#0097d4",color:"#fff",padding:"15px",marginBottom:"25px",textAlign:"center" }}> Client Details </h2>
          <table className="print-tbl-border" style={{ width:"100%",border:"1px solid #ccc",padding:"15px",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Client Type: </label>
                  </td>
                  <td>
                     <p>{this.state.clientType === 1 ?"Corporate":"Individual"}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Client Name: </label>
                  </td>
                  <td>
                     <p>{this.state.title} {this.state.firstName} {this.state.middleName} {this.state.lastName}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Gender : </label>
                  </td>
                  <td>
                     <p>{this.state.gender===1?"Male":"Female"}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">DOB : </label>
                  </td>
                  <td>
                     <p>{this.state.dob}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Nationality : </label>
                  </td>
                  <td>
                     <p>{this.state.nationality }</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Mobile Number  : </label>
                  </td>
                  <td>
                     <p>{this.state.phoneNumber}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Email Address  : </label>
                  </td>
                  <td>
                     <p>{this.state.email}</p>
                  </td> 
              </tr>
            </tbody>
          </table>  
        </div>  
      </>
    );
  }
}



import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import TopMenu from "./TopMenu"
export default class viewClient extends Component {


    state = {
        document_type: '',
        complementary_information: '',
        confidential: '', 
        document_image:'',
        status:'',
        documentTypeError:'',
        complementaryInformationError: '',
        confidentialError: '', 
        successMessage: '',
        success: false,
        errorMessage: '',
        error: false, 
        buttonData: false,
        Details: '', 
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        isReadOnly:false,
        validationErrors:{
            documentTypeError: ''
        },
        btnLaoder:false,



    }
    async componentDidMount() {


        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            let setReadOnly = false;
            if(checkToken.role == 'Client'){
                setReadOnly = true;
            }
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken,
                isReadOnly:setReadOnly
            })
        }
        if (this.props.match.params.hasOwnProperty('clientId')) {
            
        }
        this.getSpecificDpcument();
    }
    async getSpecificDpcument()
    {
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
           let editId=this.props.match.params.id
        
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
        
            let accessToken = checkToken.access_token;
        
            //get listing
            await axios.get(`${window.$URL}client/client-specific-document/`+editId,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    
                    if(response.data.status){ 
                        
                             
                            this.setState({
                                
                              document_type: response.data.data.document_type,
                              complementary_information: response.data.data.complementary_information,
                              confidential: response.data.data.confidential,
                              user_id: this.props.match.params.clientId,
                              status:response.data.data.status,
                              document_image:response.data.data.document_image,
                                
                            })
                            this.setState({ showLicenseNumber:true})
                        
                    }
                })
                .catch(error => {
                    console.error(error.data)
                }) 
            }  
        }
    }  
    onChange = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
    }
    validate = () => {
        this.setState({

        documentTypeError:'',
        complementaryInformationError: '',
        confidentialError: '',
        });

        let documentTypeError = '';
        let complementaryInformationError = '';
        let confidentialError = '';
        let formvalidateStatus = true;
        if (this.state.document_type.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{documentTypeError:'Please select document type'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{documentTypeError:''}));
        }
        if (this.state.complementary_information.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{complementaryInformationError:'Please entrt information'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{complementaryInformationError:''}));
        }

        if (this.state.confidential.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{confidentialError:'Please select confidential'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{confidentialError:''}));
        } 
       
        return formvalidateStatus
    }
    onSubmit = (e) => {
        //alert('gii');return false;
        e.preventDefault();
        
        const { document_type, complementary_information, confidential, token } = this.state;



        const isValid = this.validate();
        
         if (isValid) {
            //alert('pk');
            this.setState({btnLaoder:true}); 
            var bodyLogoFormData = new FormData();
            bodyLogoFormData.set('document_type', this.state.document_type);
            bodyLogoFormData.set('complementary_information', this.state.complementary_information);
            bodyLogoFormData.set('confidential', this.state.confidential);  
            bodyLogoFormData.set('status', this.state.status); 
            if(this.state.message_document !== null){  
              bodyLogoFormData.append('document_image', this.state.document_image);
            } 
            //return false;
            axios.post(`${window.$URL}client/update-client-documents/${this.props.match.params.id}`, bodyLogoFormData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        })
                        this.setState({btnLaoder:false}); 
                        this.componentDidMount();
                         if( this.state.role === 'Super_Admin' || this.state.role === 'Tem_Member'){
                            this.props.history.push(`/client/client-documents/`+this.props.match.params.clientId);
                        }else{
                            this.props.history.push('/client-portla/personal-documents');
                        } 
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        });
                        this.setState({btnLaoder:false}); 
                    }
                })
                .catch(error => {
                    console.error(error.data);
                    this.setState({btnLaoder:false}); 
                })
        }
    }

    handleDocumentChange = (e) => {
        e.preventDefault();
        const img = e.target.files[0]; 
        this.setState({
            //message_document: URL.createObjectURL(img),
            document_image: img,
        })
    }



    render() {
        const {
            documentTypeError,
            complementaryInformationError,
            confidentialError,

        } = this.state;
        return ( 
            <div className="wrapper">
              <ToastContainer/>           
              <div className="container newContainer mainArea pt-0" id="formpage">
                <div className="row justify-content-center"> 
                  <div className="lefeArea col-md-9 p-0"> 
                    { this.state.role !== 'Client' &&
                        <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_documents"/>
                    } 
                    <div className="content-wrap">
                        <div className="row justify-content-center"> 
                        <div className="col-md-6">   
                            <h4 className="smallheading mb-0">Address Details</h4>
                            <div className="tablebox">
                            <form onSubmit={this.onSubmit}> 
                                <div className="form-group row">
                                <label for="" className="col-sm-4 col-form-label">Document Type <sup>*</sup></label>
                                <div className="col-sm-8">
                                        <select className="form-control w-100" name="document_type" value={this.state.document_type} onChange={this.onChange}  disabled={this.state.isReadOnly} id="exampleFormControlSelect1">
                                            <option value="">Please Select </option>
                                            <option value="Driving Licence Number">Driving Licence Number</option>
                                            <option value="Family Registration Number">Family Registration Number</option>
                                            <option value="Identity Card">Identity Card</option>
                                            <option value="Passport">Passport</option>
                                        </select>
                                        <p className="error">{this.state.validationErrors.documentTypeError}</p>
                                </div>
                                </div> 
                                <div className="form-group row">
                                <label for="" className="col-sm-4 col-form-label">Complementary Info  <sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="text" name="complementary_information" className="form-control" id="inputEmail3" onChange={this.onChange} value={this.state.complementary_information} disabled={this.state.isReadOnly} placeholder="Information" />
                                    <p className="error">{this.state.validationErrors.complementaryInformationError}</p>
                                </div>
                                </div>
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Confidential <sup>*</sup></label> 
                                    <div className="col-sm-8">
                                            <select className="form-control w-100" name="confidential" onChange={this.onChange} value={this.state.confidential}  disabled={this.state.isReadOnly} id="exampleFormControlSelect1">
                                                <option value="">Please Select </option>
                                                <option value="1">Yes</option>
                                                <option value="2">No</option> 
                                            </select>
                                            <p className="error">{this.state.validationErrors.confidentialError}</p>
                                    </div>
                                </div> 
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Document Status <sup>*</sup></label> 
                                    <div className="col-sm-8">
                                            <select className="form-control w-100" name="status" onChange={this.onChange} value={this.state.status}  disabled={this.state.isReadOnly} id="exampleFormControlSelect1">
                                                <option value="">Please Select </option>
                                                <option value="0">Pending Upload</option>
                                                <option value="1">Uploaded</option> 
                                                <option value="2">Verify</option>
                                                <option value="3">Rejected</option>
                                            </select>
                                            <p className="error">{this.state.validationErrors.confidentialError}</p>
                                    </div>
                                </div> 
                                {this.state.role == "Client" && this.state.status !=2  && 
                                    <div className="form-group row mt-0">
                                        <label
                                        for="inputEmail3"
                                        className="col-sm-5 col-form-label"
                                        >
                                        Docuemnt  Upload 
                                        </label>
                                        <div className="col-sm-7">
                                        <input type="file" className="form-control"  onChange={(event) => this.handleDocumentChange(event)}/>
                                        </div>
                                    </div>
                                } 
                                {this.state.document_image != "" && this.state.status != 0 && 
                                    <div className="form-group row mt-0">
                                        <label
                                        for="inputEmail3"
                                        className="col-sm-5 col-form-label"
                                        >
                                        View Uploaded Document 
                                        </label>
                                        <div className="col-sm-7">
                                           
                                            <a href="javascript:void(0)" onClick={()=> window.open(`${window.$DocumentURL}client-document/`+this.state.document_image, "_blank")} className="btn btn-success mt-2" traget="_BLANK">Download</a>
                                            
                                        </div>
                                    </div>
                                }
                                <div className="form-group row text-center mt-2 ml-auto">
                                    <div className="col-sm-12">
                                        <div className="m-auto buttonwrp">
                                            { (this.state.role === 'Super_Admin' || this.state.role === 'Team_Member' )  &&
                                                    <Link
                                                        className="btn btn-primary bluebg"
                                                        to={{
                                                        pathname: `/client/client-documents/${this.props.match.params.clientId}`, 
                                                        clientId:this.props.clientId,
                                                            state: {
                                                                clientId: this.props.clientId 
                                                                
                                                            }
                                                        }}
                                                    > Back</Link>
                                            }
                                            { this.state.role === 'Client' &&
                                                    <Link
                                                        className="btn btn-primary bluebg"
                                                        to={{
                                                        pathname: `/client-portla/personal-documents`,  
                                                        }}
                                                    > Back</Link>
                                            }  
                                            
                                            <button type="submit" className="btn btn-primary"><div className="buttonloader"><div className={`loader_wrap ${ this.state.btnLaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Save</button>
                                             
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div> 
                        </div> 
                        </div>
                    </div>
                  </div>
                  { this.state.role !== 'Client' && 
                    <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_documents"/>
                  }    
                </div>
              </div>
          </div>
        );
    }
}

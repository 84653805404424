import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from './RightMenu';
export default class viewClientsAccount extends Component {

    constructor(props) {
        super(props); 
          this.state = {
            clientAccount: {
                client_mid : '',
                account_type: '',
                currency: '',
                walve_monthly_fees: 0,
                account_name: '',
                opening_details: '',
                remarks: '',
                mandate_instructions: '',
                notification: '',
                status:'',
            },
            balance:0.00, 
            interest:0.00,
            blocked:0.00,
            available:0.00, 
            currencyConfig:[], 
            accountTypes:[],
            mandateInstructions:[], 
        };
     
    }
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
        }
        
        if (this.props.location.state) { 
            this.getConfig();
            this.getAccountDetails();
        }else{
            this.props.history.push(`/client`);
        }
       
    }
    async getConfig()
    {
         
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
        
                //get listing
                await axios.get(`${window.$URL}getConfigs?account_types=true&currency=true&mandate_instructions=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    
                    if (response.data.status) { 
                        if(response.data.data.account_types){
                            this.setState({accountTypes:response.data.data.account_types}); 
                        }
                        if(response.data.data.currency){
                            this.setState({currencyConfig:response.data.data.currency}); 
                        }
                        if(response.data.data.mandate_instructions){
                            this.setState({mandateInstructions:response.data.data.mandate_instructions}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

           

        }
    } 
    async getAccountDetails()
    {
        if(this.props.location.state.accountId)
        {
             
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
        
                //get listing 
                await axios.get(`${window.$URL}client/account-detail`,{
                    params: {
                       
                        account_id        : this.props.location.state.accountId, 

                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                    
                    if (response.data.status) {  
                        this.setState(Object.assign(this.state.clientAccount, {
                            client_mid:response.data.data.client_mid,
                            account_type:response.data.data.account_type,
                            currency:response.data.data.currency,
                            account_name:response.data.data.account_name,
                            opening_details:response.data.data.opening_details,
                            remarks:response.data.data.remarks,
                            mandate_instructions:response.data.data.mandate_instructions,
                            notification:response.data.data.notification,
                            status:response.data.data.status,
                            walve_monthly_fees:response.data.data.walve_monthly_fees, 
                            }));

                        this.setState({
                            balance:response.data.data.balance,
                            interest:response.data.data.interest,
                            blocked:response.data.data.blocked,
                            available:response.data.data.available, 
                        });
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            }

        }
    }
   
    render() {
        const {            
            currencyConfig,mandateInstructions,accountTypes 
        } = this.state;
         
        let currencyOption = currencyConfig.map((item, index) =>
                <option value={item.value}>{item.name}</option>
            );
        let mandateOptions = mandateInstructions.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let accountTypeOptions = accountTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        
        return (
            
            <div className="wrapper">
                  <ToastContainer/>
                {/* <div className="menubox">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                </div> */}
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                            <div className="lefeArea col-md-9 ">
                                <h3 className="heading">Account Details</h3> 
                                <div className="row justify-content-center">
                                    <div className="col-md-6">  
                                            <h4 className="smallheading mt-2 mb-0">General Configuration</h4>
                                            <div className="tablebox"> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Type <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <select name="account_type" readOnly  value={this.state.clientAccount.account_type} className="form-control" id="exampleFormControlSelect1" >
                                                            <option value="0">Select account type</option>
                                                            {accountTypeOptions}
                                                        </select> 
                                                    </div>
                                                </div>  
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Currency <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <select name="currency" readOnly value={this.state.clientAccount.currency} className="form-control" id="exampleFormControlSelect1" >
                                                            <option>Select Currency</option>
                                                            {currencyOption}
                                                        </select> 
                                                    </div>
                                                </div>  
                                            </div> 
                                            <h4 className="smallheading mb-0">Charges and Fees</h4>
                                            <div className="tablebox">   
                                                    <div className="from-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Walve Monthly Fees</label>
                                                        <div className="col-sm-8"> 
                                                            <select name="walve_monthly_fees" className="form-control" onChange={this.onChange} value={this.state.clientAccount.walve_monthly_fees} id="exampleFormControlSelect1" >
                                                                    <option value="0">No</option>
                                                                    <option value="1">Yes</option>
                                                                </select> 
                                                        </div>
                                                    </div>  
                                                </div> 
                                            <h4 className="smallheading mb-0">General Information</h4> 
                                            <div className="tablebox"> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Name <sup>*</sup></label>
                                                    <div className="col-sm-8">
                                                        <input name="account_name" type="text" readOnly value={this.state.clientAccount.account_name} className="form-control" id="inputEmail3" placeholder="Current Account" /> 
                                                    </div>
                                                </div> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Mandate Instructions  </label>
                                                    <div className="col-sm-8">
                                                        <select name="mandate_instructions" readOnly value={this.state.clientAccount.mandate_instructions} className="form-control" id="exampleFormControlSelect1">
                                                            <option>Please select</option>
                                                            {mandateOptions}
                                                        </select> 
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Opening Details</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" name="opening_details" readOnly value={this.state.clientAccount.opening_details} className="form-control" id="inputEmail3" placeholder="Opening Details" />
                                                    </div>
                                                </div> 
                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Remarks  </label>
                                                    <div className="col-sm-8">
                                                        <textarea name="remarks" className="form-control w-100" id="exampleFormControlTextarea1"  readonly defaultValue={this.state.clientAccount.remarks} />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="smallheading mb-0">Notifications</h4>
                                            <div className="tablebox"> 
                                                <div className="row form-group">
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Send Notification On opening the account</label>
                                                    <div className="col-sm-8"> 
                                                        <select name="notification" readOnly value={this.state.clientAccount.notification} className="form-control" id="exampleFormControlSelect1">
                                                            <option value="">Please select</option>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="form-group row text-center mt-2 ml-auto">
                                                    <div className="col-sm-12">
                                                        <div className="btnwrp">
                                                                <Link
                                                                    className="btn btn-primary bluebg"
                                                                    to={{
                                                                    pathname: `/client/client-accounts/${this.state.clientAccount.client_mid}`, 
                                                                    clientId:this.state.clientAccount.client_mid,
                                                                    state: {
                                                                        clientId: this.state.clientAccount.client_mid  
                                                                    }
                                                                    }}
                                                                >
                                                                Back</Link> 
                                                        </div>
                                                    </div>
                                                </div> 
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="smallheading mt-2 mb-0">Account Status</h4>
                                        <div className="tablebox"> 
                                            <div className="row form-group"> 
                                                <div className="col-sm-8"> 
                                                    <select name="status" readOnly value={this.state.clientAccount.status} className="form-control" id="exampleFormControlSelect1">
                                                        <option value="">Please select</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Pending</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="smallheading mt-2 mb-0">Balance</h4>
                                        <div className="tablebox"> 
                                            <div className="row form-group"> 
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Balance</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" readOnly value={this.state.balance} className="form-control" readOnly /> 
                                                    </div>
                                            </div>
                                            <div className="row form-group"> 
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Interest</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" readOnly value={this.state.interest} className="form-control" readOnly /> 
                                                    </div>
                                            </div>
                                            <div className="row form-group"> 
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Blocked</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" readOnly value={this.state.blocked} className="form-control" readOnly /> 
                                                    </div>
                                            </div>
                                            <div className="row form-group"> 
                                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Available</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" readOnly value={this.state.available} className="form-control" readOnly /> 
                                                    </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                            </div> 
                        {/* lefeArea end */} 
                        <RightMenu clientId={ this.props.location.state.clientId } activeMenu="client_accounts"/>  
                    </div>
                </div>
            </div> 
        );
    }
}

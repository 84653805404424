import React, { Component } from "react"; 
import axios from "axios";

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Pagination from "react-js-pagination"; 
 
export default class CurrencyWallet extends Component { 
    state = { 
        baseCurrency : {
            'currency':'EUR',
            'name'    :'European ',
            'symbol'  :'€',
            'rate'    :'',
            'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/23px-Flag_of_Europe.svg.png'
        }, 
        rates:[ 
            {
                'currency':'USD',
                'name'    :'US dollar',
                'symbol'  :'$',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/23px-Flag_of_the_United_States.svg.png'
            },
            {
                'currency':'GBP',
                'name'    :'Pound sterling',
                'symbol'  :'£',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/23px-Flag_of_the_United_Kingdom.svg.png'
            },
            {
                'currency':'CHF',
                'name'    :'Swiss franc',
                'symbol'  :'CHF',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/16px-Flag_of_Switzerland.svg.png'
            },
            {
                'currency':'SEK',
                'name'    :'Swedish krona',
                'symbol'  :'kr',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/23px-Flag_of_Sweden.svg.png'
            },
            {
                'currency':'ZAR',
                'name'    :'South African rand',
                'symbol'  :'R',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/23px-Flag_of_South_Africa.svg.png'
            },
            {
                'currency':'JPY',
                'name'    :'Japanese yen',
                'symbol'  :'¥',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/23px-Flag_of_Japan.svg.png'
            },
            {
                'currency':'THB',
                'name'    :'Thai baht',
                'symbol'  :'฿',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/23px-Flag_of_Thailand.svg.png'
            },
            {
                'currency':'HKD',
                'name'    :'Hong Kong dollar',
                'symbol'  :'HK$',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Flag_of_Hong_Kong.svg/23px-Flag_of_Hong_Kong.svg.png'
            },
            {
                'currency':'KRW',
                'name'    :'South Korean won',
                'symbol'  :'₩',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/23px-Flag_of_South_Korea.svg.png'
            },
            {
                'currency':'CAD',
                'name'    :'Canadian dollar',
                'symbol'  :'C$',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/23px-Flag_of_Canada_%28Pantone%29.svg.png'
            },
            {
                'currency':'AUD',
                'name'    :'Australian dollar',
                'symbol'  :'A$',
                'rate'    :'',
                'icon'    :'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Flag_of_Australia_%28converted%29.svg/23px-Flag_of_Australia_%28converted%29.svg.png'
            },
        ], 
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '', 
        token: '',
        pagelaoder:true 
    }
    async componentDidMount() { 
        var symbols = this.state.rates.map(function(value, i){
            return value.currency;
        });
        
        await axios.get(`https://api.exchangerate.host/latest`,{
            params: { 
                access_key: '315aeb75adc3f9c8fab697630e1280b4',
                "base": "EUR",
                symbols:symbols.join()  
            }, 
        })
        .then(response => { 
            let rates = this.state.rates;
            rates.forEach(account => { 
              if (account.currency in response.data.rates){
                account.rate = response.data.rates[account.currency]; 
                
              } 
            }); 
            this.setState({ rates: rates,pagelaoder:false}); 
        })
        .catch(error => { 
            
        })  
        
    }

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    } 
    render() {
        const { 
            rates,baseCurrency

        } = this.state; 
        return (
            <div className="wrapper">
                  <ToastContainer />  
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9">
                            <h3 className="heading">Currency Wallet</h3> 
                            <div className="content-wrap">  
                                <div className="tablebox p-0 w-100">
                                            <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                            <table className="table table-bordered table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col">View</th> */}
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Currency Name</th>
                                                        <th scope="col">Exchange Rate = 1 EUR</th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rates.length > 0 ?
                                                            rates.map((data, index) => { 
                                                                return (
                                                                    <tr>
                                                                        {/* <td className="text-center">
                                                                            <button><a href="#"><i className="fa fa-search" /></a></button>
                                                                        </td> */}
                                                                        <td><span className="currency-icon"><img src={baseCurrency.icon} /></span><span className="currency-name">{baseCurrency.currency}</span><span className="currency-icon right-icon"><img src={data.icon} /></span><span className="currency-name">{data.currency}</span></td>
                                                                        <td>{baseCurrency.name}{data.name}</td>
                                                                        <td>{data.rate}</td> 
                                                                     </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={5}>No result found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table> 
                                        </div>
                                  
                            </div>
                        </div>
                        {/* lefeArea end */} 
                    </div>
                </div>
            </div> 
        );
    }
}

import React, { Component } from "react";
import sign from "../../assets/images/sign.png";
export default class ProcessPasswordChange extends Component {
  state = {
    name: "",
    role: "",
  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
    });
  }
  render() {
    const { name, role } = this.state;
    return (
      <>
        <div className="wrapper wrappermax">
          <div className="mainheading2 text-center w100 m-0">
            Process Password Change
          </div>
          <div className="container mainArea" id="formpage">
          <div className="row justify-content-center">
            <div className="lefeArea col-md-6">
              <div className="tablebox">
                <form>
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      User Name
                    </label>
                    <div className="col-sm-7">
                      <p className="blackolor"></p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Full Name
                    </label>
                    <div className="col-sm-7">
                      <p className="blackolor">Ttig Lennart Engstrom</p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Last Login
                    </label>
                    <div className="col-sm-7">
                      <p className="blackolor">30 Apr 2021 02:54</p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Last Password Change
                    </label>
                    <div className="col-sm-7">
                      <p className="blackolor">22 Apr 2021 01:05</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Old Password <sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="password"
                        className="form-control "
                        id="inputEmail3"
                        placeholder="......."
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      New Password <sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        className="form-control "
                        id="inputEmail3"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      for="inputEmail3"
                      className="col-sm-5 col-form-label"
                    >
                      Retype Password <sup>*</sup>
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        className="form-control "
                        id="inputEmail3"
                        placeholder=""
                      />
                    </div>
                  </div>
                </form>
                <div className="form-group w-100 row text-center mt-2 ml-auto">
                  <div className="col-sm-12">
                    <div className="m-auto buttonwrp">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png" 
export default class BluckTransactionHistory extends Component {


    state = { 
        transaction_type : '',
        account:'',
        form_date:'',
        to_date:'',
        filterDetails: [], 
        transactionTypes:[], 
        totalTransications:[],
        transactionAccounts:[],
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        pagelaoder:true


    }
    async componentDidMount() { 
        this.getConfig();
        this.getClientAccounts();
        this.OnSearch();
    }
    async OnSearch()
    { 
        this.setState({ pagelaoder:true});
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        var params ={
            
        } 
          //get listing 
          await axios.get(`${window.$URL}bulk/trasation/view`,{
            params: {
                transaction_id :  this.props.location.state.bluckId, 
            },
            headers: { "Authorization": `Bearer ${accessToken}` }
        })
          .then(response => { 
              if (response.data.status) { 
                  let totalCount = response.data.data.emi_data.length;
                  let showingFrom = response.data.data.emi_data.length > 1 ? 1 : response.data.data.emi_data.length;
                  let showingTo = response.data.data.emi_data.length > 10 ? 10 : response.data.data.emi_data.length;
                  this.setState({
                      totalTransications: response.data.data.emi_data,
                      filterDetails: response.data.data.emi_data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.emi_data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Transaction',
                      pagelaoder:false
                  })

                  if(totalCount==0){
                    toast.success("No result found", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                  }
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
    async getConfig()
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
                
                let accessToken = checkToken.access_token; 
                //get listing
                await axios.get(`${window.$URL}getConfigs?transaction_types=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => { 
                    if (response.data.status) { 
                        if(response.data.data.transaction_types){
                            this.setState({transactionTypes:response.data.data.transaction_types}); 
                        } 
                    }
                })
                .catch(error => {
                     
                })
                 //end listing

            } 
        
    } 
    async getClientAccounts(accountType)
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
                let transactionType = this.state.transaction_type; 
                
                 
                //get listing
               // await axios.get(`${window.$URL}getConfigs?transaction_account=`+transactionType+parma,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                await axios.get(`${window.$URL}admin/account-lists`,{
                    params: {
                       
                        account_type        : accountType, 

                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                   
                    if (response.data.status) { 
                        if(response.data.data.transaction_accounts){
                             
                            this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                            
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            }  
    }
    handlePageChange(pageNumber) {
        const { totalTransications, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = totalTransications.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }
    onChangeSearch = (e) => {
        e.preventDefault(); 
        const { name,value } = e.target;   
        this.setState({
            [e.target.name]: e.target.value, 
          })
      
    }
  
 
    render() { 
        const {            
            transactionTypes,transactionAccounts,filterDetails,paginationCountText,activePage,pageLimit,totalCount
        } = this.state; 
        let transactionTypeOptions = transactionTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let transactionAccountOptions = transactionAccounts.length > 0
        && transactionAccounts.map((item, i) => {
            return (
                <option value={item.id}>{item.account}</option>
            ) 
        }, this);
        return (
            <div className="wrapper">
                  <ToastContainer /> 
                
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 p-0">
                            <h3 className="heading">Bulk Transactions</h3> 
                            <div className="text-right mb-3">
                                            <Link
                                                className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/admin/bulk/transactions`,  
                                                }}
                                            >Back</Link> 
                                        </div>
                            <div className="content-wrap">
                                <form className=" d-none">
                                    <div className="row clientteaminput">
                                        <div className="col-md-6"> 
                                            <div className="form-group row">
                                                <label for="inputEmail3" className="col-sm-8 col-form-label">Accounts</label>
                                                <div className="col-sm-4 pl-2">
                                                    <select name="account" className="form-control" onChange={this.onChangeSearch} > 
                                                    {transactionTypeOptions}
                                                    </select>  
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label for="inputEmail3" className="col-sm-8 col-form-label">Type</label>
                                                <div className="col-sm-4 pl-2">
                                                    <select name="transaction_type"  onChange={this.onChangeSearch} className="form-control w-100">
                                                     {transactionAccountOptions}                     
                                                    </select>
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="col-md-6"> 
                                            <div className="form-group row">
                                                <label for="inputEmail3" className="col-sm-8 col-form-label">From Date</label>
                                                <div className="col-sm-4 pl-2 pr-0">
                                                    <input id="datepicker" className="form-control" placeholder="yyyy-mm-dd" name="from_date" onChange={this.onChangeSearch} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-8 col-form-label">To Date</label>
                            
                                                <div className="col-sm-4 pl-2 pr-0">
                                            
                                                <input id="datepicker1" className="form-control" placeholder="yyyy-mm-dd" name="to_date" onChange={this.onChangeSearch} />
                                                </div>
                                            </div> 
                                        </div> 
                                    </div> 
                                    <div className="form-group row text-center mt-2 ml-auto">
                                        <div className="col-sm-12">
                                        <div className="btnwrp">
                                                <button type="button" onClick={() => this.OnSearch()} className="btn btn-primary">
                                                    Search
                                                 </button>
                                                <button type="button" onClick={() => this.OnClear()} className="btn btn-primary">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="tablebox p-0">
                                    <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                    <table className="table table-bordered table-responsive-sm">
                                        <thead>
                                            <tr>  
                                                <th scope="col">Bulk Id</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Bulk Type</th> 
                                                <th scope="col">Amount</th>
                                                <th scope="col">Interest</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">From Account</th> 
                                                <th scope="col">To Account</th>  
                                                <th scope="col">Date</th> 
                                                <th scope="col">Charge</th> 
                                                <th scope="col">Status</th> 
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            {
                                                filterDetails.length > 0 ?
                                                    filterDetails.map((data, index) => {

                                                        return (
                                                            <tr>  
                                                                <td>{data.transaction_no}</td>
                                                                <td>{data.transaction_type}</td>
                                                                <td>{data.bluck_type}</td>  
                                                                <td>{data.transaction_amount}</td>
                                                                <td>{data.transaction_interest}</td>
                                                                <td>{data.transaction_start_date}</td>
                                                                <td>{data.transaction_end_date}</td> 
                                                                <td>{data.transaction_from_account_no}</td>
                                                                <td>{data.transaction_to_account_no}</td>
                                                                <td>{data.emi_date}</td>
                                                                <td>{data.emi_charge}</td>
                                                                <td>{data.status_text}</td>  
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={5}>No result found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                            </div>
                                            <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                <Pagination
                                                    prevPageText='Prev'
                                                    nextPageText='Next'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                    activePage={activePage}
                                                    itemsCountPerPage={pageLimit}
                                                    totalItemsCount={totalCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemclassName="page-item"
                                                    linkclassName="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
        );
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
export default class EditProduct extends Component {


    state = {
        name: '',
        status: '1', 
        validationErrors:{
            nameError: ''
        },
        token:'',
        role:'',

    }
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({ 
                role: checkToken.role,
                token: accessToken
            })
        }
        if (this.props.location.state) {
            //console.log("===================="+this.props.location.state.clientId); 
            //console.log('++++++++++++++++++++', this.props.match.params.clientId)
            this.getProductDetails();
            
        }else{
          this.props.history.push(`/product`);
        }
        
    }
    async getProductDetails()
    {
         
           let editId=this.props.location.state.productId
        
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
        
            let accessToken = checkToken.access_token;
            var params ={ 
                id:editId, 
            }
            //get listing
            await axios.get(`${window.$URL}account-type-get`,{
                params: {
                   
                    id: editId, 

                },
                headers: { "Authorization": `Bearer ${accessToken}` }
            })
            .then(response => {
                    
                    if(response.data.status){  
                            this.setState({ 
                              name: response.data.data.name, 
                              status:response.data.data.status, 
                            }) 
                    }
                })
                .catch(error => {
                    console.error("asd"+error.message)
                    // if(error.message === 'Unauthenticated'){
                    //     alert("asd");
                    // }
                }) 
            }  
       
    } 
    onChange = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
    }
    validate = () => {
        this.setState({ 
        nameError:'', 
        }); 
        let formvalidateStatus = true;
        if (this.state.name.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{nameError:'Please Enter Product Name'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{nameError:''}));
        } 
        return formvalidateStatus
    }
    onSubmit = (e) => {
        //alert('gii');return false;
        e.preventDefault();
        
        const { name, status} = this.state; 
        const isValid = this.validate();
        
         if (isValid) {
            //alert('pk');
            let editId=this.props.location.state.productId
            var params ={
                name:this.state.name, 
                status:this.state.status, 
                id:editId, 
            }; 
           axios.post(`${window.$URL}update-account-type`, params, { headers: { "Authorization": `Bearer ${this.state.token}` } }) 
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        }) 
                        this.componentDidMount(); 
                            this.props.history.push(`/product`);
                        
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
        }
    } 
    render() {
        const {
            documentTypeError,
            complementaryInformationError,
            confidentialError,

        } = this.state;
        return ( 
            <div className="wrapper">
              <ToastContainer/>           
              <div className="container newContainer mainArea pt-0" id="formpage">
                <div className="row justify-content-center"> 
                  <div className="lefeArea col-md-9 p-0">  
                    <div className="content-wrap">
                    <div className="row justify-content-center"> 
                      <div className="col-md-6">   
                        <h4 className="smallheading mb-0">Edit Product</h4>
                        <div className="tablebox">
                        <form onSubmit={this.onSubmit}>  
                            <div className="form-group row">
                              <label for="" className="col-sm-4 col-form-label">Product Name<sup>*</sup></label>
                              <div className="col-sm-8">
                                <input type="text" name="name" className="form-control" id="inputEmail3" value={this.state.name} onChange={this.onChange} placeholder="Product Name" />
                                <p className="error">{this.state.validationErrors.nameError}</p>
                              </div>
                            </div>
                            <div className="form-group row">
                                <label for="" className="col-sm-4 col-form-label">Status</label> 
                              <div className="col-sm-8">
                                    <select className="form-control w-100" name="status" value={this.state.status} onChange={this.onChange} id="exampleFormControlSelect1">
                                       <option value="1">Active</option>
                                        <option value="2">Deactivate</option> 
                                    </select>
                                 
                              </div>
                            </div> 
                            <div className="form-group row text-center mt-2 ml-auto">
                                <div className="col-sm-12">
                                    <div className="m-auto buttonwrp">
                                        <Link
                                            className="btn btn-primary bluebg"
                                            to={{
                                            pathname: `/product`,  
                                            }}
                                        > Back</Link>  
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                          </form>
                        </div> 
                      </div> 
                    </div>
                  </div>
                  </div>  
                </div>
              </div>
          </div>
        );
    }
}

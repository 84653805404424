import React, { Component } from "react";
import { Link } from 'react-router-dom';

export default class RightMenu extends Component {
  constructor(props) {
    super(props); 
        this.state = {
            relationAndOnlineaccess: localStorage.getItem("relationAndOnlineaccess"),
        } 
}; 

async componentDidMount() {  
    this.setState({relationAndOnlineaccess:localStorage.getItem("relationAndOnlineaccess")}); 
}
componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
     
  }
    render() {
      
    return (
      <> 
        <div className="rightArea col-md-3 p-0">
          <div id="accordionmain" className="main">
              <div className="card mb-0">
                  <div className="card-header collapsed" data-toggle="collapse" href="#collapseOnemain">
                  <h3 className="heading">Client Profile</h3>
                  <h5 className="headingRight"><strong>{localStorage.getItem("selectdClientName")}</strong><br/>CLNo:<strong>{localStorage.getItem("selectdClientNumber")}</strong></h5>
                  </div> 
              </div>
          </div> 
          <div id="collapseOnemain" className="card-body collapse" data-parent="#accordionmain" >
            <nav className="navbar bg-light rightmenuclient"> 
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link bigactive">Client Information</span>
                </li>
                <li className="nav-item">
                  <Link
                  to={{
                    pathname: `/client/view-client/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId, 
                    }
                  }}
                >
                  Client Details</Link>
                </li>
                <li className="nav-item">
                  <Link
                  to={{
                    pathname: `/client/client-address/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                  Address</Link>
                </li>
                <li className="nav-item">
                  <Link
                  to={{
                    pathname: `/client/client-identification/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                  Identification</Link>
                </li>
                {this.state.relationAndOnlineaccess =='true' &&
                    <li className="nav-item">
                        <Link
                        className={`nav-link ${this.props.activeMenu === 'client_online_access' ? 'active' : ''}`} 
                            to={{
                            pathname: `/client/client-online-access/${this.props.clientId}`, 
                            clientId:this.props.clientId,
                            state: {
                                clientId: this.props.clientId 
                            }
                            }}
                        >Online Access</Link>
                    </li> 
                }
                {this.state.relationAndOnlineaccess =='true' &&
                  <li className="nav-item">               
                    <Link
                    to={{
                      pathname: `/client/client-relation/${this.props.clientId}`, 
                      clientId:this.props.clientId,
                      state: {
                        clientId: this.props.clientId 
                        
                      }
                    }}
                  >
                    Client Relationship</Link>
                  </li>
                }
                <li className="nav-item d-none">
                  <Link
                  to={{
                    pathname: `/client/client-documents/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                  Documents</Link>
                </li>
              </ul>
            
              <ul className="navbar-nav mt-3">
                <li className="nav-item">
                  <span className="nav-link bigactive">Account Information</span>
                </li>
                <li className="nav-item">                
                  <Link
                  to={{
                    pathname: `/client/client-accounts/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                  Accounts</Link>
                </li>
                <li className="nav-item">
                <Link
                  to={{
                    pathname: `/client/add-clients-accounts/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                  Open Account</Link>
                </li>
                <li className="nav-item"> 
                  <Link
                  to={{
                    pathname: `/client/transactions/${this.props.clientId}`, 
                    clientId:this.props.clientId,
                    state: {
                      clientId: this.props.clientId 
                      
                    }
                  }}
                >
                 Transaction History</Link>
                </li>
              </ul> 
            </nav>
            </div> 
        </div>
      </>
    );
    }
}
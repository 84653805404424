import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import TopMenu from "./TopMenu" 
export default class editclientRelation extends Component {


    state = { 
        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token:'',
        

        validationErrors:{
            
            relatedClientNoError:'',
            relationError:'',
            percentError:'',
            narrativeError:''
      },
      showLicenseNumber:false,
      client_no:'',
      clientNumbers:[],
      relatedClientNo:'',
      relation:'',
      percent:'',
      narrative:'',
      user_group:''





    }
    async componentDidMount() {
        
        
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        //
        let accessToken = checkToken.access_token;
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })

          //get listing
          await axios.get(`${window.$URL}client/client-specific-relation/${this.props.match.params.clientRelationId}`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              
              if(response.data.status){ 
                this.setState({
                    client_no: response.data.data.client_no,
                    relatedClientNo: response.data.data.related_client_no,
                    relation: response.data.data.relation,
                    narrative: response.data.data.narrative,
                    percent: response.data.data.percent,
                    country: response.data.data.country,
                    status:response.data.data.status, 
                    user_group:response.data.data.user_group,
                    
                }) 
            }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            
        } 
    } 
    onChange = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
        
    }
    validate = () => {
      this.setState({       
          relatedClientNoError:'',
          relationError:'',
          percentError:'',
          narrativeError:''
      });
      const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; 
       let relatedClientNoError= '';
       let relationError= '';
       let percentError='';
       let narrativeError=''; 
       let formvalidateStatus = true;  
       if (this.state.relatedClientNo.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{relatedClientNoError:'Field is required'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{relatedClientNoError:''}));
      }  
      if (this.state.relation.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{relationError:'Field is required'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{relationError:''}));
      }
      return formvalidateStatus
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const {client_no,
        relatedClientNo,
        relation,
        percent,
        narrative,status,user_group,token } = this.state; 
        const isValid = this.validate();
       
        if (isValid) {
            //alert('pk');
            let teamData = {
                client_no: client_no,
                related_client_no: relatedClientNo,
                relation: relation,
                narrative: narrative,
                percent: percent,
                cm_id:this.props.match.params.clientId,
                status:status,
                user_group:user_group,
                relation_id:this.props.match.params.clientRelationId
                
            }
          
           // return false;
            axios.put(`${window.$URL}client/update-client-relation`, teamData,{ headers: {"Authorization" : `Bearer ${token}`}})
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false,
                            drivers_licence_number:""
                        })
                        setTimeout(
                            () => this.props.history.push(`/client/client-relation/`+this.props.match.params.clientMasterId), 
                            3000
                          );
                        //this.props.history.push(`/client/client-relation/`+this.props.match.params.clientId);
                        //this.componentDidMount();
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
        }
    } 
    render() {
        const { 
            document_typeError,
            drivers_licence_numberError,
            issued_dateError,
            country_issuedError,
            location_issuedError, 
        } = this.state;
        return ( 
            <div className="wrapper">
            <ToastContainer/> 
            <div className="container newContainer mainArea pt-0" id="formpage">
              <div className="row justify-content-center"> 
                <div className="lefeArea col-md-9 p-0">
                  <h3 className="heading">Create A New Relation Mervi Andersson</h3>
                  <TopMenu clientId={ this.props.match.params.clientMasterId }activeMenu="client_relations"/> 
                  <div className="content-wrap">
                    <div className="row justify-content-center"> 
                      <div className="col-md-6">
                        <h4 className="smallheading mb-0">Client Relation</h4>
                        <div className="tablebox">
                          <form>
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Client No</label>
                              <div className="col-sm-8">
                                <input type="text" className="form-control" name="client_no" id="inputEmail3" placeholder="Client No"  onChange={this.onChange} value={this.state.client_no} disabled/>
                              </div>
                            </div> 
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Related Client No <sup>*</sup></label>
                              <div className="col-sm-8">
                                <select className="form-control" name="relatedClientNo" id="exampleFormControlSelect1" readOnly>
                                  <option value={this.state.relatedClientNo} >{this.state.relatedClientNo}</option>                        
                                </select>
                              </div> 
                            </div> 
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Relation <sup>*</sup></label>
                              <div className="col-sm-8">
                                <select className="form-control"  name="relation"  id="exampleFormControlSelect1" value={this.state.relation} readOnly>
                                  <option>Select Relation</option>
                                  <option>Signatory</option>
                                  <option>Online Banking</option>
                                  <option>Contact Person</option>
                                  <option>Father</option>
                                  <option>Mother</option>
                                  <option>Son</option>
                                  <option>Daughter</option>
                                  <option>Wife</option>
                                  <option>Husband</option>
                                  <option>Director</option>
                                  <option>Intermediary</option>
                                  <option>Nominee Shareholder</option>
                                  <option>Secretary</option>
                                  <option>UBBO</option> 
                                </select> 
                              </div>
                            </div> 
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Narrative</label>
                              <div className="col-sm-8">
                                <input type="text" name="narrative" value={this.state.narrative} onChange={this.onChange} className="form-control" id="inputEmail3" placeholder="Narrative"/> 
                              </div> 
                            </div> 
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Percent</label>
                              <div className="col-sm-8">
                                <input type="text" value={this.state.percent} onChange={this.onChange} name="percent" className="form-control" id="inputEmail3" placeholder="Percent" /> 
                              </div>
                            </div> 
                            <div className="form-group row">
                              <label for="inputEmail3" className="col-sm-4 col-form-label">Status</label>
                              <div className="col-sm-8">
                                <select className="form-control"  name="status" value={this.state.status} onChange={this.onChange}  id="exampleFormControlSelect1" >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option> 
                                </select> 
                              </div>
                            </div>  
                            <div className="form-group row text-center mt-2 ml-auto">
                              <div className="col-sm-12">
                                <div className="btnwrp">
                                  <Link
                                      className="btn btn-primary bluebg"
                                      to={{
                                      pathname: `/client/client-relation/${this.props.match.params.clientMasterId}`, 
                                      clientId:this.props.match.params.clientMasterId,
                                      state: {
                                          clientId: this.props.match.params.clientMasterId  
                                      }
                                      }}
                                  >
                                  Back
                                  </Link>
                                  <button type="button" onClick={this.onSubmit} className="btn btn-primary">Update</button>
                              </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        </div>
                    </div>
                 </div>
                </div> 
                  <RightMenu clientId={ this.props.match.params.clientMasterId } activeMenu="client_relations"/>    
              </div>
            </div>
          </div> 
        );
    }
}

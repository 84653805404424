import React, { useState } from 'react';

import { format } from 'date-fns';

import moment from "moment";     
const PrintAllClients = (props) => {  
  const filterDetails = props.clients;
  return (
     <div className="receipt-print-wrap"> 
          <table style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr style={{ textAlign:'center' }}>
                  <td >
                    <img src={require('../../../assets/images/logo.png')} style={{ width:"150px" }}alt="" className="print-logo" />
                     
                  </td> 
              </tr>
            </tbody>
          </table> 
           <h2 style={{ background:"#0097d4",color:"#fff",padding:"15px",margin:"25px 0px",textAlign:"center" }}> Clients</h2>
           <table className="table table-bordered table-responsive-sm">
              <thead>
                  <tr>  
                  <th scope="col">Client Number</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Company Number</th>
                  <th scope="col">Client Type</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Status</th> 
                  </tr>
              </thead>
                                            
            <tbody>
              {
                  filterDetails.length > 0 ?
                      filterDetails.map((data, index) => {
                          
                          return (
                              <tr>  
                                <td>{data.client_no}</td>
                                <td>{data.client_name }</td>
                                <td>{data.company_name }</td>
                                <td>{data.company_number }</td>
                                <td>{data.client_type}</td>
                                <td>{format(new Date(data.created_at), "dd/MM/yyyy kk:mm:ss")}</td>
                                <td className="bggreen5">{data.status === 1 || data.status === '1' ?"Active":"Deactivate"}</td>
                               </tr>
                          )
                      })
                      :
                      <tr>
                          <td colSpan={5}>No result found</td>
                      </tr>
              }
            </tbody>
        </table> 
        </div> 
  );
}
export default PrintAllClients
import React, { Component } from "react";
import sign from "../../assets/images/sign.png";
export default class TranfersterToConnectedAccounts extends Component {
  state = {
    name: "",
    role: "",
  };
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //
    this.setState({
      name: checkToken.name,
      role: checkToken.role,
    });
  }
  render() {
    const { name, role } = this.state;
    return (
      <>
        <div className="wrapper wrappermax">
    <div className="mainheading2 text-center w100 m-0">
      Tranferster - To Connected Accounts
    </div>
  <div className="container mainArea" id="formpage">
    <div className="row justify-content-center">
    <div className="lefeArea2 col-md-5">
      <div className="tablebox tablebox2">
      <h4 className="smallheading">From Account</h4>
        <form>
          <div className="form-group row">
            <label for="inputEmail3" className="col-sm-5 col-form-label">Select Account / IBAN  <sup>*</sup></label>
            <div className="col-sm-7">
              <select className="form-control" id="exampleFormControlSelect1">
                <option>Select Account / IBAN</option>
                <option>a</option>
                <option>b</option>
              
              </select>
            </div>
          </div>
          
        
  
        </form>
      </div>
      <div className="tablebox tablebox2">
      <h4 className="smallheading">Transfer Details</h4>
        <form>
          <div className="form-group row">
            <label for="inputEmail3" className="col-sm-5 col-form-label">Amount to Transfer <sup>*</sup></label>
            <div className="col-sm-7 d-flex">
              <input type="text" className="form-control w-50" id="inputEmail3" placeholder=""/>
              <input type="text" className="form-control " id="inputEmail3" placeholder="0.00"/>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-5 col-form-label">Execution Date <sup>*</sup> </label>
  
            <div className="col-sm-7">
              <input id="datepicker6" className="form-control" placeholder="yyyy-mm-dd"/>
            </div>
          </div>
          <div className="form-group row">
            <label for="inputEmail3" className="col-sm-5 col-form-label">Details <sup>*</sup>
            </label>
            <div className="col-sm-7">
              <input type="text" className="form-control" id="inputEmail3" placeholder=""/>
            </div>
          </div>
          <div className="form-group row">
            <label for="inputEmail3" className="col-sm-5 col-form-label">Nature of Transaction</label>
            <div className="col-sm-7">
              <select className="form-control" id="exampleFormControlSelect1">
                <option>Select Nature of transaction</option>
                <option>a</option>
                <option>b</option>
              
              </select>
            </div>
          </div>
          
        
  
        </form>
      </div>
    
    </div>
    <div className="rightArea2 mt-0 col-md-5">
      <div className="tablebox tablebox2">
        <h4 className="smallheading">To Account</h4>
          <form>
            <div className="form-group row">
              <label for="inputEmail3" className="col-sm-5 col-form-label">Select Account / IBAN</label>
              <div className="col-sm-7">
                <select className="form-control" id="exampleFormControlSelect1">
                  <option>Select Account / IBAN</option>
                  <option>a</option>
                  <option>b</option>
                
                </select>
              </div>
            </div>
    
          </form>
        </div>
        <div className="tablebox tablebox2">
          <h4 className="smallheading">Transfer Details</h4>
            <form>
              <div className="form-group row">
                <label for="inputEmail3" className="col-sm-5 col-form-label">Amount To Transfer <sup>*</sup>
                </label>
                <div className="col-sm-7">
                  <p className="blackolor">0.00</p>
                </div>
              </div>
      
            </form>
          </div>
        
          
          <div className="tablebox tablebox2">
            <h4 className="smallheading">Template Settings</h4>
          <div className="form-group row">
            <label for="inputEmail3" className="col-sm-5 col-form-label">Save Template</label>
            <div className="col-sm-7 text-left">
              <div className="custom-control custom-radio custom-control-inline">
                <input type="checkbox" className="form-check-input" id="exampleCheckjoint"/>
                <label className="form-check-label" for="exampleCheckjoint">Save as Template</label>
              </div>
              
            </div>
          </div>
  </div>
   
  
  
  
    </div>
   
  
  </div>
  </div>
  
  
  
  
  </div>
      </>
    );
  }
}

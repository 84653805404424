import React, { Component} from "react";
import { Link } from 'react-router-dom';
import axios from "axios"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination"; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import format from "date-fns/format";


export default class Client extends Component {


    state = {
        clientType: '',
        companyName: '',
        companyNumber: '',
        companyWebsite: '', 

        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        title: '',
        dob: '',
        nationality: '',
        firstNameError: '',
        middleNameError: '',
        lastNameError: '',
        genderError: '',
        titleError: '',
        dobError: '',
        walve_monthly_fees:'',
        nationalityError: '',


        companyRegistration: '',
        vatRef: '',
        tin: '',
        taxRef: '',
        companyType: '',
        companyRegistrationError: '',
        vatRefError: '',
        tinError: '',
        taxRefError: '',
        companyTypeError: '',


        regAddress: '',
        postCode: '',
        city: '',
        country: '',
        createdBy: '',
        createdAt: '',
        lastModificationUser: '',
        lastDateCollection: '',
        lastDateRecollection: '',
        regAddressError: '',
        postCodeError: '',
        cityError: '',
        countryError: '',
        createdByError: '',
        createdAtError: '',
        lastModificationUserError: '',
        lastDateCollectionError: '',
        lastDateRecollectionError: '',


        phoneNumber: '',
        email: '',
        refBy: '',
        phoneNumberError: '',
        emailError: '',
        refByError: '',


        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        backendErrors: '',

        validationErrors:{
            clientTypeError: '',
            companyNameError: '',
            companyNumberError: '',
            companyWebsiteError: '',
            firstNameError: '',
            lastNameError: '',
            genderError: '',
            titleError: '',
            dobError: '',
            nationalityError: '',
            companyRegistrationError: '',
            vatRefError: '',
            tinError: '',
            taxRefError: '',
            companyTypeError: '',
            regAddressError: '',
            postCodeError: '',
            cityError: '',
            countryError: '',
            createdByError: '',
            createdAtError: '',
            lastModificationUserError: '',
            lastDateCollectionError: '',
            lastDateRecollectionError: '',
            phoneNumberError: '',
            emailError: '',
            refByError: ''
        },
        companybox:false,
        ProfileImg: null,
        profileImageInDB: '',
        btnLaoder:false
 
    }
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            })
        } 
    }

    cancelAction = () => {
        const { buttonData, UpdateData } = this.state;
        if (buttonData) {
            this.setState({
                buttonData: false
            })
        }
        if (UpdateData) {
            this.setState({
                UpdateData: false
            })
        }
    }
  
    onChange = (e) => {
        e.preventDefault(); 
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    onChangeClientType = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        
        if(value === 'corporate'){
            this.setState({ companybox:true });
        }else{
            this.setState({ companybox:false });
        }
            
        
        this.setState({ [name]: value });
       
       
      
    }
    handleProfileImageChange = (e) => {
        e.preventDefault();
        const img = e.target.files[0];
  
        this.setState({
            prevProfileImg: URL.createObjectURL(img),
            ProfileImg: img,
        })
    }
    validate = () => { 
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;  
        let emailError = '';  
        const { validationErrors } = this.state;
        let formvalidateStatus = true;
        if (this.state.clientType.length < 1) {  
            this.setState(Object.assign(this.state.validationErrors,{clientTypeError:'Please select client type'}));
            formvalidateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{clientTypeError:''}));
        } 

        if(this.state.companybox){
            if (this.state.companyName.length < 1) {  
                this.setState(Object.assign(this.state.validationErrors,{companyNameError:'Please enter company name'}));
                formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationErrors,{companyNameError:''}));
            } 

            if (this.state.companyNumber.length < 1) { 
                this.setState(Object.assign(this.state.validationErrors,{companyNumberError:'Please enter company number'}));
                formvalidateStatus = false;
            }
            else{
                this.setState(Object.assign(this.state.validationErrors,{companyNumberError:''}));
            }
        }  

        if (this.state.firstName.length < 1) {            
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:'Please Enter First Name'}));
             formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:''}));
        } 
        
      
       if (this.state.lastName.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{lastNameError:'Please Enter Surname'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{lastNameError:''}));
        }

        if (this.state.gender.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{genderError:'Please Select Gender'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{genderError:''}));
        }

        if (this.state.title.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{titleError:'Please Enter Title'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{titleError:''}));
        }

        if (this.state.dob.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{dobError:'Please Enter DOB'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{dobError:''}));
        }
        
        if (this.state.nationality.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{nationalityError:'Please Enter Nationality'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{nationalityError:''}));
        }

        if (this.state.regAddress.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{regAddressError:'Please Enter Registered Address'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{regAddressError:''}));
        }
        
        if (this.state.postCode.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{postCodeError:'Please Enter Post Code'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{postCodeError:''}));
        } 

         if (this.state.city.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{cityError:'Please Enter City'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{cityError:''}));
        }

        if (this.state.country.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{countryError:'Please Select Country'}));
              formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{countryError:''}));
        }

        if (this.state.phoneNumber.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:'Please Enter Phone Number'}));
              formvalidateStatus = false;
        }else if (this.state.phoneNumber.length < 10) {
             this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:'Please Enter Correct Phone Number'}));
              formvalidateStatus = false;
        }
        else{
            this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:''}));
        } 

        

        if (this.state.email.length < 1 && this.state.emailShow === true) {
            emailError = "Please enter Email";
             formvalidateStatus = false;
        } else if (!regex.test(this.state.email.trim()) && this.state.emailShow === true) {
             emailError = "Please enter a valid Email";
             formvalidateStatus = false;
        }else{
            emailError = ' ';
        }
        if (emailError) {
             this.setState(Object.assign(this.state.validationErrors,{emailError:emailError}));
          
        }  
        return formvalidateStatus 
    }
 
    onSubmit = (e) => {
        e.preventDefault();
        //alert('gii');return false;
        const {
            clientType,
            companyName,
            companyNumber,
            companyWebsite,
            firstName,
            middleName,
            lastName,
            gender,
            title,
            dob,
            walve_monthly_fees,
            nationality,
            companyRegistration,
            vatRef,
            tin,
            taxRef,
            companyType,
            regAddress,
            postCode,
            city,
            country,
            createdBy,
            createdAt,
            lastModificationUser,
            lastDateCollection,
            lastDateRecollection,
            phoneNumber,
            email,
            refBy,
            token,
            backendErrors,
            ProfileImg
        } = this.state;



        const isValid = this.validate();
        
        if (isValid) {
            this.setState({btnLaoder:true});
            let clientData = {
                client_type: clientType,
                company_name: companyName,
                company_number: companyNumber,
                company_website: companyWebsite,
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                gender: gender,
                title: title,
                dob: moment(moment(dob)).format('Y-MM-DD'),
                nationality: nationality,
                walve_monthly_fees:walve_monthly_fees,
                registration_date: moment(moment(companyRegistration)).format('Y-MM-DD'),
                vat_reference: vatRef,
                tin_tic: tin,
                tax_reference_jurisdiction: taxRef,
                type_of_company: companyType,
                address_1: regAddress,
                post_code: postCode,
                city: city,
                country: country,
                created_by: createdBy,
                created_at: createdAt,
                last_modification_user: lastModificationUser,
                last_date_collection: lastDateCollection,
                last_date_recollection: lastDateRecollection,
                phone_number: phoneNumber,
                email: email,
                referred_by: refBy,

            } 
            //return false;
            axios.post(`${window.$URL}client/insert`, clientData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => { 
                    if (response.data.status) { 
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        })  
                        if(ProfileImg!== null){
                            var bodyLogoFormData = new FormData();
                            bodyLogoFormData.set('userId', response.data.userId);
                            bodyLogoFormData.append('uploadProfileImage', ProfileImg);
                            axios({
                                method: 'post',
                                url: `${window.$URL}client/profile-Image-upload`,
                                data: bodyLogoFormData,
                                headers: { 'Content-Type': 'multipart/form-data' ,
                                            "Authorization": `Bearer ${token}`}  
                            })
                            .then(response2 => { 
                                if (response2.data.success) {
                                    // toast.success(response2.data.message, {
                                    //     position: "bottom-center",
                                    //     autoClose: 3000,
                                    //     hideProgressBar: true,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    // });
                                    this.setState({btnLaoder:false});
                                    setTimeout(
                                        () => this.props.history.push(`/client`), 
                                        3000
                                    );

                                    
                                } else {
                                    // toast.error(response2.data.message, {
                                    //     position: "bottom-center",
                                    //     autoClose: 3000,
                                    //     hideProgressBar: true,
                                    //     closeOnClick: true,
                                    //     pauseOnHover: true,
                                    //     draggable: true,
                                    //     progress: undefined,
                                    // });
                                    this.setState({btnLaoder:false});
                                }
                              
                            })
                            .catch(imageError => {
                                
                            })
                        }
                        this.setState({btnLaoder:false});
                          toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }); 
                        setTimeout(
                            () => this.props.history.push(`/client`), 
                            2000
                            );


                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong',
                            backendErrors: response.data.message,
                            emailError: response.data.message.email ?response.data.message.email[0]:'',
                            phoneNumberError: response.data.message.phone_number ?response.data.message.phone_number[0]:'',
                            btnLaoder:false,
                        })
                        this.setState(Object.assign(this.state.validationErrors,{emailError:response.data.message.email ?response.data.message.email[0]:''}));
                        this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:response.data.message.phone_number ?response.data.message.phone_number[0]:''}));
                    }
                })
                .catch(error => {
                     
                })
        }
    }
  
    dateChanged = (name,d) => {
      if(d){
        this.setState({[name]:d});
      }else{
        this.setState({[name]: ""});
      }
    }


    handleDateChange = (name,date) => {  
      if(date){
        this.setState({
          [name]: moment(moment(date)).format('Y-MM-DD'), 
        })
      }else{
          this.setState({
              [name]: "", 
            })
      }
    }
    render() {
        const {
            showSweetAlert,
            modalDialog,
            clientType,
            companyName,
            companyNumber,
            companyWebsite,
            firstName,
            middleName,
            lastName,
            gender,
            title,
            dob,
            nationality,
            companyRegistration,
            vatRef,
            tin,
            taxRef,
            companyType,
            regAddress,
            postCode,
            city,
            country,
            createdBy,
            createdAt,
            lastModificationUser,
            lastDateCollection,
            lastDateRecollection,
            phoneNumber,
            email,
            refBy,
            clientTypeError,
            companyNameError,
            companyNumberError,
            companyWebsiteError,
            firstNameError,
            lastNameError,
            genderError,
            titleError,
            dobError,
            nationalityError,
            companyRegistrationError,
            vatRefError,
            tinError,
            taxRefError,
            companyTypeError,
            regAddressError,
            postCodeError,
            cityError,
            countryError,
            createdByError,
            createdAtError,
            lastModificationUserError,
            lastDateCollectionError,
            lastDateRecollectionError,
            phoneNumberError,
            emailError,
            refByError,
            success,
            error,
            successMessage,
            errorMessage,
            backendErrors,
            profileImageInDB
        } = this.state;
         
        return (
            <div className="wrapper">
              <ToastContainer />  
              <div className="container mainArea pt-0" id="formpage"> 
                    <h3 className="heading">Client Add</h3>  
                    <div className="content-wrap">
                    <form onSubmit={this.onSubmit}> 
                      <div className="row"> 
                        <div className="col-md-6">
                        <h4 className="smallheading mb-0">Client Information</h4>
                        <div className="tablebox">
                                <div className="form-group row">
                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Client Type <sup>*</sup></label>
                                    <div className="col-sm-8">
                                        <select className="form-control"   onChange={this.onChangeClientType} name="clientType" id="exampleFormControlSelect1"> 
                                            <option value=''>Select Type</option>
                                            <option value="corporate">Corporate</option>
                                            <option value="individual">Individual</option>
                                        </select>
                                        <p className="error">{this.state.validationErrors.clientTypeError}</p>
                                    </div>
                                </div>
                                { (this.state.companybox === true) &&
                                    <div className="comapny_box"> 
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company Name <sup>*</sup></label>
                                            <div className="col-sm-8">
                                                <input type="text" value={this.state.companyName} className="form-control" onChange={this.onChange} name="companyName" id="inputEmail3" placeholder="Company Name" />
                                                <p className="error">{this.state.validationErrors.companyNameError}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company Number <sup>*</sup></label>
                                            <div className="col-sm-8">
                                                <input type="text" value={this.state.companyNumber} className="form-control" onChange={this.onChange} name="companyNumber" id="inputEmail3" placeholder="Company Number" />
                                                <p className="error">{this.state.validationErrors.companyNumberError}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company website</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" value={this.state.companyWebsite} onChange={this.onChange} value={this.state.companyWebsite} name="companyWebsite" id="inputEmail3" placeholder="Company website" />
                                                <p className="error">{this.state.validationErrors.companyWebsiteError}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                        </div>
                          <h4 className="smallheading mb-0">Signatories</h4>
                          <div className="tablebox"> 
                                <div className="form-group row">
                                  <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Title <sup>*</sup>
                                  </label>
                                  <div className="col-sm-8">
                                          <select className="form-control" onChange={this.onChange} value={this.state.title} defaultValue={this.state.title} name="title">
                                              <option value="0">Title</option>
                                              <option value="1">Mr.</option>
                                              <option value="2">Mrs.</option>
                                              <option value="3">Miss.</option>
                                              <option value="4">Dr.</option>
                                              <option value="5">Ms.</option>
                                              <option value="6">Prof.</option>
                                          </select> 
                                        <p className="error">{this.state.validationErrors.titleError}</p>                                             
                                  </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">First Name <sup>*</sup>
                                </label>
                                    <div className="col-sm-8">
                                        <input type="text" value={this.state.firstName} className="form-control" onChange={this.onChange} name="firstName" id="inputEmail3" placeholder="First Name" />
                                        <p className="error">{this.state.validationErrors.firstNameError}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Middle Name</label>
                                    <div className="col-sm-8">
                                        <input type="text" value={this.state.middleName} className="form-control" onChange={this.onChange} name="middleName" id="inputEmail3" placeholder="Middle Name" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Surname <sup>*</sup>
                                    </label>
                                    <div className="col-sm-8">
                                        <input type="text" value={this.state.lastName} className="form-control" onChange={this.onChange} name="lastName" id="inputEmail3" placeholder="Surname" />
                                        <p className="error">{this.state.validationErrors.lastNameError}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Gender <sup>*</sup></label>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={this.onChange} name="gender" id="exampleFormControlSelect1" value={this.state.gender} defaultValue={this.state.gender} >
                                            <option>Select</option>
                                            <option value="1" selected={this.state.gender===1?"1":""}>Male</option>
                                            <option value="2" selected={this.state.gender===2?"2":""}>Female</option>
                                        </select>
                                        <p className="error">{this.state.validationErrors.genderError}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">DOB<sup>*</sup></label>
                                    <div className="col-sm-6 customdatesm"> 
                                        {/* <DatePicker placeholderText="yyyy-mm-dd" maxDate={new Date()}  dateFormat="yyyy-mm-dd" className="form-control" value={ this.state.dob } selected={this.state.dob && new Date(this.state.dob)} name="searchTodate"   onChange={(date) => {
                                         this.handleDateChange('dob',date);}} 
                                          showYearDropdown
                                          scrollableMonthYearDropdown autoComplete="off" 
                                        />  */}
                                        <DatePicker placeholderText="yyyy-mm-dd" dateFormat='yyyy-MM-dd' value={ this.state.dob} maxDate={new Date()}  selected={this.state.dob} onChange={(dob) => {
                                         this.dateChanged('dob',dob);}} className="form-control" name="searchTodate" showYearDropdown
                                          scrollableMonthYearDropdown autoComplete="off"/>
                                        <p className="error">{this.state.validationErrors.dobError}</p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="inputEmail3" className="col-sm-4 col-form-label">Nationality <sup>*</sup></label>
                                    <div className="col-sm-8">
                                    <select className="form-control" onChange={this.onChange} name="nationality" id="exampleFormControlSelect1">
                                    <option>Select</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua &amp; Barbuda">
                                      Antigua &amp; Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia &amp; Herzegovina">
                                      Bosnia &amp; Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">
                                      British Indian Ocean Ter
                                    </option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">
                                      Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                      Dominican Republic
                                    </option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">
                                      Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">
                                      French Southern Ter
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">
                                      Netherland Antilles
                                    </option>
                                    <option value="Netherlands">
                                      Netherlands (Holland, Europe)
                                    </option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">
                                      Republic of Montenegro
                                    </option>
                                    <option value="Republic of Serbia">
                                      Republic of Serbia
                                    </option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre &amp; Miquelon">
                                      St Pierre &amp; Miquelon
                                    </option>
                                    <option value="St Vincent &amp; Grenadines">
                                      St Vincent &amp; Grenadines
                                    </option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome &amp; Principe">
                                      Sao Tome &amp; Principe
                                    </option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">
                                      Trinidad &amp; Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks &amp; Caicos Is">
                                      Turks &amp; Caicos Is
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">
                                      United Arab Emirates
                                    </option>
                                    <option value="United States of America">
                                      United States of America
                                    </option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">
                                      Vatican City State
                                    </option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">
                                      Virgin Islands (Brit)
                                    </option>
                                    <option value="Virgin Islands (USA)">
                                      Virgin Islands (USA)
                                    </option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis &amp; Futana Is">
                                      Wallis &amp; Futana Is
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                        <p className="error">{this.state.validationErrors.nationalityError}</p> 
                                    </div>
                                </div>  
                          </div> 
                          <h4 className="smallheading mb-0">General information</h4>
                          <div className="tablebox"> 
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Company Registration</label> 
                                <div className="col-sm-6 customdatesm">
                                     {/* <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" maxDate={new Date()} className="form-control " value={ this.state.companyRegistration } selected={this.state.companyRegistration && new Date(this.state.companyRegistration)} name="searchTodate"   onChange={(date) => {
                                         this.handleDateChange('companyRegistration',date);}}  
                                         showYearDropdown
                                         scrollableMonthYearDropdown autoComplete="off"/>  */}
                                      <DatePicker placeholderText="yyyy-mm-dd" dateFormat='yyyy-MM-dd' value={ this.state.companyRegistration} maxDate={new Date()}  selected={this.state.companyRegistration} onChange={(dob) => {
                                         this.dateChanged('companyRegistration',dob);}} className="form-control" name="searchTodate" showYearDropdown
                                          scrollableMonthYearDropdown autoComplete="off"/>
                                 </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Vat Reference</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.vatRef} onChange={this.onChange} name="vatRef" id="inputEmail3" placeholder="Vat Reference" />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Tin/Tic</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.tin} onChange={this.onChange} name="tin" id="inputEmail3" placeholder="Tin/Tic" />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Tax Ref. Jurisdiction
                                </label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.taxRef} onChange={this.onChange} name="taxRef" id="inputEmail3" placeholder="Tax Reference Jurisdiction" />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Type of Company</label>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={this.onChange} name="companyType" id="exampleFormControlSelect1">
                                        <option>Select</option>
                                        <option  selected={this.state.companyType === '1' || this.state.companyType === 1 ?"AE":""} value="1">AE</option>
                                        <option  selected={this.state.companyType === '2' || this.state.companyType === 2 ?"BE":""} value="2">BE</option>
                                    </select>
                                </div>
                              </div> 
                          </div>  
                        </div>
                        <div className="col-md-6"> 
                          <h4 className="smallheading mb-0">  Profile Image  </h4>
                            <div className="tablebox"> 
                                <div className="profile-image-wr">
                                    <div className="pofile-image"> 
                                    <img src={(!this.state.prevProfileImg) ? `${require("../../assets/images/no-image-480x480.png")}` : this.state.prevProfileImg} className="img-fluid" alt={this.state.prevProfileImg} width="200px" height="200px"/>
                                     </div> 
                                    <input type="file" hidden id="choose-photo" onChange={(event) => this.handleProfileImageChange(event)} />
                                    <label className="file-upload" htmlFor="choose-photo"><i className="fa fa-pencil" aria-hidden="true" />Upload Profile Iamge</label> 
                                </div>
                            </div> 
                            <h4 className="smallheading mb-0">Registered Address</h4>
                            <div className="tablebox"> 
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Registered Address <sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.regAddress} onChange={this.onChange} name="regAddress" id="inputEmail3" placeholder="Registered Address" />
                                    <p className="error">{this.state.validationErrors.regAddressError}</p>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Post Code<sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.postCode} onChange={this.onChange} name="postCode" id="inputEmail3" placeholder="Post Code" />
                                    <p className="error">{this.state.validationErrors.postCodeError}</p>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">City<sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.city} onChange={this.onChange} name="city" id="inputEmail3" placeholder="City" />
                                    <p className="error">{this.state.validationErrors.cityError}</p>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Country<sup>*</sup></label>
                                <div className="col-sm-8">
                                <select className="form-control" onChange={this.onChange} name="country" id="exampleFormControlSelect1">
                                    <option>Select</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua &amp; Barbuda">
                                      Antigua &amp; Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia &amp; Herzegovina">
                                      Bosnia &amp; Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">
                                      British Indian Ocean Ter
                                    </option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">
                                      Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                      Dominican Republic
                                    </option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">
                                      Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">
                                      French Southern Ter
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">
                                      Netherland Antilles
                                    </option>
                                    <option value="Netherlands">
                                      Netherlands (Holland, Europe)
                                    </option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">
                                      Republic of Montenegro
                                    </option>
                                    <option value="Republic of Serbia">
                                      Republic of Serbia
                                    </option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre &amp; Miquelon">
                                      St Pierre &amp; Miquelon
                                    </option>
                                    <option value="St Vincent &amp; Grenadines">
                                      St Vincent &amp; Grenadines
                                    </option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome &amp; Principe">
                                      Sao Tome &amp; Principe
                                    </option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">
                                      Trinidad &amp; Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks &amp; Caicos Is">
                                      Turks &amp; Caicos Is
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">
                                      United Arab Emirates
                                    </option>
                                    <option value="United States of America">
                                      United States of America
                                    </option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">
                                      Vatican City State
                                    </option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">
                                      Virgin Islands (Brit)
                                    </option>
                                    <option value="Virgin Islands (USA)">
                                      Virgin Islands (USA)
                                    </option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis &amp; Futana Is">
                                      Wallis &amp; Futana Is
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                    <p className="error">{this.state.validationErrors.countryError}</p>
                                </div>
                              </div> 
                            </div> 
                            <h4 className="smallheading mb-0">Contact Information</h4>
                            <div className="tablebox"> 
                                <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Mobile Number <sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="tel" className="form-control" value={this.state.phoneNumber} onChange={this.onChange} name="phoneNumber" id="inputEmail3" placeholder="Mobile Number" />
                                    <p className="error">{this.state.validationErrors.phoneNumberError}</p>
                                </div>
                                </div>
                                <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Email Address <sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input type="email" className="form-control" value={this.state.email} onChange={this.onChange} name="email" id="inputEmail3" placeholder="Email Address" />
                                    <p className="error">{this.state.validationErrors.emailError}</p>
                                </div>
                                </div> 
                            </div>
                            <h4 className="smallheading mb-0">Additional Information</h4>
                            <div className="tablebox"> 
                                <div className="form-group row">
                                  <label for="inputEmail3" className="col-sm-5 col-form-label">Referred By (Client number)</label>
                                  <div className="col-sm-7">
                                  <input type="number" className="form-control" value={this.state.refBy} onChange={this.onChange} name="refBy" id="inputEmail3" placeholder="Referred By (Client number)" />
                                  </div>
                                </div> 
                            </div> 
                            <div className="row text-center ml-auto">
                                <div className="col-sm-12">
                                    <Link to="/client" className="btn btn-primary greybtn">Cancel</Link> 
                                    <button type="submit" className="btn btn-primary bluebg">
                                    <div className="buttonloader"><div className={`loader_wrap ${ this.state.btnLaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                        Save Client
                                    </button> 
                                </div>
                                
                            </div>
                        </div> 
                      </div>
                    </form>
                    </div> 
                  </div> 
            </div> 
        );
    }
}

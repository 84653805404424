import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
 
export default class OpenNewAccount extends Component {

    constructor(props) {
        super(props); 
          this.state = {
            clientAccount: {
                client_mid : '',
                account_type: '',
                currency: '',
                waive_fee: 0,
                account_name: '',
                opening_details: '',
                remarks: '',
                mandate_instructions: '',
                notification: 0,
            },
            currency:[], 
            accountTypes:[],
            mandateInstructions:[],
            validationErrors: {
                accountTypeError            :'',
                currencyError               :'', 
                accountNameError            :'',
                openingDetailsError         :'',
                remarksError                :'',
                mandateInstructionsError    :'',
                notificationError           :'', 
            },
            btnlaoder:false
            
        };
     
    }
    async componentDidMount() {

       
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
            this.setState(Object.assign(this.state.clientAccount,{client_mid:checkToken.cm_id}));
            this.getConfig();
         
       
        }
       
    }
    async getConfig()
    {
          
          
            let checkTokenData = localStorage.getItem("userToken");
           
                let checkToken = JSON.parse(checkTokenData);
        
                let accessToken = checkToken.access_token;
        
                //get listing
                await axios.get(`${window.$URL}getConfigs?account_types=true&currency=true&mandate_instructions=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    
                    if (response.data.status) { 
                        if(response.data.data.account_types){
                            this.setState({accountTypes:response.data.data.account_types}); 
                        }
                        if(response.data.data.currency){
                            this.setState({currency:response.data.data.currency}); 
                        }
                        if(response.data.data.mandate_instructions){
                            this.setState({mandateInstructions:response.data.data.mandate_instructions}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing
 
    }
    onChange = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target; 
         const { clientAccount } = this.state;
         this.setState({
            clientAccount: {
                 ...clientAccount,
                 [name]: value
             }
         },this.validate);
         
    }
    onChange2 = (e) => {
        e.preventDefault(); 
          this.setState({ notification: e.target.value })
     }
    validate = () => {
       const{ clientAccount } = this.state;
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let formvalidateStatus = true;

        if (clientAccount.account_type.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{accountTypeError:'Please Select A Account'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{accountTypeError:''}));
        }

        if (clientAccount.currency.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{currencyError:'Please Select A Currency'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{currencyError:''}));
        }

        /*if (clientAccount.waive_fee.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{waveMonthlyFeesError:'select an option'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{waveMonthlyFeesError:''}));
        }*/

        if (clientAccount.account_name.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{accountNameError:'Please Enter Account Name'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{accountNameError:''}));
        }
        /*
        if (clientAccount.opening_details.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{openingDetailsError:'Enter Opening Details'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{openingDetailsError:''}));
        } */  

            
        if (clientAccount.mandate_instructions.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{mandateInstructionsError:'Select Mandate Instruction'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{mandateInstructionsError:''}));
        }

        /*if (clientAccount.notification.length < 1) { 
             this.setState(Object.assign(this.state.validationErrors,{notificationError:'Select Notification'}));
            formvalidateStatus = false;
        }else{
             this.setState(Object.assign(this.state.validationErrors,{notificationError:''}));
        }*/ 
        return formvalidateStatus
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const { account_type, currency, waive_fee, account_name, opening_details, remarks, mandate_instructions, notification, created_by,token } = this.state;



        const isValid = this.validate();
        //
        if (isValid) {
            this.setState({btnlaoder:true});
            //alert('pk');
            let accountData =  this.state.clientAccount; 
            axios.post(`${window.$URL}client/open-client-accounts`, accountData,{ headers: {"Authorization" : `Bearer ${token}`}})
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false
                        })

                        
                        this.props.history.push('/client-portla/personal-accounts');
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                    this.setState({btnlaoder:false});
                })
                .catch(error => {
                    console.error(error.data)
                    this.setState({btnlaoder:false});
                })
        }
    }
 
    render() {
        const {            
            currency,mandateInstructions,accountTypes 
        } = this.state;
          
        let currencyOption = currency.length > 0
        && currency.map((item, index) =>
                <option value={item.value}>{item.name}</option>
            );
        let mandateOptions = mandateInstructions.length > 0
        && mandateInstructions.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let accountTypeOptions = accountTypes.length > 0
        && accountTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        
        return (
            
            <div className="wrapper wrappermax">
                  <ToastContainer/>
                {/* <div className="menubox">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                </div> */}
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 ">
                                <h3 className="heading">Open Account</h3> 
                                <div className="row justify-content-center">
                                <div className="lefeArea col-md-9"> 

                                    <form onSubmit={this.onSubmit}>
                                        <h4 className="smallheading mt-2 mb-0">General Configuration</h4>
                                        <div className="tablebox"> 
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Type <sup>*</sup></label>
                                                <div className="col-sm-8">
                                                    <select name="account_type" className="form-control" id="exampleFormControlSelect1" onChange={this.onChange} >
                                                        <option value="0">Select account type</option>
                                                        {accountTypeOptions}
                                                    </select>
                                                    <p className="error">{this.state.validationErrors.accountTypeError}</p>
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Currency <sup>*</sup></label>
                                                <div className="col-sm-8">
                                                    <select name="currency" className="form-control" id="exampleFormControlSelect1" onChange={this.onChange}>
                                                        <option>Select Currency</option>
                                                        {currencyOption}
                                                    </select>
                                                    <p className="error">{this.state.validationErrors.currencyError}</p>
                                                </div>
                                            </div>  
                                        </div> 
                                        {this.state.role != "Client" &&
                                            <>
                                            <h4 className="smallheading mb-0">Charges and Fees</h4>
                                            <div className="tablebox">   
                                            <div className="from-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Walve Monthly Fees</label>
                                                        <div className="col-sm-8"> 
                                                            <select name="walve_monthly_fees" className="form-control" onChange={this.onChange} value={this.state.clientAccount.walve_monthly_fees} id="exampleFormControlSelect1" >
                                                                    <option value="0">No</option>
                                                                    <option value="1">Yes</option>
                                                                </select>
                                                            <p className="error">{this.state.validationErrors.waveMonthlyFeesError}</p>
                                                        </div>
                                                    </div>
                                            </div>
                                            </>
                                        }
                                        <h4 className="smallheading mb-0">General Information</h4> 
                                        <div className="tablebox"> 
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Account Name <sup>*</sup></label>
                                                <div className="col-sm-8">
                                                    <input name="account_name" type="text" className="form-control" id="inputEmail3" placeholder="Current Account" onChange={this.onChange} />
                                                    <p className="error">{this.state.validationErrors.accountNameError}</p>
                                                </div>
                                            </div> 
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Mandate Instructions  <sup>*</sup></label>
                                                <div className="col-sm-8">
                                                    <select name="mandate_instructions" className="form-control" onChange={this.onChange} id="exampleFormControlSelect1">
                                                        <option>Please select</option>
                                                        {mandateOptions}
                                                    </select>
                                                    <p className="error">{this.state.validationErrors.mandateInstructionsError}</p>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Opening Details</label>
                                                <div className="col-sm-8">
                                                    <input type="text" name="opening_details" className="form-control" id="inputEmail3" placeholder="Opening Details" onChange={this.onChange} />
                                                </div>
                                            </div> 
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Remarks  </label>
                                                <div className="col-sm-8">
                                                    <textarea name="remarks" className="form-control w-100" id="exampleFormControlTextarea1" onChange={this.onChange} defaultValue={"  "} />
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.role != "Client" &&
                                            <>
                                                <h4 className="smallheading mb-0">Notifications</h4>
                                                <div className="tablebox"> 
                                                    <div className="row form-group">
                                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Send Notification On opening the account</label>
                                                        <div className="col-sm-8"> 
                                                            <select name="notification" className="form-control" onChange={this.onChange} id="exampleFormControlSelect1">
                                                                <option>Please select</option>
                                                                <option value="1">Yes</option>
                                                                <option value="2">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }  
                                        <div className="row justify-content-center mr-0">
                                                    <div className="text-center btncenter">
                                                            <Link
                                                                className="btn btn-primary bluebg"
                                                                to={{
                                                                pathname: `/client/client-accounts/${this.props.match.params.clientId}`, 
                                                                clientId:this.props.clientId,
                                                                state: {
                                                                    clientId: this.props.clientId  
                                                                }
                                                                }}
                                                            >
                                                            Back</Link>
                                                        <button type="submit" className="btn btn-primary bluebg"><div className="buttonloader"><div className={`loader_wrap ${ this.state.btnlaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Save</button>
                                                    </div>
                                                
                                            </div>
                                    </form> 
                               
                                </div>
                                </div>

                            </div> 
                    </div>
                </div>
            </div> 
        );
    }
}

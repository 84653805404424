import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu" 
import TopMenu from "./TopMenu";
import PrintComponents from "react-print-components"; 
import PrintDetails from "../Component/PrintDetails"; 
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class viewClient extends Component {


  state = {
    clientType: '',
    companyName: '',
    companyNumber: '',
    companyWebsite: '',
    clientTypeError: '',
    companyNameError: '',
    companyNumberError: '',
    companyWebsiteError: '',


    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    title: '',
    dob: '',
    nationality: '',
    firstNameError: '',
    middleNameError: '',
    lastNameError: '',
    genderError: '',
    titleError: '',
    dobError: '',
    nationalityError: '',


    companyRegistration: '',
    vatRef: '',
    tin: '',
    taxRef: '',
    companyType: '',
    companyRegistrationError: '',
    vatRefError: '',
    tinError: '',
    taxRefError: '',
    companyTypeError: '',


    regAddress: '',
    postCode: '',
    city: '',
    country: '',
    createdBy: '',
    createdAt: '',
    lastModificationUser: '',
    lastDateCollection: '',
    lastDateRecollection: '',
    regAddressError: '',
    postCodeError: '',
    cityError: '',
    countryError: '',
    createdByError: '',
    createdAtError: '',
    lastModificationUserError: '',
    lastDateCollectionError: '',
    lastDateRecollectionError: '',


    phoneNumber: '',
    email: '',
    refBy: '',
    phoneNumberError: '',
    emailError: '',
    refByError: '',


    successMessage: '',
    success: false,
    errorMessage: '',
    error: false,
    emailShow: true,
    mobileShow: false,
    buttonData: false,
    Details: '',
    popularCategoryId: '',
    UpdateData: false,
    EditDetails: [],
    showSweetAlert: false,
    requestedId: '',
    filterDetails: [],
    showItems: '',
    modalDialog: '',
    modalStatus: false,
    sort: {
        column: null,
        direction: 'desc',
    },
    pageLimit: 10,
    pageCount: 0,
    totalCount: 0,
    showingFrom: 0,
    showingTo: 0,
    paginationCountText: '',
    delteId: '',
    token:'',
     validationErrors:{
        clientTypeError: '',
        companyNameError: '',
        companyNumberError: '',
        companyWebsiteError: '',
        firstNameError: '',
        middleNameError: '',
        lastNameError: '',
        genderError: '',
        titleError: '',
        dobError: '',
        nationalityError: '',
        companyRegistrationError: '',
        vatRefError: '',
        tinError: '',
        taxRefError: '',
        companyTypeError: '',
        regAddressError: '',
        postCodeError: '',
        cityError: '',
        countryError: '',
        createdByError: '',
        createdAtError: '',
        lastModificationUserError: '',
        lastDateCollectionError: '',
        lastDateRecollectionError: '',
        phoneNumberError: '',
        emailError: '',
        refByError: ''
    },
    companybox:false,
    createdAt:'',
    updatedAt:'',
    updatedBy:'',
    status:'',
    ProfileImg: null,
    profileImageInDB: '',
    userId:'', 
    profileImageUpload:false,
    pagelaoder:true,
    accounts:[]



}
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
             
        }
        if (this.props.location.state) {
            //console.log("===================="+this.props.location.state.clientId); 
            //console.log('++++++++++++++++++++', this.props.match.params.clientId)
            
        }else{
          this.props.history.push(`/client`);
        }
        this.getclientList()

    }


    async getclientList()
    {
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
        
            let accessToken = checkToken.access_token;
        
          //get listing
          await axios.get(`${window.$URL}client/edit/`+clientId,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              
              if(response.data.status){  
                if(response.data.data.client_type === "1" || response.data.data.client_type === 1)
                {
                     //console.log('corporate_master')
                    this.setState({pagelaoder:false}); 
                    this.setState({
                               clientType: response.data.data.client_type,
                                companyName: response.data.data.get_corporate_master.company_name,
                                companyNumber: response.data.data.get_corporate_master.company_number,
                                companyWebsite: response.data.data.get_corporate_master.company_website, 
                                firstName: response.data.data.get_corporate_signatories.first_name,
                                middleName: response.data.data.get_corporate_signatories.middle_name,
                                lastName: response.data.data.get_corporate_signatories.surname,
                                gender: response.data.data.get_corporate_signatories.gender,
                                title: response.data.data.get_corporate_signatories.title,
                                
                                dob: response.data.data.get_corporate_signatories.dob,
                                nationality: response.data.data.get_corporate_signatories.nationality,
                                companyRegistration: response.data.data.get_corporate_general.registration_date,

                                // tin: response.data.data.get_corporate_signatories.nationality,
                                taxRef: response.data.data.get_corporate_general.tax_reference_jurisdiction,
                                vatRef: response.data.data.get_corporate_general.vat_reference,
                                tin: response.data.data.get_corporate_general.tin_tic,
                                regAddress: response.data.data.get_corporate_address.address_1,
                                postCode : response.data.data.get_corporate_address.post_code,
                                city : response.data.data.get_corporate_address.city,
                                country : response.data.data.get_corporate_address.country,
                                email:response.data.data.get_corporate_contact.email,
                                phoneNumber:response.data.data.get_corporate_contact.contact_number,
                                refBy : response.data.data.referred_by,
                                companybox:true,
                                companyType:response.data.data.get_corporate_general.type_of_company,
                                createdBy:response.data.data.created_by,
                                updatedBy:response.data.data.updated_by,
                                updatedAt:moment(response.data.data.updated_at).format("YYYY-MM-DD"),
                                createdAt:moment(response.data.data.created_at).format("YYYY-MM-DD"),
                                status:response.data.data.status,
                                prevProfileImg:response.data.data.get_user.profile_image,
                                userId:response.data.data.get_user.id,
                    })
                }else if(response.data.data.client_type === "2" || response.data.data.client_type === 2)
                { 
                  this.setState({pagelaoder:false});
                    this.setState({
                                clientType: response.data.data.client_type, 
                                firstName: response.data.data.get_individual_master.first_name,
                                middleName: response.data.data.get_individual_master.middle_name,
                                lastName: response.data.data.get_individual_master.surname,
                                gender: response.data.data.get_individual_master.gender,
                                title: response.data.data.get_individual_master.title,
                                dob: response.data.data.get_individual_master.dob,
                                nationality: response.data.data.get_individual_master.nationality,

                                regAddress: response.data.data.get_individual_address.address_1,
                                postCode : response.data.data.get_individual_address.post_code,
                                city : response.data.data.get_individual_address.city,
                                country : response.data.data.get_individual_address.country,

                                
                                companyRegistration: response.data.data.get_individual_general.registration_date,
                                vatRef: response.data.data.get_individual_general.vat_reference,
                                tin: response.data.data.get_individual_general.tin_tic,
                                taxRef: response.data.data.get_individual_general.tax_reference_jurisdiction,
                                companyType:response.data.data.get_individual_general.type_of_company,

                                email:response.data.data.get_individual_contact.email,
                                phoneNumber:response.data.data.get_individual_contact.contact_number, 
                                createdBy:response.data.data.created_by,
                                
                                updatedBy:response.data.data.updated_by,
                                updatedAt:moment(response.data.data.updated_at).format("YYYY-MM-DD"),
                                createdAt:moment(response.data.data.created_at).format("YYYY-MM-DD"),
                                status:response.data.data.status,
                                prevProfileImg:response.data.data.get_user.profile_image,
                                userId:response.data.data.get_user.id,
                                refBy : response.data.data.referred_by,
                      })
                }
                this.setState(
                    { 
                        accounts:response.data.data.accounts,
                        clientNo:response.data.data.client_no
                    }
                );  
            }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }

        }
    }


    render() {
        const { 
            showSweetAlert,
            modalDialog,            
            clientType,
            companyName,
            companyNumber,
            companyWebsite,
            firstName,
            middleName,
            lastName,
            gender,
            title,
            dob,
            nationality,
            companyRegistration,
            vatRef,
            tin,
            taxRef,
            companyType,
            regAddress,
            postCode,
            city,
            country,
            createdBy,
            createdAt,
            lastModificationUser,
            lastDateCollection,
            lastDateRecollection,
            phoneNumber,
            email,
            refBy,           
            clientTypeError,
            companyNameError,
            companyNumberError,
            companyWebsiteError,
            firstNameError,
            middleNameError,
            lastNameError,
            genderError,
            titleError,
            dobError,
            nationalityError,
            companyRegistrationError,
            vatRefError,
            tinError,
            taxRefError,
            companyTypeError,
            regAddressError,
            postCodeError,
            cityError,
            countryError,
            createdByError,
            createdAtError,
            lastModificationUserError,
            lastDateCollectionError,
            lastDateRecollectionError,
            phoneNumberError,
            emailError,
            refByError,
            success,
            error,
            successMessage,
            errorMessage,
            profileImageInDB
        } = this.state;
        return (
            <div className="wrapper">
              <ToastContainer />  
              <div className="container newContainer mainArea pt-0" id="formpage">
                <div className="row justify-content-center"> 
                  <div className="lefeArea col-md-9 p-0">
                    <h3 className="heading">Client View</h3> 
                    <TopMenu clientId={ this.props.match.params.clientId } activeMenu="view_client" relationAndOnlineaccess={this.props.location.state.relationAndOnlineaccess}/>   
                     <div className="text-right mb-2"> 
                        <PrintComponents
                          trigger={<button className="btn btn-primary bluebg">Print Details</button>}
                        >
                          <PrintDetails data={this.state} accounts={this.state.accounts}/>
                        </PrintComponents> 
                        <Link
                            className="btn btn-primary bluebg"
                            to={{
                            pathname: `/client`,  
                            }}
                        >Back to Clients</Link> 
                    </div>
                    <div className="content-wrap">
                      <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                      <div className="row justify-content-center"> 
                        <div className="col-md-6">
                        <h4 className="smallheading mb-0">Client Information</h4>
                        <div className="tablebox">
                                <div className="form-group row">
                                    <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Client Type <sup>*</sup> {clientTypeError ? <span style={{ color: 'red' }}> {clientTypeError} </span> : null}</label>
                                    <div className="col-sm-8">
                                        <select className="form-control" disabled={true} onChange={this.onChange} name="clientType" id="exampleFormControlSelect1">
                                            <option>--SELECT--</option>
                                            <option selected={this.state.clientType === '1' || this.state.clientType === 1 ?"Corporate":""}>Corporate</option>
                                            <option selected={this.state.clientType === '2' || this.state.clientType === 2 ?"Individual":""}>Individual</option>
                                        </select>
                                        
                                    </div>
                                </div>
                                { (this.state.companybox === true) &&
                                    <div className="comapny_box"> 
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company Name <sup>*</sup> {companyNameError ? <span style={{ color: 'red' }}> {companyNameError} </span> : null}</label>
                                            <div className="col-sm-8">
                                                <input type="text" value={this.state.companyName} className="form-control" onChange={this.onChange} name="companyName" id="inputEmail3" placeholder="Company Name" readOnly/>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company Number <sup>*</sup>{companyNumberError ? <span style={{ color: 'red' }}> {companyNumberError} </span> : null}</label>
                                            <div className="col-sm-8">
                                                <input type="text" value={this.state.companyNumber} className="form-control" onChange={this.onChange} name="companyNumber" id="inputEmail3" placeholder="Company Number" readOnly/>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Company website {companyWebsiteError ? <span style={{ color: 'red' }}> {companyWebsiteError} </span> : null}</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" value={this.state.companyWebsite} onChange={this.onChange} value={this.state.companyWebsite} name="companyWebsite" id="inputEmail3" placeholder="Company website" readOnly/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                }
                        </div>
                          <h4 className="smallheading mb-0">Signatories</h4>
                          <div className="tablebox">
                            <form>
                            <div className="form-group row">
                                  <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Title <sup>*</sup>
                                  </label>
                                  <div className="col-sm-8">
                                          <select className="form-control" onChange={this.onChange} value={this.state.title} defaultValue={this.state.title} name="title" disabled="true">
                                              <option value="0">Title</option>
                                              <option value="1">Mr.</option>
                                              <option value="2">Mrs.</option>
                                              <option value="3">Miss.</option>
                                              <option value="4">Dr.</option>
                                              <option value="5">Ms.</option>
                                              <option value="6">Prof.</option>
                                          </select>
                                      
                                        <p className="error">{this.state.validationErrors.titleError}</p>                                             
                                  </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">First Name <sup>*</sup>
                                </label>
                                <div className="col-sm-8">
                                <input type="text" value={this.state.firstName} className="form-control" onChange={this.onChange} name="firstName" id="inputEmail3" placeholder="First Name" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Middle Name</label>
                                <div className="col-sm-8">
                                <input type="text" value={this.state.middleName} className="form-control" onChange={this.onChange} name="middleName" id="inputEmail3" placeholder="Middle Name" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Surname <sup>*</sup>
                                </label>
                                <div className="col-sm-8">
                                <input type="text" value={this.state.lastName} className="form-control" onChange={this.onChange} name="lastName" id="inputEmail3" placeholder="Surname" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                                  <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Gender <sup>*</sup> {genderError ? <span style={{ color: 'red' }}> {genderError} </span> : null}</label>
                                                  <div className="col-sm-8">
                                                      <select className="form-control" onChange={this.onChange} name="gender" id="exampleFormControlSelect1" value={this.state.gender} defaultValue={this.state.gender} disabled="true">
                                                          <option>Select</option>
                                                          <option value="1" selected={this.state.gender===1?"1":""}>Male</option>
                                                          <option value="2" selected={this.state.gender===2?"2":""}>Female</option>
                                                      </select>
                                                      
                                                  </div>
                                              </div>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">DOB</label>
              
                                <div className="col-sm-6">
                                <DatePicker dateFormat='yyyy-MM-dd'  value={this.state.dob} id="datepicker" className="form-control" onChange={this.onChange} name="dob" placeholder="yyyy-mm-dd" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Nationality
                                </label>
                                <div className="col-sm-8">
                                <input type="text"  value={this.state.nationality} className="form-control" onChange={this.onChange} name="nationality" id="inputEmail3" placeholder="Nationality" readOnly/>
                                </div>
                              </div>
                              
                              {/* <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Marital Status</label>
                                <div className="col-sm-8">
                                  <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Widowed</option>
                                    <option>Divorced</option>
                                    <option>Separated</option>
                                  
                                  </select>
                                </div>
                              </div> */}
                            </form>
                          </div>
              
                          <h4 className="smallheading mb-0">General information</h4>
                          <div className="tablebox">
                            <form>
                              <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Company Registration</label>
              
                                <div className="col-sm-6">
                                <DatePicker dateFormat='yyyy-MM-dd' /* id="datepicker2" */ className="form-control" value={this.state.companyRegistration} onChange={this.onChange} name="companyRegistration" placeholder="yyyy-mm-dd" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Vat Reference</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.vatRef} onChange={this.onChange} name="vatRef" id="inputEmail3" placeholder="Vat Reference" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Tin/Tic</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.tin} onChange={this.onChange} name="tin" id="inputEmail3" placeholder="Tin/Tic" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Tax Ref. Jurisdiction
                                </label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.taxRef} onChange={this.onChange} name="taxRef" id="inputEmail3" placeholder="Tax Reference Jurisdiction" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Type of Company</label>
                                <div className="col-sm-8">
                                                  <select className="form-control" onChange={this.onChange} value={this.state.companyType} name="companyType" id="exampleFormControlSelect1" disabled="true">
                                                          <option value="">Select</option>
                                                          <option value="1">AE</option>
                                                          <option value="2">BE</option>
                                                      </select>
                                </div>
                              </div>
                            </form>
                          </div>
              
                          <h4 className="smallheading mb-0">Registered Address</h4>
                          <div className="tablebox">
                            <form>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Registered Address <sup>*</sup></label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.regAddress} onChange={this.onChange} name="regAddress" id="inputEmail3" placeholder="Registered Address" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Post Code</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.postCode} onChange={this.onChange} name="postCode" id="inputEmail3" placeholder="Post Code" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">City</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control" value={this.state.city} onChange={this.onChange} name="city" id="inputEmail3" placeholder="City" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Country</label>
                                <div className="col-sm-8">
                                <select className="form-control" onChange={this.onChange} name="country" value={this.state.country} id="exampleFormControlSelect1" disabled="true">
                                    <option value="">Select</option>
                                    <option value="Afganistan">Afghanistan</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua &amp; Barbuda">
                                      Antigua &amp; Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia &amp; Herzegovina">
                                      Bosnia &amp; Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">
                                      British Indian Ocean Ter
                                    </option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">Canary Islands</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">
                                      Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">Channel Islands</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos Island">Cocos Island</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                      Dominican Republic
                                    </option>
                                    <option value="East Timor">East Timor</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">
                                      Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">Falkland Islands</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Ter">
                                      French Southern Ter
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">Great Britain</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">Korea North</option>
                                    <option value="Korea Sout">Korea South</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">Midway Islands</option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">
                                      Netherland Antilles
                                    </option>
                                    <option value="Netherlands">
                                      Netherlands (Holland, Europe)
                                    </option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">Palau Island</option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">Philippines</option>
                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">
                                      Republic of Montenegro
                                    </option>
                                    <option value="Republic of Serbia">
                                      Republic of Serbia
                                    </option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">St Barthelemy</option>
                                    <option value="St Eustatius">St Eustatius</option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">St Maarten</option>
                                    <option value="St Pierre &amp; Miquelon">
                                      St Pierre &amp; Miquelon
                                    </option>
                                    <option value="St Vincent &amp; Grenadines">
                                      St Vincent &amp; Grenadines
                                    </option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">Samoa American</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome &amp; Principe">
                                      Sao Tome &amp; Principe
                                    </option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad &amp; Tobago">
                                      Trinidad &amp; Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks &amp; Caicos Is">
                                      Turks &amp; Caicos Is
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">
                                      United Arab Emirates
                                    </option>
                                    <option value="United States of America">
                                      United States of America
                                    </option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">
                                      Vatican City State
                                    </option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">
                                      Virgin Islands (Brit)
                                    </option>
                                    <option value="Virgin Islands (USA)">
                                      Virgin Islands (USA)
                                    </option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Wallis &amp; Futana Is">
                                      Wallis &amp; Futana Is
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                </div>
                              </div>
                              
                            </form>
                          </div>
                          <h4 className="smallheading mb-0">Contact Information</h4>
                          <div className="tablebox">
                            <form>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Mobile Number <sup>*</sup></label>
                                <div className="col-sm-8">
                                <input type="tel" className="form-control" value={this.state.phoneNumber} onChange={this.onChange} name="phoneNumber" id="inputEmail3" placeholder="Mobile Number" readOnly/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label for="inputEmail3" className="col-sm-4 col-form-label">Email Address <sup>*</sup></label>
                                <div className="col-sm-8">
                                <input type="email" className="form-control" value={this.state.email} onChange={this.onChange} name="email" id="inputEmail3" placeholder="Email Address" readOnly/>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-6"> 
                          <h4 className="smallheading mb-0">
                            Profile Image
                            </h4>
                          <div className="tablebox"> 
                            <div className="profile-image-wr">
                                <div className="pofile-image">
                                {
                                  
                                profileImageInDB !== null && !this.state.prevProfileImg ?
                                <img src={`${window.$ImageURL}` + profileImageInDB} className="img-fluid" alt={profileImageInDB}  width="50%"/>
                                :
                                <img src={(!this.state.prevProfileImg) ? `${window.$ImageURL}no-image-480x480.png` :this.state.prevProfileImg} className="img-fluid" alt={this.state.prevProfileImg}  width="50%"/>
                                }
                                </div> 
                            </div>
                          </div> 
                            <h4 className="smallheading mb-0">
                              System Information (Automated functionality)
                            </h4>
                            <div className="tablebox"> 
                                <div className="form-group row">
                                  <label for="inputEmail3" className="col-sm-5 col-form-label">Created By</label>
                                  <div className="col-sm-7">
                                  <input type="tel" className="form-control" value={this.state.createdBy} onChange={this.onChange} name="createdBy" id="inputEmail3" placeholder="Created By" readOnly/>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-5 col-form-label">Created Date</label>
              
                                  <div className="col-sm-5">
                                  <DatePicker dateFormat='yyyy-MM-dd' /* id="datepicker6"  */value={this.state.createdAt} className="form-control" onChange={this.onChange} name="createdAt" placeholder="yyyy-mm-dd" readOnly/>
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label for="inputEmail3" className="col-sm-5 col-form-label">Last modification User</label>
                                  <div className="col-sm-7">
                                  <input type="tel" className="form-control" value={this.state.updatedBy} onChange={this.onChange} name="lastModificationUser" id="inputEmail3" placeholder="Last modification User" readOnly/>
                                  </div>
                                </div> */}
                                {/* <div className="form-group row">
                                  <label className="col-sm-5">Last Date of Collection</label>
              
                                  <div className="col-sm-6">
                                    <input id="datepicker4" className="form-control" placeholder="yyyy-mm-dd" readOnly/>
                                  </div>
                                </div> */}
                                {/* <div className="form-group row">
                                  <label className="col-sm-5">Last Date recollection Date</label>
              
                                  <div className="col-sm-5">
                                  <input type="date" id="datepicker3" value={this.state.lastDateCollection} className="form-control" onChange={this.onChange} name="lastDateCollection" placeholder="yyyy-mm-dd" readOnly/>
                                  </div>
                                </div>   */}
                            </div>
                            <h4 className="smallheading mb-0">Additional Information</h4>
                            <div className="tablebox"> 
                                <div className="form-group row">
                                  <label for="inputEmail3" className="col-sm-5 col-form-label">Referred By (Client number)</label>
                                  <div className="col-sm-7">
                                  <input type="tel" className="form-control" value={this.state.refBy} onChange={this.onChange} name="refBy" id="inputEmail3" placeholder="Referred By (Client number)" readOnly/>
                                  </div>
                                </div> 
                            </div> 
                        </div> 
                      </div>
                    </div> 
                  </div>
                  {/* <rightPanel/> */}
                  <RightMenu clientId={ this.props.match.params.clientId } activeMenu="view_client" relationAndOnlineaccess={this.props.location.state.relationAndOnlineaccess}/>  
                </div>
              </div> 
            </div> 
        );
    }
}
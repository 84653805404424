import React, { useState } from 'react';
     
const PrintDetails = (props) => { 
  const details = props.data;
  const filterDetails = props.accounts;
  return (
     <div className="receipt-print-wrap"> 
          <table style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr style={{ textAlign:'center' }}>
                  <td >
                    <img src={require('../../../assets/images/logo.png')} style={{ width:"150px" }}alt="" className="print-logo" />
                    <div style={{ margin:"15px 0px" }}> 
                      <p><label className="print-label">Client Number </label>: {details.clientNo}</p>
                    </div>  
                  </td> 
              </tr>
            </tbody>
          </table>
          <h2 style={{ background:"#0097d4",color:"#fff",padding:"15px",marginBottom:"25px",textAlign:"center" }}> Client Details </h2>
          <table className="table table-bordered table-responsive-sm" style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Client Type: </label>
                  </td>
                  <td>
                     <p>{details.clientType === 1 ?"Corporate":"Individual"}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Client Name: </label>
                  </td>
                  <td>
                     <p>{details.title===1?"Mr.":"Mrs."} {details.firstName} {details.middleName} {details.lastName}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Gender : </label>
                  </td>
                  <td>
                     <p>{details.gender===1?"Male":"Female"}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">DOB : </label>
                  </td>
                  <td>
                     <p>{details.dob}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Nationality : </label>
                  </td>
                  <td>
                     <p>{details.nationality }</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Mobile Number  : </label>
                  </td>
                  <td>
                     <p>{details.phoneNumber}</p>
                  </td> 
              </tr>
              <tr>
                  <td style={{ width:"15%" }}>
                     <label className="print-label">Email Address  : </label>
                  </td>
                  <td>
                     <p>{details.email}</p>
                  </td> 
              </tr>
            </tbody>
          </table> 
           <h2 style={{ background:"#0097d4",color:"#fff",padding:"15px",margin:"25px 0px",textAlign:"center" }}> Client Accounts</h2>
           <table className="table table-bordered table-responsive-sm">
              <thead>
                  <tr>  
                  <th scope="col">Account No</th>
                  <th scope="col">Description</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Interest</th>
                  <th scope="col">Blocked</th>
                  <th scope="col" class="d-none">Available</th> 
                  <th scope="col">Status</th> 
                  </tr>
              </thead>
                                            
            <tbody>
              {
                  filterDetails.length > 0 ?
                      filterDetails.map((data, index) => {
                          
                          return (
                              <tr> 
                                  
                                  <td>{data.account_no}</td>
                                  <td>{data.description}</td>
                                  <td>{data.currency}</td>
                                  <td>{data.balance}</td>
                                  <td>{data.interest}</td>
                                  <td>{data.blocked}</td> 
                                  <td class="d-none">{data.available}</td>  
                                  <td class={data.status == '1' ? "bggreen" : "bgred"}>{data.status_text}</td>
                                    
                              </tr>
                          )
                      })
                      :
                      <tr>
                          <td colSpan={5}>No result found</td>
                      </tr>
              }
            </tbody>
        </table> 
        </div> 
  );
}
export default PrintDetails
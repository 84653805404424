import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from "./RightMenu"
import moment from 'moment';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import TopMenu from "./TopMenu" 

export default class addClientIdentification extends Component {


    state = {
        document_type: '',
        drivers_licence_number: '',
        issued_date: '',
        country_issued: '',
        location_issued: '',

        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token:'',

        validationErrors:{
          document_typeError: '',
          drivers_licence_numberError: '',
          country_issuedError: '',
          issued_dateError:'',
      },
      showLicenseNumber:false,
      uploaded_document_1:'',
      uploaded_document_2:'',
      uploaded_document_3:'',
      document_1:'',
      document_2:'',
      document_3:'',



    }
    async componentDidMount() {
        
        
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        //
        let accessToken = checkToken.access_token;
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            

        }
        // if(this.props.match.params.hasOwnProperty('id'))
        // {
        //     console.log('someerror',this.props.match.params.id)

        // }
        this.getSpecificIdentification();

    }
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value },this.validate)
        if(name=='document_type' && value!=''){
         
          this.setState({ showLicenseNumber:true})
          }
    }
    validate = () => {
        this.setState({          
            document_typeError: '',
            drivers_licence_numberError: '',
            country_issuedError: '',
        });
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
       

       let document_typeError= '';
       let drivers_licence_numberError= '';
       let country_issuedError='';

       let formvalidateStatus = true;

      if (this.state.document_type.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{document_typeError:'Please select document type'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{document_typeError:''}));
      } 

       if (this.state.drivers_licence_number.length < 1) {  
          this.setState(Object.assign(this.state.validationErrors,{drivers_licence_numberError:'Please enter licence number'}));
          formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{drivers_licence_numberError:''}));
      }
      if (this.state.country_issued.length < 1) {  
        this.setState(Object.assign(this.state.validationErrors,{country_issuedError:'Please select country'}));
        formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{country_issuedError:''}));
      } 
      if (this.state.location_issued.length < 1) {  
        this.setState(Object.assign(this.state.validationErrors,{location_issuedError:'Please enter expiry date'}));
        formvalidateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{location_issuedError:''}));
      }
      // if (this.state.document_1.length < 1) {  
      //     this.setState(Object.assign(this.state.validationErrors,{document_1Error:'Please Upload Min One Document'}));
      //     formvalidateStatus = false;
      // }else{ 
      //     this.setState(Object.assign(this.state.validationErrors,{document_1Error:''}));
      // }   



        this.setState({

            document_typeError,
            drivers_licence_numberError,
            country_issuedError,


        })
        return formvalidateStatus
    }
    async getSpecificIdentification()
    {
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
           let editId=this.props.match.params.id
        
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
        
            let accessToken = checkToken.access_token;
        
            //get listing
            await axios.get(`${window.$URL}client/client-specific-identification/`+editId,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    
                    if(response.data.status){ 
                        
                             
                            this.setState({
                                
                              document_type: response.data.data.document_type,
                              drivers_licence_number: response.data.data.drivers_licence_number,
                              issued_date: response.data.data.issued_date,
                              country_issued: response.data.data.country_issued,
                              location_issued: response.data.data.location_issued, 
                              uploaded_document_1:response.data.data.document_1,
                              uploaded_document_2:response.data.data.document_2,
                              uploaded_document_3:response.data.data.document_3,
                                
                            })
                            this.setState({ showLicenseNumber:true})
                        
                    }
                })
                .catch(error => {
                    console.error(error.data)
                }) 
            }  
        }
    }  
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const { document_type,
            drivers_licence_number,
            issued_date,
            country_issued,
            location_issued,token } = this.state;

        

        const isValid = this.validate();
        //
        if (isValid) {
            //alert('pk');
            let teamData = {
                document_type: document_type,
                drivers_licence_number: drivers_licence_number,
                issued_date: issued_date,
                country_issued: country_issued,
                location_issued: location_issued,
                cm_id:this.props.match.params.clientId, 
            }
            var bodyLogoFormData = new FormData();
            bodyLogoFormData.set('document_type', document_type);
            bodyLogoFormData.set('drivers_licence_number', drivers_licence_number);
            bodyLogoFormData.set('issued_date', issued_date);
            bodyLogoFormData.set('country_issued', country_issued);  
            bodyLogoFormData.set('location_issued', location_issued); 
            bodyLogoFormData.set('cm_id', this.props.match.params.clientId); 
            if(this.state.document_1 !== null){  
              bodyLogoFormData.append('document_1', this.state.document_1);
            } 
            if(this.state.document_2 !== null){  
              bodyLogoFormData.append('document_2', this.state.document_2);
            }
            if(this.state.document_3 !== null){  
              bodyLogoFormData.append('document_3', this.state.document_3);
            }
          
           // return false;
            axios.post(`${window.$URL}client/update-client-identification/${this.props.match.params.id}`, bodyLogoFormData,{  headers: { 'Content-Type': 'multipart/form-data' ,
            "Authorization": `Bearer ${token}`}  })
                .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false,
                            drivers_licence_number:""
                        })
                        if( this.state.role === 'Super_Admin' || this.state.role === 'Tem_Member'){
                          this.props.history.push(`/client/client-identification/`+this.props.match.params.clientId);
                        }else{
                          this.props.history.push('/client-portla/personal-identifications');
                        } 
                        //this.componentDidMount();
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        })
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
        }
    }
    handleDocument1Change = (e) => {
          e.preventDefault();
          const img = e.target.files[0]; 
          this.setState({
              //message_document: URL.createObjectURL(img),
              document_1: img,
          })
      }
      handleDocument2Change = (e) => {
        e.preventDefault();
        const img = e.target.files[0]; 
        this.setState({
            //message_document: URL.createObjectURL(img),
            document_2: img,
        })
    }
    handleDocument3Change = (e) => {
      e.preventDefault();
      const img = e.target.files[0]; 
      this.setState({
          //message_document: URL.createObjectURL(img),
          document_3: img,
      })
    }

    handleDateChange = (name,date) => {  
      if(date){
        this.setState({
          [name]: moment(moment(date)).format('Y-MM-DD'), 
        })
      }else{
          this.setState({
              [name]: "", 
            })
      }
    }


    render() {
        const { 
            document_typeError,
            drivers_licence_numberError,
            issued_dateError,
            country_issuedError,
            location_issuedError,
            
        } = this.state;
        return ( 
          <div className="wrapper">
            <ToastContainer/>           
            <div className="container newContainer mainArea pt-0" id="formpage">
              <div className="row justify-content-center"> 
                <div className="lefeArea col-md-9 p-0"> 
                  { this.state.role !== 'Client' &&
                  <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_identifications"/>
                  }  
                  <div className="content-wrap">
                  <div className="row justify-content-center"> 
                    <div className="col-md-6">   
                      <h4 className="smallheading mb-0">Identification Details</h4>
                      <div className="tablebox">
                      <form onSubmit={this.onSubmit}> 
                          <div className="form-group row">
                            <label for="" className="col-sm-4 col-form-label">Document Type <sup>*</sup></label>
                            <div className="col-sm-8">
                                <select className="form-control w-100" name="document_type"  value={this.state.document_type} onChange={this.onChange} id="exampleFormControlSelect1">
                                  <option value="">--SELECT--</option>
                                  <option>Application</option>
                                  <option>Drivers License Number</option>
                                  <option>Family Registration Number</option>
                                  <option>Identity Card</option>
                                  <option>Passport</option> 
                                </select>
                                <p className="error">{this.state.validationErrors.document_typeError}</p>
                            </div>
                          </div>
                          {this.state.showLicenseNumber==true &&  
                            <div className="form-group row">
                              <label for="" className="col-sm-4 col-form-label">{this.state.document_type}<sup>*</sup></label>
                              <div className="col-sm-8">
                                <input type="text" name="drivers_licence_number" value={this.state.drivers_licence_number} onChange={this.onChange} className="form-control" id="inputEmail3" placeholder={this.state.document_type}/>
                                <p className="error">{this.state.validationErrors.drivers_licence_numberError}</p>
                              </div>
                            </div>
                          }
                          <div className="form-group row">
                            <label for="" className="col-sm-4 col-form-label">Issued date</label>
                            <div className="col-sm-8 customdatesm">
                             {/*  <input type="date" id="datepicker2" name="issued_date" onChange={this.onChange} value={this.state.issued_date} className="form-control" placeholder="yyyy-mm-dd"/> */}
                              <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.issued_date } selected={this.state.issued_date && new Date(this.state.issued_date)} name="issued_date" onChange={(date) => {
                                                        this.handleDateChange('issued_date',date); 
                                                    }}  
                                              showYearDropdown
                                              scrollableMonthYearDropdown />
                              <p className="error">{this.state.validationErrors.country_issuedError}</p>
                            </div>
                          </div>
                          <div className="form-group row">
                              <label for="" className="col-sm-4 col-form-label">Expiry Date</label> 
                            <div className="col-sm-8 customdatesm">
                              {/* <input type="date" id="datepicker3" name="location_issued" onChange={this.onChange} value={this.state.location_issued} className="form-control" placeholder="yyyy-mm-dd"/> */}
                              <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.location_issued } selected={this.state.location_issued && new Date(this.state.location_issued)} name="location_issued"   onChange={(date) => {
                                                        this.handleDateChange('location_issued',date); 
                                                    }} 
                                                    showYearDropdown
                                          scrollableMonthYearDropdown  />
                              <p className="error">{this.state.validationErrors.location_issuedError}</p>
                            </div>
                          </div> 
                          <div className="form-group row">
                              <label for="" className="col-sm-4 col-form-label">Country Issued <sup>*</sup></label> 
                            <div className="col-sm-8">
                              <select className="form-control" id="exampleFormControlSelect1" name="country_issued" onChange={this.onChange} value={this.state.country_issued}>
                                  <option>Select</option>
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua &amp; Barbuda">
                                    Antigua &amp; Barbuda
                                  </option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia &amp; Herzegovina">
                                    Bosnia &amp; Herzegovina
                                  </option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">
                                    British Indian Ocean Ter
                                  </option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">
                                    Central African Republic
                                  </option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">
                                    Dominican Republic
                                  </option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">
                                    French Southern Ter
                                  </option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">
                                    Netherland Antilles
                                  </option>
                                  <option value="Netherlands">
                                    Netherlands (Holland, Europe)
                                  </option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">
                                    Republic of Montenegro
                                  </option>
                                  <option value="Republic of Serbia">
                                    Republic of Serbia
                                  </option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre &amp; Miquelon">
                                    St Pierre &amp; Miquelon
                                  </option>
                                  <option value="St Vincent &amp; Grenadines">
                                    St Vincent &amp; Grenadines
                                  </option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome &amp; Principe">
                                    Sao Tome &amp; Principe
                                  </option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad &amp; Tobago">
                                    Trinidad &amp; Tobago
                                  </option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks &amp; Caicos Is">
                                    Turks &amp; Caicos Is
                                  </option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">
                                    United Arab Emirates
                                  </option>
                                  <option value="United States of America">
                                    United States of America
                                  </option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">
                                    Vatican City State
                                  </option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">
                                    Virgin Islands (Brit)
                                  </option>
                                  <option value="Virgin Islands (USA)">
                                    Virgin Islands (USA)
                                  </option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis &amp; Futana Is">
                                    Wallis &amp; Futana Is
                                  </option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                                <p className="error">{this.state.validationErrors.country_issuedError}</p> 
                              </div> 
                          </div> 
                          <div className="form-group row mt-0">
                              <label
                              for="inputEmail3"
                              className="col-sm-4 col-form-label"
                              >
                              Document 1 
                              </label>
                              <div className="col-sm-4">
                                <input type="file" className=""  onChange={(event) => this.handleDocument1Change(event)}/>
                              </div>
                              <div className="col-sm-4">
                                    {this.state.uploaded_document_1 &&
                                      <a href="javascript:void(0)" onClick={()=> window.open(this.state.uploaded_document_1, "_blank")} className="btn btn-success" traget="_BLANK">Document 1</a>
                                    } 
                               </div>
                          </div>
                          <div className="form-group row mt-0">
                              <label
                              for="inputEmail3"
                              className="col-sm-4 col-form-label"
                              >
                              Document 2 
                              </label>
                              <div className="col-sm-4">
                                <input type="file" className=""  onChange={(event) => this.handleDocument2Change(event)}/>
                              </div>
                              <div className="col-sm-4">
                                    {this.state.uploaded_document_2 &&
                                      <a href="javascript:void(0)" onClick={()=> window.open(this.state.uploaded_document_2, "_blank")} className="btn btn-success" traget="_BLANK">Document 2</a>
                                    }
                              </div>
                          </div>
                          <div className="form-group row mt-0">
                              <label
                              for="inputEmail3"
                              className="col-sm-4 col-form-label"
                              >
                              Document 3 
                              </label>
                              <div className="col-sm-4">
                                <input type="file" className=""  onChange={(event) => this.handleDocument3Change(event)}/>
                              </div>
                              <div className="col-sm-4"> 
                                  {this.state.uploaded_document_3 &&
                                    <a href="javascript:void(0)" onClick={()=> window.open(this.state.uploaded_document_3, "_blank")} className="btn btn-success" traget="_BLANK">Document 3</a>
                                  } 
                              </div>
                          </div>
                                    
                                   
                                  
                          <div className="form-group row text-center mt-2 ml-auto">
                              <div className="col-sm-12">
                              <div className="m-auto buttonwrp">
                                    { (this.state.role === 'Super_Admin' || this.state.role === 'Team_Member' )  &&
                                            <Link
                                                className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/client/client-identification/${this.props.match.params.clientId}`, 
                                                clientId:this.props.clientId,
                                                    state: {
                                                        clientId: this.props.clientId 
                                                        
                                                    }
                                                }}
                                            > Back</Link>
                                    }
                                    { this.state.role === 'Client' &&
                                            <Link
                                                className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/client-portla/personal-identifications`,  
                                                }}
                                            > Back</Link>
                                    }  
                                      <button type="submit" className="btn btn-primary">Save</button>
                                  </div>
                              </div>
                          </div>
                        </form>
                      </div> 
                    </div> 
                  </div>
                </div>
                </div> 
                { this.state.role !== 'Client' &&
                  <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_identifications"/>    
                }  
              </div>
            </div>
        </div>
        );
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png" 
export default class SupportTicket extends Component {


    state = {

        mobileShow: false,
        buttonData: false,
        Details: '',
        documentId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        pagelaoder:true



    }
    async componentDidMount() { 
        this.OnSearch();
    }
    async OnSearch()
    {
       
        this.setState({ pagelaoder:true});
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        var params ={
            
        }
       
          //get listing 
        await axios.get(`${window.$URL}support-ticket/list`,{
            params: {
                
            },
            headers: { "Authorization": `Bearer ${accessToken}` }
        })
          .then(response => {
              
              if (response.data.status) {

                  let totalCount = response.data.data.length;
                  let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                  let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                  this.setState({
                      Details: response.data.data,
                      filterDetails: response.data.data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Support tickets',
                      pagelaoder:false, 
                  })

                  if(totalCount==0){
                    toast.success("No result found", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                  }
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }
 
    render() {
        const {
            showSweetAlert,
            modalDialog,
            filterDetails,
            activePage, totalCount, pageLimit, paginationCountText,

        } = this.state;
        return (
            <div className="wrapper">
                  <ToastContainer />  
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 p-0">
                                <h3 className="heading">Contact Support Requests</h3> 
                                <div className="content-wrap">
                                <div className="tablebox p-0 w-100">
                                    <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                    <table className="table table-bordered table-responsive-sm">
                                        <thead>
                                            <tr> 
                                            <th scope="col">Ticket No</th>
                                            <th scope="col">Ticket Category</th>
                                            <th scope="col">Subject</th> 
                                            <th scope="col">Client Name</th> 
                                            <th scope="col">Status</th>
                                            <th scope="col">Date</th>
                                            <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            {
                                                filterDetails.length > 0 ?
                                                    filterDetails.map((data, index) => {

                                                        return (
                                                            <tr> 
                                                                <td>{data.ticket_no}</td>
                                                                <td>{data.category}</td>
                                                                <td>{data.subject}</td>
                                                                <td>{data.client_name}</td> 
                                                                <td>{data.ticket_status_text}</td>  
                                                                <td>{data.date}</td> 
                                                                <td className="text-center">
                                                                    <Link to={{ 
                                                                        pathname: `/support-ticket/replay`, 
                                                                        state: {
                                                                                ticket_id: data.ticket_id,
                                                                                ticket_no: data.ticket_no
                                                                            }
                                                                    }}>
                                                                    <button><i className="fa fa-comments" /></button></Link> 
                                                                </td> 
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={5}>No result found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                            </div>
                                            <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                <Pagination
                                                    prevPageText='Prev'
                                                    nextPageText='Next'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                    activePage={activePage}
                                                    itemsCountPerPage={pageLimit}
                                                    totalItemsCount={totalCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemclassName="page-item"
                                                    linkclassName="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
        );
    }
}

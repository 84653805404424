import React, { Component } from "react";
import axios from "axios";

export default class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      // email: '',

      // emailError: '',

      successMessage: "",
      success: false,
      errorMessage: "",
      error: false,
      token: "",
      passwordError: "",
      confirmPasswordError: "",

      username: "",
      password: "",
      new_password:'',
      confirmPassword: "",
      update: "",
    };
  }

  async componentDidMount() {
    
    await axios
      .get(`${window.$URL}reset-password/${this.props.match.params.token}`)
      .then((response) => {
       
        if (response.data.data.message === "password reset link a-ok") {
          this.setState({
            username: response.data.data.otp,
            error: false,
          });
        } else if (response.data.data.message === "Password reset link is invalid") {
           
          this.setState({
            errorMessage: response.data.data.message,
            error: true,
          });
        } else {
          this.setState({
            errorMessage: response.data,
            error: true,
          });
        }
      })
      .catch((error) => {
        
       // console.log(error.data,'error');
      });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  validate = () => {
    this.setState({
      passwordError: "",
      confirmPasswordError: "",
    });

    let passwordError = "";
    let confirmPasswordError = "";

    if (this.state.password.length < 1) {
      passwordError = "Please enter Password";
    } else if (this.state.password.length < 6) {
      passwordError = "Password must be 6 characters long";
    }

    if (passwordError) {
      this.setState({ passwordError });
      return false;
    }

    if (this.state.confirmPassword.length < 1) {
      confirmPasswordError = "Please enter  Confirm Password";
    } else if (this.state.confirmPassword !== this.state.password) {
      confirmPasswordError = "Must be same as password.";
    }
    if (confirmPasswordError) {
      this.setState({ confirmPasswordError });
      return false;
    }

    this.setState({
      passwordError,
      confirmPasswordError,
    });

    return true;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
    });
    let checkValidation = this.validate(); 
    if (checkValidation) { 
      axios
        .post(`${window.$URL}update-password`, {
          otp: this.state.username,
          new_password: this.state.password,
        })
        .then((response) => { 
          if (response.data.success === "password updated") {
           
            this.setState({
              success: true,
              successMessage: response.data.success,
            });
            this.props.history.push('/login');
          } else {
            this.setState({
              error: true,
            });
          }
        })
        .catch((error) => { 
        });
    }
  };

  render() {
    const {
      passwordError,
      confirmPasswordError,
      success,
      error,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <div className="main-container">
        <div className="loginarea">
          <div className="logo">
            <img
              src={require("../../assets/images/frux-logo.svg")}
              alt="logo"
              className="img-fluid"
            />
            {success ? (
              <p style={{ color: "green", fontSize: 18 }}> {successMessage} </p>
            ) : null}
            {error ? (
              <p style={{ color: "red", fontSize: 18 }}> {errorMessage} </p>
            ) : null}
          </div>
          <form onSubmit={this.onSubmit}>
            {/* <h4 className="mt-3">Old Password</h4> */}

              <h4>New Password</h4>
            <div className="logininner d-flex justify-content-center row mt-0">
              <div className="form-group input-group mtnew">
                <label className="has-float-label m-0" for="lgnForm_passNew">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>

                <input
                  type="password"
                  placeholder="Enter New Password"
                  className="form-control"
                  name="password"
                  onChange={this.onChange}
                />
                {passwordError ? (
                  <p className="error-login" style={{ color: "red" }}> {passwordError} </p>
                ) : null}
              </div>
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_passretype">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>

                <input
                  type="password"
                  placeholder="Retype Password"
                  className="form-control"
                  name="confirmPassword"
                  onChange={this.onChange}
                />
                {confirmPasswordError ? (
                  <p  className="error-login" style={{ color: "red" }}> {confirmPasswordError} </p>
                ) : null}
              </div>
              <input
                type="submit"
                name="lgnForm:LoginButton"
                value="Submit"
                id="lgnForm_LoginButton"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import TopMenu from "./TopMenu" 
import RightMenu from "./RightMenu"
export default class clientRelation extends Component {


    state = {
      loginUserName : '',
        document_type: '',
        drivers_licence_number: '',
        issued_date: '',
        country_issued: '',
        location_issued: '',

        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails1: [],
        filterDetails2: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token:'',

        validationErrors:{
          document_typeError: '',
          drivers_licence_numberError: '',
          country_issuedError: '',
          issued_dateError:'',
      },
      showLicenseNumber:false,
      pagelaoder:true




    }
    async componentDidMount() {
        
        
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        //
        let accessToken = checkToken.access_token;
        this.setState({
            loginUserName: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })
        

            //get listing
            await axios.get(`${window.$URL}client/client-relations/${this.props.match.params.clientId}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
                .then(response => {
                     
                    if (response.data.status) {

                        let totalCount = response.data.data.length;
                        let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                        let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                        this.setState({
                            Details: response.data.data,
                            filterDetails1: response.data.data.clientRelation.slice(0, this.state.pageLimit),
                            filterDetails2: response.data.data.clientRelated.slice(0, this.state.pageLimit),
                            pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                            totalCount,
                            showingFrom,
                            showingTo,
                            paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Relations',
                            pagelaoder:false
                        });
                        if(totalCount==0){
                          toast.error("No result found", {
                              position: "bottom-center",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                          });
                        }
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            //end listing
        }
        if(this.props.match.params.hasOwnProperty('clientId'))
        {
            
        }
    }
   

    render() {
      const {
        showSweetAlert,
        modalDialog,
        filterDetails1,
        filterDetails2,
        activePage, totalCount, pageLimit, paginationCountText,

      } = this.state;
        return ( 
            <div className="wrapper">
              <ToastContainer/> 
            <div className="container newContainer mainArea pt-0" id="formpage">
              <div className="row justify-content-center"> 
                <div className="lefeArea col-md-9 p-0"> 
                  <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_relations"/> 
                  <div className="content-wrap"> 
                    <div className="text-right mb-2"> 
                        <Link
                            className="btn btn-primary bluebg"
                            to={{
                            pathname: `/client`,  
                            }}
                        >Back to Clients</Link>
                        <Link
                            className="btn btn-primary bluebg"
                            to={{
                            pathname: `/client/add-new-relation/${this.props.match.params.clientId}`, 
                            clientId:this.props.clientId,
                                state: {
                                    clientId: this.props.clientId 
                                    
                                }
                            }}
                        > Add New Relation</Link> 
                    </div>
                    <h4 className="smallheading mb-0">
                      Clients Connected to <strong>{localStorage.getItem("selectdClientName")}</strong>
                    </h4> 
                    <div className="tablebox p-0 w-100">
                      <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                      <table className="table table-bordered table-responsive-sm">
                        <thead>
                          <tr>
                            <th scope="col">View</th>
                            <th scope="col">Id</th>
                            <th scope="col">Related Client Number</th>
                            <th scope="col">Related Client Name</th>
                            <th scope="col">Kind of Relation</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          filterDetails1.length > 0 ?
                              filterDetails1.map((data, index) => {

                                  return (
                                      <tr>
                                          <td className="text-center">
                                          <Link  to={{
                                                pathname: `/client/client-relation-details/${this.props.match.params.clientId}/${data.r_id}`, 
                                                state: {
                                                  clientRelationId: data.r_id,                                                
                                                  clientId: this.props.match.params.clientId,                                                
                                                  }
                                            }}>
                                            <button><i className="fa fa-search" /></button></Link>
                                          </td>
                                          <td>{data.r_id}</td>
                                          <td>{data.client_number}</td>
                                          <td>{data.client_name}</td>
                                          <td>{data.relation}</td>
                                          <td className={data.status == '1' ? "bggreen" : "bgred"}>{data.status_text}</td>

                                      </tr>
                                  )
                              })
                              :
                              <tr>
                                  <td colSpan={6}>No result found</td>
                              </tr>
                      }
                          
                        </tbody>
                      </table>
                    </div>
                    <h4 className="smallheading mt-0 mb-0"> Clients being related by <strong>{localStorage.getItem("selectdClientName")}</strong> </h4> 
                    <div className="tablebox p-0 w-100">
                      <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                      <table className="table table-bordered table-responsive-sm">
                        <thead>
                          <tr>
                            <th scope="col">View</th>
                            <th scope="col">Id</th>
                            <th scope="col">Related Client Number</th>
                            <th scope="col">Related Client Name</th>
                            <th scope="col">Kind of Relation</th>
                            <th scope="col">Online Access</th>
                            <th scope="col">Username of Online Access</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          filterDetails2.length > 0 ?
                              filterDetails2.map((data, index) => { 
                                    return (
                                      <tr> 
                                          <td className="text-center">
                                          <Link  to={{
                                                pathname: `/client/view-client/${this.props.match.params.clientId}`, 
                                                state: {
                                                    clientId: this.props.match.params.clientId                                                
                                                  }
                                            }}>
                                            <button><i className="fa fa-search" /></button></Link>
                                          </td>
                                          <td>{data.r_id}</td>
                                          <td>{data.client_number}</td>
                                          <td>{data.client_name}</td>
                                          <td>{data.relation}</td>
                                          <td>{data.onlineAccess}</td>
                                          <td>{data.username}</td>
                                          <td className={data.status == '1' ? "bggreen" : "bgred"}>{data.status_text}</td>

                                      </tr>
                                    )
                                  })
                                :
                                <tr>
                                    <td colSpan={8}>No result found</td>
                                </tr>
                              } 
                        </tbody>
                      </table>
                    </div>
                  </div> 
                </div> 
                <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_relations"/>    
              </div>
            </div>
          </div> 
        );
    }
}

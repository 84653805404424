import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"; 
import { ToastContainer, toast } from 'react-toastify'; 
import db from '../../firebase.config';
//import { db } from "../../services/firebase"
import 'react-toastify/dist/ReactToastify.css'; 
import "../../assets/css/support.css" ;
 
export default class Replay extends Component {

    constructor(props) {
        super(props); 
        this.state = { 
            name:'',
            role:'',
            token:'',
            ticket_id:'',
            ticket_no:'',
            ticket_client:'',
            ticket_status:'',
            ticket_status_text:'',
            image:'',
            category:'',
            clientName:'',
            date:'',
            subject:'',
            replays:[],
            message:'',
            chats:  [],
            content: '',
            readError: null,
            writeError: null,
            statusLoader:false
    
        }
        // ...
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
    async componentDidMount() { 
        this.setState({ readError: null });
         
        
        try {  
            let ticketSlug = 'tickets/'+this.props.location.state.ticket_no+'/messages'; 
             
            db.ref(ticketSlug).on("value", snapshot => {
              let chats = []; 
              snapshot.forEach((snap) => { 
                //snap.forEach((data) => {  
                    chats.push(snap.val());
                //});
              });
              this.setState({ chats }); 
            }); 
          } catch (error) {
            this.setState({ readError: error.message });
          }
          
        let checkTokenData = localStorage.getItem("userToken");
        let checkToken = JSON.parse(checkTokenData);
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:checkToken.access_token,
            
        }); 
        this.getTicketDetails(); 
    }
    async getTicketDetails()
    {
       
        this.setState({ pagelaoder:true});
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        var params ={
            
        }
       
          //get listing 
        await axios.get(`${window.$URL}support-ticket/replay/list`,{
            params: {
                support_id:this.props.location.state.ticket_id
            },
            headers: { "Authorization": `Bearer ${accessToken}` }
        })
          .then(response => {
              
              if (response.data.status) {

                this.setState({
                    ticket_id:response.data.data.ticket_id,
                    ticket_no:response.data.data.ticket_no,
                    ticket_client:response.data.data.ticket_client,
                    subject:response.data.data.subject,
                    ticket_status:response.data.data.ticket_status,
                    ticket_status_text:response.data.data.ticket_status_text,
                    date:response.data.data.date,
                    image:response.data.data.image,
                    category:response.data.data.category,
                    clientName:response.data.data.client_name,
                });
                   
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value })
    } 
    validate = () => { 
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;  
        let emailError = '';  
        const { validationErrors } = this.state;
        let formvalidateStatus = true;
        if (this.state.content.length < 1) {  
            toast.error("Please Enter Message", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            formvalidateStatus = false;
        } 
        return formvalidateStatus 
    }
    onSubmit = (e) => {
        e.preventDefault();
        let params = {
            message: this.state.message,
            support_id:this.props.location.state.ticket_id
        }
        const isValid = this.validate();
        
        if (isValid) {
            axios.post(`${window.$URL}support-ticket/replay/add`, params, { headers: { "Authorization": `Bearer ${this.state.token}` } })
            .then(response => {
                ;
                this.getTicketDetails();
                this.setState({ message:''  });
            })
            .catch(error => {
                console.error(error.data)
            })
        }
    };
    onTicketStatus = (e) => {
        let status = 1;
        let statusText = 'Accepted';
        if(this.state.ticket_status == 1 ){
            status = 2;
            statusText = 'Closed';
        }
        if(this.state.ticket_status == 2 ){
            status = 3;
            statusText = 'Reopen';
        }
        let params = {
            'support_id':this.props.location.state.ticket_id,
            'status':status
        }
        this.setState({statusLoader:true});
        axios.post(`${window.$URL}support-ticket/change/status`, params, { headers: { "Authorization": `Bearer ${this.state.token}` } })
        .then(response => {
            ;
          
            this.setState({ message:''  });
            let updates = {};
            updates['tickets/'+this.props.location.state.ticket_no+'/status'] = statusText;  
            db.ref().update(updates);
            if(status === 2){
                this.props.history.push(`/support-tickets`);
            }else{
                this.getTicketDetails();
            }
            this.setState({statusLoader:false});
            
        })
        .catch(error => {
            console.error(error.data);
            this.setState({statusLoader:false});  
        })
        
    };
    handleChange(event) {
        this.setState({
          content: event.target.value
        });
      }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ writeError: null }); 
        const isValid = this.validate();
        
        if (isValid) {
            try {
                let ticketSlug = 'tickets/'+this.state.ticket_no+'/messages';
            
            let messageBy ='admin';
            
            if(this.state.role === 'Client'){
                messageBy =  'agent'; 
            }
            await db.ref(ticketSlug).push({
                message: this.state.content,
                timestamp: Date.now(), 
                sender_name:this.state.name,
                messageBy:messageBy,
            });
            this.setState({ content: '' });
            this.setState({ writeError:"save" });
            } catch (error) {
            this.setState({ writeError: error.message });
            }
        }
    }

     
    render() { 
        // let replays = this.state.replays.map((item, index) =>{  
        //     var chatBoxWrap = item.replay_by != 2 ? 'admin' : 'agent';
        //     var chatImage = item.replay_by != 2 ? 'right' : 'left';
        //     if(this.state.role === 'Client'){
        //         chatBoxWrap = item.replay_by != 2 ? 'agent' : 'admin';
        //         chatImage = item.replay_by != 2 ? 'left' : 'right';
        //     }
        //     return (
        //         <li key={item.id} className={`clearfix ${ chatBoxWrap }`}>
        //             <span className={`"chat-img clearfix mx-2 ${ chatImage }`}>
        //             <i className="fa fa-user-circle"></i>
        //             </span>
        //             <div className="chat-body clearfix">
        //                 <div className="header clearfix">
        //                     <strong className="primary-font">{item.name}</strong>  
        //                 </div>
        //                 <p>
        //                    {item.message}
        //                 </p>
        //             </div>
        //         </li>             
        //     ) 
        // }, this);    
        let replays = this.state.chats.map((item, index) =>{  
            var chatBoxWrap = item.messageBy;  
            if(this.state.role === 'Client'){
                chatBoxWrap =item.messageBy !== 'agent' ? 'agent' : 'admin'; 
            }
            return (
                <li key={item.id} className={`clearfix ${ chatBoxWrap }`}> 
                    <div className="chat-body clearfix">
                        <div className="header clearfix">
                            <strong className="primary-font">{item.sender_name}</strong>  
                        </div>
                        <p>
                           {item.message}
                        </p>
                    </div>
                </li>             
            ) 
        }, this);
       
        return (
            <div className="wrapper">
                  <ToastContainer /> 
                 
                <div className="container newContainer mainArea pt-0">
                    <div className="row">   
                    <div className="col-md-6 mx-auto mt-5 mb-5">
                        <div className="text-right mb-3">
                            <Link
                                className="btn btn-primary bluebg"
                                to={{
                                pathname: `/support-tickets`,  
                                }}
                            >Back</Link>
                                
                        </div> 
                        <p>{this.state.readError}</p>
                        <div className="tablebox">
                            <div className="d-flex">
                                <div className="pr-5">
                                    <strong>Ticket Number</strong>
                                    <p>{this.state.ticket_no}</p>
                                </div>
                                <div className="pr-5">
                                    <strong>Category</strong>
                                    <p>{this.state.category}</p>
                                </div>
                                <div className="pr-5">
                                    <strong>Subject</strong>
                                    <p>{this.state.subject}</p>
                                </div>
                                <div className="pr-5">
                                    <strong>Client Name</strong>
                                    <p>{this.state.clientName}</p>
                                </div>
                                <div className="pr-5">
                                    <strong>Document</strong>
                                    <div>
                                    {this.state.image ?
                                        <a href="javascript:void(0)" onClick={()=> window.open(this.state.image, "_blank")} className="btn btn-success" traget="_BLANK">Download</a>
                                        :
                                        <span>N/A</span>
                                    }
                                    </div> 
                                </div> 
                                <div className="pr-5">
                                     
                                    <div>
                                    {this.state.ticket_status == 1 &&
                                        <div className="text-center mb-5">
                                            {this.state.role !== "Client" &&
                                                <button type="button" onClick={this.onTicketStatus} className="btn btn-primary"><div className="buttonloader"><div className={`loader_wrap ${ this.state.statusLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                                Close Ticket</button>
                                            }  
                                        </div>
                                    }
                                    {this.state.ticket_status == 2 &&
                                        <div className="text-center mb-5">
                                            {this.state.role == "Client" &&
                                                <button type="button" onClick={this.onTicketStatus} className="btn btn-primary"><div className="buttonloader"><div className={`loader_wrap ${ this.state.statusLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                                Reopen </button>
                                            }  
                                        </div>
                                    }
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="card"> 
                            <div className="card-body chat-care">  
                                <ul className="chat"> 
                                    {replays}
                                </ul>
                            </div>
                            <div className="card-footer">
                                <form onSubmit={this.handleSubmit}> 
                                    {this.state.ticket_status == 1 &&
                                        <div className="input-group">
                                            <input id="btn-input" type="text"  value={this.state.content} onChange={this.onChange} name="content" id="inputEmail3" placeholder="Enter Message" className="form-control input-sm" placeholder="Type your message here..." />
                                            <span className="input-group-btn">
                                                <button type="submit" className="btn btn-primary" id="btn-chat">
                                                    Send</button>
                                            </span>
                                        </div>
                                    }
                                    {(this.state.ticket_status == 0 ||  this.state.ticket_status == 3) &&
                                        <div className="text-center">
                                            {this.state.role !== "Client" &&
                                                <button type="button" onClick={this.onTicketStatus} className="btn btn-primary" id="btn-chat"><div className="buttonloader"><div className={`loader_wrap ${ this.state.statusLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                                Verify And Replay</button>
                                            } 
                                             {this.state.role === "Client" && this.state.ticket_status == 0 &&
                                                <p>Please Wait Your Request Under Review.</p>
                                             }
                                        </div>
                                    }
                                     
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
           </div> 
        );
    }
}

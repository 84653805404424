import firebase from 'firebase'
//import firebase from "firebase/app"
import "firebase/firestore"

if(process.env.NODE_ENV === 'production'){
const config = {
    apiKey: "AIzaSyAYGA8OJsnfx-00l0A83L6kaEU_nm7APqM",
    authDomain: "frux-private-bank-ced38.firebaseapp.com",
    databaseURL: "https://frux-private-bank-ced38-default-rtdb.firebaseio.com",
    projectId: "frux-private-bank-ced38",
    storageBucket: "frux-private-bank-ced38.appspot.com",
    messagingSenderId: "988843164100",
    appId: "1:988843164100:web:2605b57b58adb4a2e82491",
    measurementId: "G-7227PHBQYM"
  };
  firebase.initializeApp(config); 
}else{
   const config = {
    apiKey: "AIzaSyCi701OKmrftNMKDQCJSCzYBk8gmH-5eN0",
    authDomain: "frux-private-bank-development.firebaseapp.com",
    databaseURL: "https://frux-private-bank-development-default-rtdb.firebaseio.com",
    projectId: "frux-private-bank-development",
    storageBucket: "frux-private-bank-development.appspot.com",
    messagingSenderId: "372048474295",
    appId: "1:372048474295:web:89d9423b6d0f184bbedd32"
  }; 
  firebase.initializeApp(config); 
} 
const db = firebase.database(); 
export default db; 
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
export default class Dashboard extends Component { 
  
  
  state = {
    name: '',
    role:'',
    dashboard_count:[]

    
}
  async componentDidMount(){
    let checkTokenData = localStorage.getItem("userToken");
    let checkToken = JSON.parse(checkTokenData);
    //
    this.setState({
      name:checkToken.name,
      role:checkToken.role
    });
    this.getConfig();
  } 
  async getConfig()
  {  
          let checkTokenData = localStorage.getItem("userToken");
          if(checkTokenData){
              let checkToken = JSON.parse(checkTokenData);
              
              let accessToken = checkToken.access_token; 
              //get listing
              await axios.get(`${window.$URL}getConfigs?dashboard_count=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
              .then(response => {
                  
                  if (response.data.status) { 
                      if(response.data.data.dashboard_count){
                          this.setState({dashboard_count:response.data.data.dashboard_count}); 
                      }
                     
                  }
              })
              .catch(error => {
                  console.error(error.data)
              })
               //end listing

          } 
      
  } 
  render() {
    const{ name,role}= this.state;
    return (
      <div> 
        <div className="wrapper minwrap">
          <div className="container-fluid text-center">
            <div className="messegeHeading col-12">
              <h4>
                Welcome Administrator -{name} {" "}
                <span className="d-none"><i className="fa fa-envelope" /> You have ‘19’ unread message(s)</span>
              </h4>
            </div> 

            <div className="container deskboxywrp">
            <div className="deskboxy"> 


            <div className="col-sm-3 ">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{this.state.dashboard_count.total_client}</h3>

                <p>Clients</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag"></i>
              </div>
              <Link className="small-box-footer" to="/client">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>
             {role !== 'Team_Member' && 
              <div className="col-sm-3 ">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{this.state.dashboard_count.total_team}</h3>

                  <p>Teams</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <Link className="small-box-footer" to="/team">More info <i className="fa fa-arrow-circle-right"></i></Link> 
              </div>
              </div>
            }


            
            <div className="col-sm-3 d-none">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{this.state.dashboard_count.active_client}</h3>

                <p>Active Clients</p>
              </div>
              <div className="icon">
                <i className="ion active-client"></i>
              </div>
              <Link className="small-box-footer" to="/client">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>
            <div className="col-sm-3 d-none">
            <div className="small-box bg-danger">
              <div className="inner">
              <h3>{this.state.dashboard_count.deactive_client}</h3> 
                <p>Deactivate Clients</p>
              </div>
              <div className="icon">
                <i className="ion didactive"></i>
              </div>
              <Link className="small-box-footer" to="/client">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>
            
            </div>
            <div className="deskboxy"> 
            <div className="col-sm-3 ">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{this.state.dashboard_count.total_product}</h3>

                <p>Products</p>
              </div>
              <div className="icon">
                <i className="ion ion-products"></i>
              </div>
              <Link className="small-box-footer" to="/product">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>


            <div className="col-sm-3 d-none">
            <div className="small-box bg-primary">
              <div className="inner">
                <h3>{this.state.dashboard_count.active_product}</h3>

                <p>Active Products</p>
              </div>
              <div className="icon">
                <i className="ion activeproducts"></i>
              </div>
              <Link className="small-box-footer" to="/product">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>

            
            <div className="col-sm-3 d-none">
            <div className="small-box bg-dark">
              <div className="inner">
              <h3>{this.state.dashboard_count.deactive_product}</h3>

                <p>Deactivate Products</p>
              </div>
              <div className="icon">
                <i className="ion deactiveproducts"></i>
              </div>
              <Link className="small-box-footer" to="/client">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>

            <div className="col-sm-3 ">
            <div className="small-box bg-orange">
              <div className="inner">
                <h3>{this.state.dashboard_count.resent_transactions}</h3>

                <p>Recent Transactions</p>
              </div>
              <div className="icon">
                <i className="ion resentt"></i>
              </div>
              <Link className="small-box-footer" to="/transactions">More info <i className="fa fa-arrow-circle-right"></i></Link> 
            </div>
            </div>

             

              </div> 
              </div> 
              </div> 
          </div>
        </div> 
    );
  }
}

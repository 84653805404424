import React from 'react'
import { Link } from 'react-router-dom'; 
import PropTypes from "prop-types";  
import '../../assets/css/print.css'; 
//const deviceWidth = window.innerWidth > 650 ? true : false

export default function printLayout({ children }) { 
    return (
        <>  
           {children}
        </>
    )
}

printLayout.propTypes = {
    children: PropTypes.element.isRequired
};


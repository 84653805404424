import React, { Component } from "react";
import axios from "axios";

export default class ResetPassword extends Component {
  //console.log(this.props.match.params.token,'token');

  constructor() {
    super();
    this.state = {
      // email: '',

      // emailError: '',
      successMessage: "",
      success: false,
      errorMessage: "",
      error: false,
      passwordError: "",
      confirmPasswordError: "",
      oldPassword: "",
      oldPasswordError: "",
      oldPasswordInvalid: "",
      Isloggedin: false,
      password: "",
      confirmPassword: "",
      update: "",
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  validate = () => {
    this.setState({
      passwordError: "",
      confirmPasswordError: "",

      oldPasswordError: "",
    });

    let passwordError = "";
    let confirmPasswordError = "";

    let oldPasswordError = "";

    if (this.state.password.length < 1) {
      passwordError = "Please enter Password";
    } else if (this.state.password.length < 6) {
      passwordError = "Password must be 6 characters long";
    }

    if (passwordError) {
      this.setState({ passwordError });
      return false;
    }

    if (this.state.oldPassword.length < 1) {
      oldPasswordError = "Please Enter Old Password";
    }
    if (oldPasswordError) {
      this.setState({ oldPasswordError });
      return false;
    }

    if (this.state.confirmPassword.length < 1) {
      confirmPasswordError = "Please enter  Confirm Password";
    } else if (this.state.confirmPassword !== this.state.password) {
      confirmPasswordError = "Confirm Password must be same as password.";
    }
    if (confirmPasswordError) {
      this.setState({ confirmPasswordError });
      return false;
    }

    this.setState({
      passwordError,
      confirmPasswordError,
      oldPasswordError,
    });

    return true;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
      oldPasswordError: "",
      oldPasswordInvalid: "",
    });

    let checkValidation = this.validate();
    if (checkValidation) {
      //alert(this.state.confirmPassword);return false;
      axios
        .post(`${window.$URL}update-reset-password`, {
          email: this.props.match.params.email,
          password: this.state.oldPassword,
          new_password: this.state.password,
        })
        .then((response) => { 
          if (response.data.message === "Password Updated") { 
            let loginData = {
              email: this.props.match.params.email,
              password: this.state.password,
            };
            axios
              .post(`${window.$URL}login`, loginData)
              .then((response) => { 
                if (response.data.status === true) {
                  var userTokenData = {
                    name: response.data.data.name,
                    role: response.data.data.role,
                    access_token: response.data.data.access_token,
                  };
                  localStorage.setItem(
                    "userToken",
                    JSON.stringify(userTokenData)
                  );
                  this.props.history.push(`/dashboard`);
                  ///console.log(response.data.status);
                } else {
                  this.setState({
                    Isloggedin: false,
                    errror: true,
                    errorMessage: response.data.error,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  errror: true,
                  errorMessage: error.data,
                });
              });

            //login end
          } else {
            this.setState({
              //errorMessage:response.data.message,
              error: true,
              oldPasswordInvalid: response.data.message,
            });
          }
        })
        .catch((error) => { 
        });
    }
  };

  render() {
    const {
      passwordError,
      oldPasswordInvalid,
      oldPasswordError,
      confirmPasswordError,
      success,
      error,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <div className="main-container">
        <div className="loginarea">
          <div className="logo">
            <img
              src={require("../../assets/images/frux-logo.svg")}
              alt="logo"
              className="img-fluid"
            />
            {success ? (
              <p style={{ color: "green", fontSize: 18 }}> {successMessage} </p>
            ) : null}
            {error ? (
              <p style={{ color: "red", fontSize: 18 }}> {errorMessage} </p>
            ) : null}
          </div>
          <form onSubmit={this.onSubmit}>
            <h4 className="mt-3">Old Password</h4>

            <div className="logininner d-flex justify-content-center row">
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_UserName">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>
                <input
                  className="form-control"
                  id="lgnForm_UserName"
                  type="password"
                  name="oldPassword"
                  placeholder="Enter Old Password"
                  onChange={this.onChange}
                />
                {oldPasswordError ? (
                  <span style={{ color: "red" }}> {oldPasswordError} </span>
                ) : null}
                {oldPasswordInvalid ? (
                  <span style={{ color: "red" }}> {oldPasswordInvalid} </span>
                ) : null}
              </div>
              <h4>New Password</h4>
              <div className="form-group input-group mtnew">
                <label className="has-float-label m-0" for="lgnForm_passNew">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>

                <input
                  type="password"
                  placeholder="Enter New Password"
                  className="form-control"
                  name="password"
                  onChange={this.onChange}
                />
                {passwordError ? (
                  <span style={{ color: "red" }}> {passwordError} </span>
                ) : null}
              </div>
              <div className="form-group input-group">
                <label className="has-float-label m-0" for="lgnForm_passretype">
                  <i className="fa fa-lock input-group-addon"></i>
                </label>

                <input
                  type="password"
                  placeholder="Retype Password"
                  className="form-control"
                  name="confirmPassword"
                  onChange={this.onChange}
                />
                {confirmPasswordError ? (
                  <span style={{ color: "red" }}> {confirmPasswordError} </span>
                ) : null}
              </div>
              <input
                type="submit"
                name="lgnForm:LoginButton"
                value="Submit"
                id="lgnForm_LoginButton"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

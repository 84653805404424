import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import TopMenu from "./TopMenu"
import RightMenu from "./RightMenu"
export default class clientOnlineAccess extends Component { 
    state = { 
        name: '',
        role: '',
        token: '',
        pagelaoder:true,
        onlineAccess:[],
        btnLaoder:false,
    }
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            })


            //get listing
            await axios.get(`${window.$URL}client/online-access-details`,{
                params: { 
                    cm_id:this.props.match.params.clientId, 
                },
                headers: { "Authorization": `Bearer ${accessToken}` }
            })
            .then(response => { 
                    if (response.data.status) { 
                        this.setState({
                            onlineAccess:response.data.data.userDetail,
                            pagelaoder:false
                        }); 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            //end listing
        }
        
    } 
    
    sendAccess = (e) => {
            //alert('gii');return false;
            e.preventDefault();
            this.setState({btnLaoder:true});
        var parmas = {
            cm_id:this.props.match.params.clientId, 
        }
       
        axios.post(`${window.$URL}client/online-access-details/send`, parmas, { headers: { "Authorization": `Bearer ${this.state.token}` } })
        .then(response => {
            
            if (response.data.status) {
                toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    successMessage: response.data.message,
                    success: true,
                    pageName: "",
                    buttonData: false,
                    UpdateData: false
                })
                this.setState({btnLaoder:false}); 
                
            } else {
                toast.error('Something Went Wrong', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    error: true,
                    errormessage: 'Something Went Wrong'
                });
                this.setState({btnLaoder:false}); 
            }
        })
        .catch(error => {
            console.error(error.data);
            this.setState({btnLaoder:false}); 
        })
         
    };
    render() {
        const { 
            filterDetails1,
            onlineAccess,

        } = this.state;
       
        return (

            <div className="wrapper">
                <ToastContainer /> 
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center"> 
                        <div className="lefeArea col-md-9 p-0">
                            <h3 className="heading">Clients Connected to</h3>
                            <TopMenu clientId={this.props.match.params.clientId} activeMenu="client_online_access" /> 
                            <div className="content-wrap">  
                                        <div className="text-right mb-3">
                                            <Link
                                                className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/client`,  
                                                }}
                                            >Back to Clients</Link> 
                                        </div>  
                                <div className="tablebox p-0 w-100">
                                <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr> 
                                                <th scope="col">Client No</th>
                                                <th scope="col">Client Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Password</th>
                                                <th scope="col">Online Access</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                onlineAccess.length > 0 ?
                                                onlineAccess.map((data, index) => { 
                                                        return (
                                                            <tr> 
                                                                <td>{data.client_number}</td>
                                                                <td>{data.client_name}</td>
                                                                <td>{data.email}</td>
                                                                <td>{data.online_access}</td>
                                                                <td>
                                                                <button type="button" onClick={this.sendAccess} className="btn btn-primary"><div className="buttonloader"><div className={`loader_wrap ${ this.state.btnLaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Send</button>
                                                                </td>
                                                            </tr> 
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No result found</td>
                                                    </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                             </div> 
                        </div> 
                        <RightMenu clientId={this.props.match.params.clientId} activeMenu="client_online_access" /> 
                    </div>
                </div>
            </div> 
        );
    }
}

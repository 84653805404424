import React from 'react'
import PropTypes from "prop-types"; 
import '../../assets/css/header_admin.css';
import '../../assets/css/sidebarmenu.css';
import '../../assets/css/style.css'; 
import '../../assets/css/responsive.css'; 

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

//const deviceWidth = window.innerWidth > 650 ? true : false

export default function AuthLayout({ children }) { 
    return (
        <>
            
                <div className="wrapper-main">
                    <Header/> 
                    <Sidebar/> 
                    {children}
                    <Footer />
                </div> 
        </>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};


import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="loginfooter">
        <div className="container ">
          <div className="row">
            <div className="col-7">
              <p>
                { process.env.REACT_APP_FOOTER_COPTYRIGHT}
                <br />
                Powered By &nbsp;
                <a href="https://www.logicsofts.com.au/" target="_blank">
                logicsofts
                </a>
                <br />
                Version - 1
              </p>
            </div>
            <div className="col-5 text-right">
              <img src={require("../../../assets/images/SSL.png")} alt="SSL" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

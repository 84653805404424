import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import ScrollToTop from "./ScrollToTop";

import Login from '../pages/login';
import ForgotPassword from '../pages/Forgotpassword';
import Dashboard from '../pages/dashboard';

import Reset from '../pages/Resetpassword';

import Register from '../pages/Register';
import Logout from '../pages/Logout';
import Team from '../pages/Team';
import Profile from '../pages/Profile';
import editProfile from '../pages/Profile/editProfile';

import CustomResetpassword from '../pages/CustomResetpassword';
import ResetpasswordForm from '../pages/ResetpasswordForm';
import Client from '../pages/Client';
import AddClient from '../pages/Client/add_client'; 
import updateClient from '../pages/Client/updateClient'; 
import viewClient from '../pages/Client/viewClient'; 
import clientAddress from '../pages/Client/clientAddress'; 
import clientAddressDetails from '../pages/Client/clientAddressDetails';
import addClientAddress from '../pages/Client/addClientAddress';
import clientIdentification from '../pages/Client/clientIdentification';
import addClientIdentification from '../pages/Client/addClientIdentification';
import clientIdentificationDetails from '../pages/Client/clientIdentificationDetails';
import addClientsAccounts from '../pages/Client/addClientsAccounts';
import editClientsAccounts from '../pages/Client/editClientsAccounts';

import viewClientsAccount from '../pages/Client/viewClientsAccount';

import addClientDocuments from '../pages/Client/addClientDocuments';
import editClientDocument from '../pages/Client/editClientDocument';
import clientPrintPdf from '../pages/Client/clientPrintPdf';
import clientDocuments from '../pages/Client/clientDocuments';
import clientAccounts from '../pages/Client/clientAccounts'; 
import clientTransactions from '../pages/Client/clientTransactions';  
import Transaction from '../pages/Transaction'; 
import editClientIdentification from '../pages/Client/editClientIdentification';
import editClientAddress from '../pages/Client/editClientAddress';
import clientRelation from '../pages/Client/clientRelation';
import addNewRelation from '../pages/Client/addNewRelation';
import clientOnlineAccess from '../pages/Client/clientOnlineAccess';
import clientOnlineAccessDetails from '../pages/Client/clientOnlineAccessDetails';
import editclientOnlineAccess from '../pages/Client/editclientOnlineAccess';
import editclientRelation from '../pages/Client/editclientRelation';
import clientRelationDetails from '../pages/Client/clientRelationDetails';
import AdminAccounts from '../pages/AdminAccounts';
import AdminAccountsTransactions from '../pages/AdminAccounts/TransactionHistory';
import BluckTransactions from '../pages/AdminAccounts/BluckTransactions';
import BluckTransactionHistory from '../pages/AdminAccounts/BluckTransactionHistory';
import SupportTicket from '../pages/SupportTicket';
import SupportTicketReplay from '../pages/SupportTicket/Replay';
import ClientPortalDashboard from '../pages/ClientPortal/dashboard';
import PersonalInformation from '../pages/ClientPortal/PersonalInformation';
import PersonalAddress from '../pages/ClientPortal/PersonalAddress';
import PersonalIdentifications from '../pages/ClientPortal/PersonalIdentifications';
import PersonalDocument from '../pages/ClientPortal/PersonalDocument';
import PersonalCustomerToCustomerRelations from '../pages/ClientPortal/PersonalCustomerToCustomerRelations';
import PersonalAccounts from '../pages/ClientPortal/PersonalAccounts';
import OpenNewAccount from '../pages/ClientPortal/OpenNewAccount';
import TranfersterToConnectedAccounts from '../pages/ClientPortal/TranfersterToConnectedAccounts';
import Statements from '../pages/ClientPortal/Statements';
import ContactSupport from '../pages/ClientPortal/ContactSupport';
import ProcessPasswordChange from '../pages/ClientPortal/ProcessPasswordChange';
import MyMessages from '../pages/ClientPortal/ProcessPasswordChange';
import MyTransactions from '../pages/ClientPortal/MyTransactions';

import Product from '../pages/Product';
import ProductAdd from '../pages/Product/AddProduct';
import ProductEdit from '../pages/Product/EditProduct';

import CurrencyWallet from '../pages/CurrencyWallet';
import Report from '../pages/Report';

let resetURL = window.location.pathname.split("/")[1];

export default function Routes() {
    return (
        <ScrollToTop>
        <Switch>
            <Route path='/profile' exact component={Profile} isPrivate/>  
            <Route path='/editProfile' exact component={editProfile} isPrivate/> 
            <Route path='/admin/accounts' exact component={AdminAccounts} isPrivate/>  
            <Route path='/admin/accounts/transactions' exact component={AdminAccountsTransactions} isPrivate/> 
            <Route path='/admin/bulk/transactions' exact component={BluckTransactions} isPrivate/> 
            <Route path='/admin/bulk/transaction-history' exact component={BluckTransactionHistory} isPrivate/>
            <Route path='/transactions' exact component={Transaction} isPrivate isGrantAccess/> 
            <Route path='/support-tickets' exact component={SupportTicket} isPrivate isGrantAccess/> 
            <Route path='/support-ticket/replay' exact component={SupportTicketReplay} isPrivate isGrantAccess/>

            <Route path='/product' exact component={Product} isPrivate isGrantAccess/>
            <Route path='/product/add' exact component={ProductAdd} isPrivate isGrantAccess/>
            <Route path='/product/edit' exact component={ProductEdit} isPrivate isGrantAccess/> 
            
            <Route path='/currency-wallet' exact component={CurrencyWallet} isPrivate isGrantAccess/>
             <Route path='/reports' exact component={Report} isPrivate/>   

            <Route path='/client-portla/dashboard' exact component={ClientPortalDashboard} isPrivate isClientPortal />
            <Route path='/client-portla/personal-information' exact component={PersonalInformation} isPrivate isClientPortal/>
            <Route path='/client-portla/personal-address' exact component={PersonalAddress} isPrivate isClientPortal/>
            <Route path='/client-portla/personal-identifications' exact component={PersonalIdentifications} isPrivate isClientPortal/>
            <Route path='/client-portla/personal-documents' exact component={PersonalDocument} isPrivate isClientPortal/>
            <Route path='/client-portla/customer-to-customer-relations' exact component={PersonalCustomerToCustomerRelations} isPrivate isClientPortal/>
            <Route path='/client-portla/personal-accounts' exact component={PersonalAccounts} isPrivate isClientPortal/>
            <Route path='/client-portla/personal-open-new-account' exact component={OpenNewAccount} isPrivate isClientPortal/> 
            <Route path='/client-portla/myTransactions' exact component={MyTransactions} isPrivate isClientPortal/>
            <Route path='/client-portla/tranferster-accounts' exact component={TranfersterToConnectedAccounts} isPrivate isClientPortal/>
            <Route path='/client-portla/statements' exact component={Statements} isPrivate isClientPortal/>
            <Route path='/client-portla/contact-support' exact component={ContactSupport} isPrivate isClientPortal/>
            <Route path='/client-portla/process-password-change' exact component={ProcessPasswordChange} isPrivate isClientPortal/>
            <Route path='/client-portla/process-password-change' exact component={MyMessages} isPrivate isClientPortal/>

            
            <Route path='/dashboard' exact component={Dashboard} isPrivate />
            <Route path='/team' exact component={Team} isPrivateAdmin isPrivate/>
            <Route path='/client' exact component={Client} isPrivate />
            <Route path='/add-client' exact component={AddClient} isPrivate />
            <Route path='/update-client/:clientId' exact component={updateClient} isPrivate/>
            <Route path='/client/view-client/:clientId' exact component={viewClient} isPrivate/>
          
            <Route path='/client/client-address/:clientId' exact component={clientAddress} isPrivate />
            <Route path='/client/client-address-details/:clientId/:clientAddressId' exact component={clientAddressDetails} isPrivate isGrantAccess/>
            <Route path='/client/add-client-address/:clientId' exact component={addClientAddress} isPrivate isGrantAccess/>
            <Route path='/client/edit-client-address/:clientId/:id' exact component={editClientAddress} isPrivate isGrantAccess/>
            
            <Route path='/client/client-identification/:clientId' exact component={clientIdentification} isPrivate isGrantAccess/>
            <Route path='/client/add-client-identification/:clientId' exact component={addClientIdentification} isPrivate isGrantAccess/>
            <Route path='/client/edit-client-identification/:clientId/:id' exact component={editClientIdentification} isPrivate isGrantAccess/>
            <Route path='/client/client-identification-details/:clientId/:clientIdentificationId' exact component={clientIdentificationDetails} isPrivate isGrantAccess/>

            <Route path='/client/add-client-documents/:clientId' exact component={addClientDocuments} isPrivate isGrantAccess/>
            <Route path='/client/edit-client-document/:clientId/:id' exact component={editClientDocument} isPrivate isGrantAccess/>

            <Route path='/client/add-clients-accounts/:clientId' exact component={addClientsAccounts} isPrivate/>  
            <Route path='/client/edit-clients-account' exact component={editClientsAccounts} isPrivate isGrantAccess/> 
            <Route path='/client/view-clients-account' exact component={viewClientsAccount} isPrivate isGrantAccess/>
           
          
            <Route path='/client/client-documents/:clientId' exact component={clientDocuments} isPrivate/> 
            <Route path='/client/client-accounts/:clientId' exact component={clientAccounts} isPrivate/> 

            <Route path='/client/client-relation/:clientId' exact component={clientRelation} isPrivate/>
            <Route path='/client/add-new-relation/:clientId' exact component={addNewRelation} isPrivate/>
            <Route path='/client/client-relation-details/:clientMasterId/:clientRelationId' exact component={clientRelationDetails} isPrivate/>
            <Route path='/client/transactions/:clientId' exact component={clientTransactions} isPrivate />

            <Route path='/client/client-online-access/:clientId' exact component={clientOnlineAccess} isPrivate/>
            <Route path='/client/client-online-access-details/:clientMasterId/:clientOnlineAccessId' exact component={clientOnlineAccessDetails} isPrivate/> 
            <Route path='/client/edit-client-online-access/:clientMasterId/:clientOnlineAccessId' exact component={editclientOnlineAccess} isPrivate/> 
            <Route path='/client/edit-client-relation/:clientMasterId/:clientRelationId' exact component={editclientRelation} isPrivate/>
            <Route path='/client/print-pdf' exact component={clientPrintPdf} isPrivate isPrint/>

            <Route path='/login' exact component={Login} />
            <Route path='/register' exact component={Register} />
            <Route path='/forgot-password' exact component={ForgotPassword} />
            <Route path='/reset/:token' exact component={Reset} />
            <Route path='/logout' exact component={Logout} isPrivate isGrantAccess />

            <Route path="/reset-password" exact component={ResetpasswordForm} /> 
            <Route path="/update-reset-password/:email" exact component={CustomResetpassword} />
            <Route component={resetURL === 'reset' ? Reset : Login} />  
        
        </Switch>
        </ScrollToTop>
    )
}
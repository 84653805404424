import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Pagination from "react-js-pagination"; 
import RightMenu from './RightMenu';
import TopMenu from "./TopMenu" 
export default class clientTransactions extends Component {


    state = {
        account_type:'',
        account_no:'',
        start_to:'',
        end_to:'',
        validationErrors:{
            accountTypeError:'',
            startToError:'',
            endFromError:''
      
          },
        filterDetails: [], 
        transactionTypes:[], 
        transactionAccounts:[],
        totalTransications:[],
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        pagelaoder:false,
        btnlLoader:false,
    }
    async componentDidMount() { 
        //this.getConfig();
        //this.getClientAccounts();
       
        if (this.props.location.state) {
            //console.log("===================="+this.props.location.state.clientId); 
            //console.log('++++++++++++++++++++', this.props.match.params.clientId)
            //this.OnSearch();
            this.getConfig();
        }else{
          this.props.history.push(`/client`);
        }
        
        
    }
    handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value, 
        }); 
      }
      validate = () => { 
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;  
        let emailError = '';  
        const { validationErrors } = this.state;
        let formvalidateStatus = true; 
    
            // if (this.state.account_type.length < 1) {  
            //     this.setState(Object.assign(this.state.validationErrors,{accountTypeError:'Please Select Account Type'}));
            //     formvalidateStatus = false;
            // }else{
            //     this.setState(Object.assign(this.state.validationErrors,{accountTypeError:''}));
            // } 
            if (this.state.account_no.length < 1) {  
              this.setState(Object.assign(this.state.validationErrors,{accountNoError:'Please Select Account'}));
              formvalidateStatus = false;
            }else{
                this.setState(Object.assign(this.state.validationErrors,{accountNoError:''}));
            } 
    
            // if (this.state.start_to.length < 1) { 
            //     this.setState(Object.assign(this.state.validationErrors,{startToError:'Please Select Start Date'}));
            //     formvalidateStatus = false;
            // }
            // else{
            //     this.setState(Object.assign(this.state.validationErrors,{startToError:''}));
            // }
            // if (this.state.end_from.length < 1) { 
            //     this.setState(Object.assign(this.state.validationErrors,{endFromError:'Please Select End From Date'}));
            //     formvalidateStatus = false;
            // }
            // else{
            //     this.setState(Object.assign(this.state.validationErrors,{endFromError:''}));
            // }
         
        return formvalidateStatus 
      }
    async OnSearch()
    { 
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData); 
            let accessToken = checkToken.access_token; 
            const isValid = this.validate();
            if(isValid){
            //get listing 
                let start_to = '';
                let end_to = '';
                if(this.state.start_to){
                    start_to = moment(moment(this.state.start_to)).format('Y-MM-DD');
                }
                if(this.state.end_to){
                    end_to = moment(moment(this.state.end_to)).format('Y-MM-DD');
                }
                await axios.get(`${window.$URL}transaction/histrory`,{
                    params: {
                        account_type:this.state.account_type,
                        account_no:this.state.account_no,
                        start_to:start_to,
                        end_to:end_to,
                        cm_id:this.props.location.state.clientId,
                    },
                        headers: { "Authorization": `Bearer ${accessToken}` }
                })
                .then(response => {
                
                    if (response.data.status) {

                        let totalCount = response.data.data.transactions.length;
                        let showingFrom = response.data.data.transactions.length > 1 ? 1 : response.data.data.transactions.length;
                        let showingTo = response.data.data.transactions.length > 10 ? 10 : response.data.data.transactions.length;
                        this.setState({
                            totalTransications: response.data.data.transactions, 
                            filterDetails: response.data.data.transactions.slice(0, this.state.pageLimit),
                            pageCount: Math.ceil(response.data.data.transactions.length / this.state.pageLimit),
                            totalCount,
                            showingFrom,
                            showingTo,
                            paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Transactions',
                            pagelaoder:false
                        })

                        if(totalCount==0){
                            toast.success("No result found", {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                })
                .catch(error => {
                 console.error(error.data)
                })
            }
        }  
    }
    async getConfig()
    { 
            let clientId=this.props.match.params.clientId
          
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
                let checkToken = JSON.parse(checkTokenData);
                
                let accessToken = checkToken.access_token; 
                //get listing
                await axios.get(`${window.$URL}getConfigs`,{
                    params: {
                        my_accounts :true, 
                        cm_id:this.props.location.state.clientId, 
                    },
                    headers: { "Authorization": `Bearer ${accessToken}` }
                  })
                .then(response => {
                    
                    if (response.data.status) { 
                        if(response.data.data.transaction_types){
                            this.setState({transactionTypes:response.data.data.transaction_types}); 
                        }
                        if(response.data.data.transaction_accounts){
                            this.setState({transactionAccounts:response.data.data.transaction_accounts}); 
                        }  
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
                 //end listing

            } 
        
    } 
   
    handlePageChange(pageNumber) {
        const { totalTransications, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = totalTransications.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }
    onChangeSearch = (e) => {
        e.preventDefault();
        
        const { name,value } = e.target;   
        this.setState({
            [e.target.name]: e.target.value, 
          })
      
    }
    handleDateChange = (name,date) => {  
        if(date){
          this.setState({
            [name]: moment(moment(date)).format('Y-MM-DD'), 
          })
        }else{
            this.setState({
                [name]: "", 
              })
        }
      }
    


    render() {
        const {            
            transactionTypes,transactionAccounts,filterDetails,paginationCountText,activePage,pageLimit,totalCount
        } = this.state; 
        let transactionTypeOptions = transactionTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );
        let transactionAccountOptions =transactionAccounts.length > 0
        && transactionAccounts.map((item, i) => {
            return (
                <option value={item.account_id}>{item.account}</option>
            ) 
        }, this);
        return (
            <div className="wrapper">
                <ToastContainer />  
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 p-0">
                            <h3 className="heading">Client transactions</h3> 
                            <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_accounts"/> 
                            <div className="text-right p-0 mb-3">
                                    <Link
                                        className="btn btn-primary bluebg"
                                        to={{
                                        pathname: `/client`,  
                                        }}
                                    >Back to Clients</Link>  
                                </div>
                            <div className="content-wrap">
                                <div className="tablebox ">
                                    <form>
                                    {/* <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Account <sup>*</sup></label>
                                        <div className="col-sm-8">
                                        <select className="form-control" id="exampleFormControlSelect1" value={this.state.account_type} name="account_type" onChange={this.handleInputChange}>
                                            <option value="">Please Select an Account</option>
                                            {accountTypeOptions}
                                        </select>
                                        <p className="error">{this.state.validationErrors.accountTypeError}</p>
                                        </div>
                                    </div> */}
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-4 col-form-label">Account Number<sup>*</sup></label>
                                        <div className="col-sm-8">
                                        <select className="form-control" id="exampleFormControlSelect1" value={this.state.account_no} name="account_no" onChange={this.handleInputChange}>
                                            <option value="">Please Select an Account</option>
                                            {transactionAccountOptions}
                                        </select>
                                        <p className="error">{this.state.validationErrors.accountNoError}</p>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-4">From</label> 
                                        <div className="col-sm-8 customdatesm">
                                        <input type="date"  className="form-control d-none" placeholder="yyyy-mm-dd" value={this.state.start_to} name="start_to" onChange={this.handleInputChange}/>
                                            <DatePicker placeholderText="yyyy-mm-dd" maxDate={new Date()} dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.start_to } selected={this.state.start_to && new Date(this.state.start_to)} name="start_to"   onChange={(date) => {
                                                this.handleDateChange('start_to',date); 
                                            }} showYearDropdown
                                            scrollableMonthYearDropdown /> 
                                            <p className="error">{this.state.validationErrors.startToError}</p>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="inputEmail3" className="col-sm-4">Period To</label> 
                                        <div className="col-sm-8 customdatesm"> 
                                            <DatePicker placeholderText="yyyy-mm-dd" maxDate={new Date()} dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.end_to } selected={this.state.end_to && new Date(this.state.end_to)} name="end_to"   onChange={(date) => {
                                                this.handleDateChange('end_to',date); 
                                            }}  showYearDropdown
                                            scrollableMonthYearDropdown/> 
                                              <p className="error">{this.state.validationErrors.endToError}</p>
                                        </div>
                                    </div> 
                                    <div className="row justify-content-center mr-0"> 
                                        <button type="button" onClick={() => this.OnSearch()} className="btn btn-primary mleft7">
                                            <div className="buttonloader"><div className={`loader_wrap ${ this.state.btnLoader ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                            Search
                                        </button> 
                                        {this.state.pdfUrl &&
                                            <a href="javascript:void(0)" onClick={()=> window.open(this.state.pdfUrl, "_blank")} className="btn btn-success" traget="_BLANK">Download</a>
                                        }                 
                                    </div>
                                </form> 
                                </div> 
                                 <div className="tablebox p-0 w-100">
                                    <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                    <table className="table table-bordered table-responsive-sm">
                                        <thead>
                                            <tr> 
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Date</th> 
                                            {/* <th scope="col">Amount</th> */}
                                            <th scope="col">Account Details</th>
                                            {/* <th scope="col">From Currency</th>   */}
                                            <th scope="col">Debit details</th>
                                            <th scope="col">Debit description</th>
                                            <th scope="col">Credit details</th>
                                            <th scope="col">Credit description</th>
                                            <th scope="col" className="bgdarkgreen">Credit</th> 
                                            <th scope="col" className="bgred">Debit</th>
                                            <th scope="col">Balance</th>
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            {
                                                filterDetails.length > 0 ?
                                                    filterDetails.map((data, index) => {

                                                        return (
                                                            <tr> 
                                                                <td>{data.transaction_no}</td>
                                                                <td>{data.transaction_type}</td>
                                                                <td>{data.transaction_date}</td> 
                                                                {/* <td>{data.transaction_amount}</td> */}
                                                                <td>{data.from_account}</td>
                                                                <td>{data.debit_detials}</td>
                                                                <td>{data.debit_description}</td>
                                                                <td>{data.credit_details}</td>
                                                                <td>{data.credit_description}</td>
                                                                {/* <td>{data.transaction_from_currency}</td> */}
                                                                {/* <td>{data.transaction_to_account_no}</td> */}
                                                                {/* <td>{data.transaction_to_currency}</td> */}
                                                                {/* <td>{data.transaction_details}</td> */}
                                                                <td className="textgreen">{data.credit_amount}</td>
                                                                <td className="textred">{data.debit_amount}</td>
                                                                <td>{data.transaction_balance}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={5}>No result found</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                            </div>
                                            <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                <Pagination
                                                    prevPageText='Prev'
                                                    nextPageText='Next'
                                                    firstPageText='First'
                                                    lastPageText='Last'
                                                    activePage={activePage}
                                                    itemsCountPerPage={pageLimit}
                                                    totalItemsCount={totalCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemclassName="page-item"
                                                    linkclassName="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        {/* lefeArea end */}
                        {/* lefeArea start */}
                        <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_accounts"/>  
                        {/* lefeArea start */}
                    </div>
                </div>
            </div> 
        );
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png"
import RightMenu from './RightMenu'
import TopMenu from "./TopMenu" 
 
export default class viewClient extends Component {


    state = {
        role:'',
        clientType: '',
        companyName: '',
        companyNumber: '',
        companyWebsite: '',
        clientTypeError: '',
        companyNameError: '',
        companyNumberError: '',
        companyWebsiteError: '',


        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        title: '',
        dob: '',
        nationality: '',
        firstNameError: '',
        middleNameError: '',
        lastNameError: '',
        genderError: '',
        titleError: '',
        dobError: '',
        nationalityError: '',


        companyRegistration: '',
        vatRef: '',
        tin: '',
        taxRef: '',
        companyType: '',
        companyRegistrationError: '',
        vatRefError: '',
        tinError: '',
        taxRefError: '',
        companyTypeError: '',


        regAddress: '',
        postCode: '',
        city: '',
        country: '',
        createdBy: '',
        createdAt: '',
        lastModificationUser: '',
        lastDateCollection: '',
        lastDateRecollection: '',
        regAddressError: '',
        postCodeError: '',
        cityError: '',
        countryError: '',
        createdByError: '',
        createdAtError: '',
        lastModificationUserError: '',
        lastDateCollectionError: '',
        lastDateRecollectionError: '',


        phoneNumber: '',
        email: '',
        refBy: '',
        phoneNumberError: '',
        emailError: '',
        refByError: '',


        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        token: '',
        pagelaoder:true


    }
    
    deleteCheck = (delteId) => {
        this.setState({
            showSweetAlert: true,
            delteId: delteId,
        });
    };
    confirmDelete(id) {
        this.setState({
            showSweetAlert: false
        }, () => {
            this.deleteFunc()
        })
    }
    onCancel() {
        this.setState({
            showSweetAlert: false,
            requestedId: ''
        })
    }

    deleteFunc = async () => {
        //alert(this.state.delteId);return false;
        const{token}=this.state;

        await axios.delete(`${window.$URL}client/remove-client-address/` + this.state.delteId,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then(response => {
                response.data.status ?
                    this.setState({
                        successMessage: response.data.message,
                        success: true,
                    }, () => {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    })
                    :
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            })
            .catch(error => {
                console.error(error.data)
            })
    }

    async componentDidMount() {

        let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            let apiHeader = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + checkToken.access_token
                }
            };
            // alert(checkToken.access_token);
            //
            let accessToken = checkToken.access_token;

            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            })


            //get listing
            await axios.get(`${window.$URL}client/client-addresses/${this.props.match.params.clientId}`, { headers: { "Authorization": `Bearer ${accessToken}` } })
                .then(response => {
                    //  
                    if (response.data.status) {

                        let totalCount = response.data.data.length;
                        let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                        let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                        this.setState({
                            Details: response.data.data,
                            filterDetails: response.data.data.slice(0, this.state.pageLimit),
                            pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                            totalCount,
                            showingFrom,
                            showingTo,
                            paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Address',
                            pagelaoder:false
                        });
                        if(totalCount==0){
                            toast.error("No result found", {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                          }
                    } 
                })
                .catch(error => {
                    console.error(error.data)
                })
            //end listing
        }

        if (this.props.match.params.hasOwnProperty('clientId')) {
            
        }
    }

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }



    render() {
        const {
            showSweetAlert,
            modalDialog,
            clientType,
            companyName,
            companyNumber,
            companyWebsite,
            firstName,
            middleName,
            lastName,
            gender,
            title,
            dob,
            nationality,
            companyRegistration,
            vatRef,
            filterDetails,
            activePage, totalCount, pageLimit, paginationCountText,

        } = this.state;
        return (
            <div className="wrapper">
                <ToastContainer />
                {showSweetAlert ?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Confirm you wish to delete this item?"
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    :
                    null
                }  
                <div className="container newContainer mainArea pt-0" id="formpage">
                    <div className="row justify-content-center">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-9 p-0">
                            <h3 className="heading">Client's Address</h3>
                            <TopMenu clientId={ this.props.match.params.clientId } activeMenu="client_address"/> 
                            <div className="content-wrap">

                                <div className=" justify-content-center">
                                    {/* lefeArea start */}
                                        <div className="col-12 text-right p-0"> 
                                            <Link
                                                className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/client`,  
                                                }}
                                            >Back to Clients</Link>
                                            <Link
                                               className="btn btn-primary bluebg"
                                                to={{
                                                pathname: `/client/add-client-address/${this.props.match.params.clientId}`, 
                                                clientId:this.props.clientId,
                                                state: {
                                                    clientId: this.props.clientId 
                                                    
                                                }
                                                }}
                                            >
                                                Add New Address</Link> 
                                        </div>
                                        <div className="tablebox p-0 w-100">
                                            <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                            <table className="table table-bordered table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">View</th>
                                                        <th scope="col">Id</th> 
                                                        <th scope="col">Address</th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filterDetails.length > 0 ?
                                                            filterDetails.map((data, index) => {

                                                                return (
                                                                    <tr>
                                                                        <td className="text-center">
                                                                        <Link  to={{
                                                                            pathname: `/client/client-address-details/${this.props.match.params.clientId}/${data.id}`, 
                                                                            state: {
                                                                            clientAddressId: this.props.match.params.clientId  
                                                                            }
                                                                            }}> <button><i className="fa fa-search" /></button></Link> 
                                                                        </td>
                                                                        <td>{index+1}</td> 
                                                                        <td>{data.address_1}</td>
                                                                        <td>{data.city}</td>
                                                                        <td>{data.country}</td>


                                                                        
                                                        <td className="text-center">
                                                        <Link  to={{
                                                                    pathname: `/client/edit-client-address/${this.props.match.params.clientId}/${data.id}`,  
                                                                    state: {
                                                                        clientId: this.props.match.params.clientId 
                                                                        
                                                                      }
                                                                }}>
                                                                <button><i className="fa fa-edit" /></button></Link>
                                                                <button onClick={() => this.deleteCheck(data.id)}><i className="fa fa-trash" /></button>
                                                        </td>



                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={5}>No result found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                                    </div>
                                                    <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                                        <Pagination
                                                            prevPageText='Prev'
                                                            nextPageText='Next'
                                                            firstPageText='First'
                                                            lastPageText='Last'
                                                            activePage={activePage}
                                                            itemsCountPerPage={pageLimit}
                                                            totalItemsCount={totalCount}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange.bind(this)}
                                                            itemclassName="page-item"
                                                            linkclassName="page-link"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                        {/* lefeArea end */}
                        {/* lefeArea start */}
                        <RightMenu clientId={ this.props.match.params.clientId } activeMenu="client_address"/> 
                        {/* lefeArea start */}
                    </div>
                </div>
            </div> 
        );
    }
}

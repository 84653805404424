import React from 'react'
import PropTypes from "prop-types"; 
import '../../assets/css/header_admin.css';
import '../../assets/css/sidebarmenu.css';
import '../../assets/css/style.css'; 
import '../../assets/css/responsive.css'; 

import Header from './Header';
import Footer from './Footer';
import NavbarMenu from './NavbarMenu';

//const deviceWidth = window.innerWidth > 650 ? true : false

export default function ClientLayout({ children }) {
    // const [toggleSidebar, setToggleSidebar] = useState(deviceWidth);

    // const sidebarAction = (val) => {
    //     setToggleSidebar(val);
    // }

    return (
        <>
            
                <div className="wrapper clientportal">
                   <div className="top_header">
                   <Header/> 
                    <NavbarMenu/> 
                    </div>
                    {children}
                    <Footer />
                </div> 
        </>
    )
}

ClientLayout.propTypes = {
    children: PropTypes.element.isRequired
};


import React, { useState } from 'react';

import { format } from 'date-fns';

import moment from "moment";     
const PrintAccountReport = (props) => {  
  const filterDetails = props.accounts;
  const accountTotals = props.totls;
  const accountTypes = props.accountTypes;
  const accountTypeName = ($acType) => {
         
            return accountTypes.find(obj => obj.value === $acType);
        
    }
  
  return (
     <div className="receipt-print-wrap"> 
          <table style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
              <tr style={{ textAlign:'center' }}>
                  <td >
                    <img src={require('../../../assets/images/logo.png')} style={{ width:"150px" }}alt="" className="print-logo" />
                     
                  </td> 
              </tr>
            </tbody>
          </table> 
           <h2 style={{ background:"#0097d4",color:"#fff",padding:"15px",margin:"25px 0px",textAlign:"center" }}> Account Reports</h2>
            <div className="account-total-wrap">
                <h2 className="reportDate">Total Balance as on {moment().format('Y-MM-DD')}</h2>
                <ul className="account-list"> 
                { accountTotals.length > 0 && accountTotals.map((data, index) => { 
                        
                        return ( 
                                <li>
                                    <p><label>{data.currency}</label> <span>{data.total}</span></p>
                                </li> 
                        )
                    })
                }
                 </ul>
            </div>
            <div className="tablebox p-0 mb-3"> 
                 <table className="table table-bordered table-responsive-sm"> 
                     <thead>
                        <tr> 
                            <th scope="col">Client Number</th>
                            <th scope="col">Client Name</th>  
                            <th scope="col">Account Number</th>  
                            <th scope="col">Total Balance</th> 
                            <th scope="col">Available Balance</th> 
                            <th scope="col">Interest Amount</th> 
                            <th scope="col">Blocked</th> 
                        </tr>
                    </thead>
                    <tbody>
                        { filterDetails.length > 0 ? filterDetails.map((data, index) => { 
                            return (
                                <tr> 
                                   
                                    <td>{data.client_no}</td>
                                    <td>{data.client_name }</td>  
                                    <td>{data.account_number}</td>   
                                    <td>{data.currency} {data.balance}</td> 
                                    <td>{data.currency} {data.available}</td> 
                                    <td>{data.currency} {data.interest}</td> 
                                    <td>{data.currency} {data.blocked}</td> 
                                </tr>
                            )
                        })
                    :
                    <tr>
                        <td colSpan={5}>No result found</td>
                    </tr>
                    }     
                    </tbody>
                </table> 
            </div>
        </div> 
  );
}
export default PrintAccountReport
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; 
import PrintComponents from "react-print-components"; 
import PrintAccountReport from "../Component/AccountReport"; 

export default class Reports extends Component {
 
    constructor (props) {
        super(props)
        this.state = {
        name: '',
        role: '',
        birthDay: '',
        fullName: '',
        birthMonth: '',
        birthYear: '',
        email: '',
        password: '',
        gender: '',
        phoneNumber: '',
        birthDayError: '',
        fullNameError: '',
        birthYearError: '',
        emailError: '',
        passwordError: '',
        createdAt: '',
        createdAtError: '',



        firstName: '',
        lastName: '',
        firstNameError: '',
        lastNameError: '',



        phoneNumberError: '',
        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 20,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        searchCompanyName:"",
        searchClintname:"",
        searchCity:"",
        searchCompanyNumber:"",
         searchClintname:"",
        searchClientNumber:"",
        searchFromdate:"",
        searchTodate:"",
        pagelaoder:true,
        searchAccountType:"",
        accountTypes:[],
        accountTotals:[]

        }
    //this.handleDateChange = this.handleDateChange.bind(this); 
    }
        async componentDidMount() {
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;

            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
            localStorage.setItem("relationAndOnlineaccess",false);
        //alert( moment(checkToken.expiration).format('Y-MM-DD hh:mm:ss')); 
            await axios.get(`${window.$URL}getConfigs?account_types=true`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
                .then(response => {
                    console.log(response.data.data.currency, 'response by edit')
                    if (response.data.status) { 
                        if(response.data.data.account_types){
                            this.setState({accountTypes:response.data.data.account_types}); 
                        } 
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            this.OnSearch(); 
        }
    }

   async getclientList()
    {
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        
          //get listing
          await axios.get(`${window.$URL}client`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              
              if (response.data.status) {

                  let totalCount = response.data.data.length;
                  let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                  let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                  this.setState({
                      Details: response.data.data,
                      filterDetails: response.data.data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Users',
                      pagelaoder:false,
                  })
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }  
    onCancel() {
        this.setState({
            showSweetAlert: false,
            requestedId: ''
        })
    }
    confirmDelete(id) {
        this.setState({
            showSweetAlert: false
        }, () => {
            this.deleteFunc()
        })
    }

    deleteCheck = (delteId) => {
        this.setState({
            showSweetAlert: true,
            delteId: delteId,
        });
    };
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }
  

    deleteFunc = async () => {
        //alert(this.state.delteId);return false;
        const{token}=this.state;

        await axios.delete(`${window.$URL}client/remove/` + this.state.delteId,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then(response => {
                response.data.status ?
                    this.setState({
                        successMessage: response.data.message,
                        success: true,
                    }, () => {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    })
                    :
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            })
            .catch(error => {
                console.error(error.data)
            })
    }


    handleSort = (popularCategoryId) => {
        this.setState({
            buttonData: false
        }, () => {
            const { Details } = this.state;
            this.setState({
                UpdateData: false
            }, () => {
                const fildata = Details.filter(data => data._id === popularCategoryId);
                this.setState({
                    EditDetails: fildata,
                    UpdateData: true,
                    popularCategoryId,
                    pageName: fildata[0].pageName,
                })
            })
        })
    } 
    onSort = (e, column) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';

        const sortedData = this.state.Details.sort((a, b) => {
            const nameA = a[column];
            const nameB = b[column];
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            filterDetails: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }

    handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value,
          [`${e.target.name}Error`]: '',
        })
    } 
    handleDateChange = (name,date) => {  
        if(date){
          this.setState({
            [name]: moment(moment(date)).format('Y-MM-DD'), 
          })
        }else{
            this.setState({
                [name]: "", 
              })
        }
      }
    
    
  async OnSearch()
    {
        console.log('test',this.state)
        this.setState({pagelaoder:true});
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        let fromDate = '';
        let to_date = '';
        if(this.state.searchFromdate){
            fromDate = moment(moment(this.state.searchFromdate)).format('Y-MM-DD');
        }
        if(this.state.searchTodate){
            to_date = moment(moment(this.state.searchTodate)).format('Y-MM-DD');
        }
        var params ={
            company_name:this.state.searchCompanyName,
            company_number:this.state.searchCompanyNumber,
            client_number:this.state.searchClientNumber,
            client_name:this.state.searchClintname,
            city:this.state.searchCity,
            from_date:fromDate,
            to_date:to_date,
            account_type:this.state.searchAccountType,
        }
        console.log(params);
          //get listing
          //await axios.get(`${window.$URL}client/search`,{ data: params, headers: {"Authorization" : `Bearer ${accessToken}`}})  
          axios.post(`${window.$URL}client-reports`, params, { headers: { "Authorization": `Bearer ${accessToken}` } })
          .then(response => {
              console.log(response.data.data, 'response')
              if (response.data.status) {
                  const accountList = response.data.data.accounts;
                  const accountTotals = response.data.data.totals;  
                  let totalCount = accountList.length;
                  let showingFrom = accountList.length > 1 ? 1 : accountList.length;
                  let showingTo = accountList.length > this.state.pageLimit ? this.state.pageLimit : accountList.length;
                 
                  this.setState({
                      Details: accountList,
                      filterDetails: accountList.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(accountList.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Users',
                      pagelaoder:false,
                      accountTotals:accountTotals
                  })

                  if(totalCount==0){
                    toast.error("No result found", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                  }
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
   async OnClear(){
      
        this.setState({searchCompanyName:"",
        searchClintname:"",
        searchAccountType:"",
        });
        setTimeout(
            () =>  this.OnSearch(), 
            10
          );
       
    }
    
    accountTypeName($acType){
        const accountTypes = this.state.accountTypes;
        return accountTypes.find(obj => obj.value === $acType);
    }

    render() {
       const { accountTotals,accountTypes,showSweetAlert, modalDialog, name, role, filterDetails, activePage, totalCount, pageLimit, paginationCountText, firstNameError, lastNameError, createdAtError, emailError, passwordError, success, error, successMessage, errorMessage } = this.state;
         let accountTypeOptions = accountTypes.map((item, index) =>
            <option value={item.value}>{item.name}</option>
        );

        return (
            <div className="wrapper">
                <ToastContainer />
                {showSweetAlert ?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Confirm you wish to delete this item?"
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    :
                    null
                } 
                <div className="mainheading text-center">Client View</div>
                <div className="container mainArea clientteaminput" id="formpage">
                    <div className="row">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-12"> 
                            {this.state.role ==='Super_Admin' || this.state.role ==='Team_Member' ?  
                                <div className="text-right mb-2">  
                                    <Link to="/add-client" className="btn btn-primary greybtn">Add Client</Link>  
                                </div>
                            :''
                            }
                            <h4 className="smallheading text-left mb-0">
                                Client Information
                            </h4>  
                           <div className="tablebox mt-0 wrpinput"> 
                                <div className="row"> 
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <label htmlFor="inputEmail3" className="col-sm-12">Client Name</label>
                                            <div className="col-sm-12">
                                                <input type="text" value={this.state.searchClintname} name="searchClintname" className="form-control" id="inputEmail3" onChange={this.handleInputChange} placeholder="Client Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <label htmlFor="inputEmail3" className="col-sm-12">Account Type</label>
                                            <div className="col-sm-12">
                                               <select name="searchAccountType" className="form-control" id="exampleFormControlSelect1" onChange={this.handleInputChange}  value={this.state.searchAccountType}>
                                                    <option value="">Select account type</option>
                                                    {accountTypeOptions}
                                                </select>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                                <div className="row justify-content-center mr-0"> 
                                    <button type="button" onClick={() => this.OnSearch()} className="btn btn-primary mleft7">
                                        <div className="buttonloader"><div class={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                            Search
                                        </button> 
                                        <PrintComponents
                                          trigger={<button className="btn btn-primary bluebg"><div className="buttonloader"><div class={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Print Details</button>}
                                        >
                                            <PrintAccountReport accounts={this.state.Details} totls={accountTotals} accountTypes={accountTypes}/>
                                        </PrintComponents>
                                    <button type="submit" onClick={() => this.OnClear()} className="btn btn-primary">Clear</button> 
                                </div> 
                            </div>
                            <div className="account-total-wrap">
                                <h2 className="reportDate">Total Balance as on {moment().format('Y-MM-DD')}</h2>
                                <ul className="account-list"> 
                                { accountTotals.length > 0 && accountTotals.map((data, index) => { 
                                    console.log('response',data);
                                    const total = parseFloat(data.total);
                                        return ( 
                                                <li>
                                                    <p><label>{data.currency}</label> <span>{total.toFixed(2)}</span></p>
                                                </li> 
                                        )
                                    })
                                }
                                 </ul>
                            </div>
                            <div className="tablebox p-0 mb-3">
                                <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                 <table className="table table-bordered table-responsive-sm"> 
                                     <thead>
                                        <tr> 
                                            <th scope="col">Client Number</th>
                                            <th scope="col">Client Name</th>  
                                            <th scope="col">Account Number</th> 
                                            <th scope="col">Account Type</th>  
                                            <th scope="col">Total Balance</th> 
                                            <th scope="col">Available Balance</th> 
                                            <th scope="col">Interest Amount</th> 
                                            <th scope="col">Blocked</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { filterDetails.length > 0 ? filterDetails.map((data, index) => { 
                                            return (
                                                <tr> 
                                                   
                                                    <td>{data.client_no}</td>
                                                    <td>{data.client_name }</td>  
                                                    <td>{data.account_number}</td>  
                                                    <td>{this.accountTypeName(data.account_type).name}</td> 
                                                    <td>{data.currency} {data.balance}</td> 
                                                    <td>{data.currency} {data.available}</td> 
                                                    <td>{data.currency} {data.interest}</td> 
                                                    <td>{data.currency} {data.blocked}</td> 
                                                </tr>
                                            )
                                        })
                                    :
                                    <tr>
                                        <td colSpan={5}>No result found</td>
                                    </tr>
                                    }     
                                    </tbody>
                                </table>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                        </div>
                                        <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                            <Pagination
                                                prevPageText='Prev'
                                                nextPageText='Next'
                                                firstPageText='First'
                                                lastPageText='Last'
                                                activePage={activePage}
                                                itemsCountPerPage={pageLimit}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                                itemclassName="page-item"
                                                linkclassName="page-link"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* lefeArea end here */}
                    </div>
                </div>
            </div>
 
        );
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import PrintComponents from "react-print-components"; 
import PrintAllClients from "../Component/PrintAllClients"; 

export default class Client extends Component {
 
    constructor (props) {
        super(props)
        this.state = {
        name: '',
        role: '',
        birthDay: '',
        fullName: '',
        birthMonth: '',
        birthYear: '',
        email: '',
        password: '',
        gender: '',
        phoneNumber: '',
        birthDayError: '',
        fullNameError: '',
        birthYearError: '',
        emailError: '',
        passwordError: '',
        createdAt: '',
        createdAtError: '',



        firstName: '',
        lastName: '',
        firstNameError: '',
        lastNameError: '',



        phoneNumberError: '',
        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        searchCompanyName:"",
        searchClintname:"",
        searchCity:"",
        searchCompanyNumber:"",
        searchClientNumber:"",
        searchFromdate:"",
        searchTodate:"",
        pagelaoder:true,

        }
    //this.handleDateChange = this.handleDateChange.bind(this); 
    }
        async componentDidMount() {
            let checkTokenData = localStorage.getItem("userToken");
            if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;

            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
            localStorage.setItem("relationAndOnlineaccess",false);
        //alert( moment(checkToken.expiration).format('Y-MM-DD hh:mm:ss')); 
            this.OnSearch(); 
        }
    }

   async getclientList()
    {
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        
          //get listing
          await axios.get(`${window.$URL}client`,{ headers: {"Authorization" : `Bearer ${accessToken}`}}) 
          .then(response => {
              
              if (response.data.status) {

                  let totalCount = response.data.data.length;
                  let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                  let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                  this.setState({
                      Details: response.data.data,
                      filterDetails: response.data.data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Users',
                      pagelaoder:false,
                  })
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }  
    onCancel() {
        this.setState({
            showSweetAlert: false,
            requestedId: ''
        })
    }
    confirmDelete(id) {
        this.setState({
            showSweetAlert: false
        }, () => {
            this.deleteFunc()
        })
    }

    deleteCheck = (delteId) => {
        this.setState({
            showSweetAlert: true,
            delteId: delteId,
        });
    };
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }
  

    deleteFunc = async () => {
        //alert(this.state.delteId);return false;
        const{token}=this.state;

        await axios.delete(`${window.$URL}client/remove/` + this.state.delteId,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then(response => {
                response.data.status ?
                    this.setState({
                        successMessage: response.data.message,
                        success: true,
                    }, () => {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    })
                    :
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            })
            .catch(error => {
                console.error(error.data)
            })
    }


    handleSort = (popularCategoryId) => {
        this.setState({
            buttonData: false
        }, () => {
            const { Details } = this.state;
            this.setState({
                UpdateData: false
            }, () => {
                const fildata = Details.filter(data => data._id === popularCategoryId);
                this.setState({
                    EditDetails: fildata,
                    UpdateData: true,
                    popularCategoryId,
                    pageName: fildata[0].pageName,
                })
            })
        })
    } 
    onSort = (e, column) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';

        const sortedData = this.state.Details.sort((a, b) => {
            const nameA = a[column];
            const nameB = b[column];
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            filterDetails: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }

    handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value,
          [`${e.target.name}Error`]: '',
        })
    } 
    handleDateChange = (name,date) => {  
        if(date){
          this.setState({
            [name]: moment(moment(date)).format('Y-MM-DD'), 
          })
        }else{
            this.setState({
                [name]: "", 
              })
        }
      }
    
    
  async OnSearch()
    {
       
        this.setState({pagelaoder:true});
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        let fromDate = '';
        let to_date = '';
        if(this.state.searchFromdate){
            fromDate = moment(moment(this.state.searchFromdate)).format('Y-MM-DD');
        }
        if(this.state.searchTodate){
            to_date = moment(moment(this.state.searchTodate)).format('Y-MM-DD');
        }
        var params ={
            company_name:this.state.searchCompanyName,
            company_number:this.state.searchCompanyNumber,
            client_number:this.state.searchClientNumber,
            client_name:this.state.searchClintname,
            city:this.state.searchCity,
            from_date:fromDate,
            to_date:to_date,
        }
       
          //get listing
          //await axios.get(`${window.$URL}client/search`,{ data: params, headers: {"Authorization" : `Bearer ${accessToken}`}})  
          axios.post(`${window.$URL}client/search`, params, { headers: { "Authorization": `Bearer ${accessToken}` } })
          .then(response => {
              
              if (response.data.status) {

                  let totalCount = response.data.data.length;
                  let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                  let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                  this.setState({
                      Details: response.data.data,
                      filterDetails: response.data.data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Users',
                      pagelaoder:false
                  })

                  if(totalCount==0){
                    toast.error("No result found", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                  }
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
   async OnClear(){
        this.setState({searchCompanyName:"",
        searchClintname:"",
        searchCity:"",
        searchCompanyNumber:"",
        searchClientNumber:"",
        searchFromdate:"",
        searchTodate:"",});
        setTimeout(
            () =>  this.OnSearch(), 
            10
          );
       
    }
    viewAction = (client_type,client_name,client_no) => {   
         let relationAndOnlineaccess = client_type === 'Individual' ? true : false;
         localStorage.setItem("relationAndOnlineaccess",relationAndOnlineaccess);
         localStorage.setItem("selectdClientName",client_name); 
         localStorage.setItem("selectdClientNumber",client_no);
     }

    render() {
        const { showSweetAlert, modalDialog, name, role, filterDetails, activePage, totalCount, pageLimit, paginationCountText, firstNameError, lastNameError, createdAtError, emailError, passwordError, success, error, successMessage, errorMessage } = this.state;
        return (
            <div className="wrapper">
                <ToastContainer />
                {showSweetAlert ?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Confirm you wish to delete this item?"
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    :
                    null
                } 
                <div className="mainheading text-center">Client View</div>
                <div className="container mainArea clientteaminput" id="formpage">
                    <div className="row">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-12"> 
                            {this.state.role ==='Super_Admin' || this.state.role ==='Team_Member' ?  
                                <div className="text-right mb-2">  
                                    <Link to="/add-client" className="btn btn-primary greybtn">Add Client</Link>  
                                </div>
                            :''
                            }
                            <h4 className="smallheading text-left mb-0">
                                Client Information
                            </h4>  
                            <div className="tablebox mt-0 wrpinput">
                                {/* <form> */}
                                    <div className="row"> 
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Company Name</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchCompanyName} name="searchCompanyName" className="form-control" id="inputEmail3" onChange={this.handleInputChange} placeholder="Company Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Company Number</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchCompanyNumber} name="searchCompanyNumber" className="form-control" onChange={this.handleInputChange} id="inputEmail3" placeholder="Company Number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Client Name</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchClintname} name="searchClintname" onChange={this.handleInputChange} className="form-control" id="inputEmail3" placeholder="Client Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Client Number</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchClientNumber} name="searchClientNumber" className="form-control" onChange={this.handleInputChange} id="inputEmail3" placeholder="Client Number" />
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-sm-3">
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">City</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchCity} name="searchCity" className="form-control" onChange={this.handleInputChange} id="inputEmail3" placeholder="City" />
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-sm-3">
                                            <div className="row">
                                                <label className="col-sm-12">Date From</label>
                                                <div className="col-sm-12 customdatesm">
                                                     <DatePicker placeholderText="yyyy-mm-dd" maxDate={new Date()} dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.searchFromdate } selected={this.state.searchFromdate && new Date(this.state.searchFromdate)} name="searchTodate"   onChange={(date) => {
                                                        this.handleDateChange('searchFromdate',date); 
                                                    }} showYearDropdown
                                                    scrollableMonthYearDropdown />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="row">
                                                <label className="col-sm-12">to</label>
                                                <div className="col-sm-12 customdatesm">
                                                      <DatePicker placeholderText="yyyy-mm-dd" maxDate={new Date()} dateFormat="yyyy-mm-dd" className="form-control" value={ this.state.searchTodate } selected={this.state.searchTodate && new Date(this.state.searchTodate)} name="searchTodate"    onChange={(date) => {
                                                        this.handleDateChange('searchTodate',date); 
                                                    }}showYearDropdown
                                                    scrollableMonthYearDropdown  />
                                                </div>
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                    {/* <div  className="col-sm-2">Date:</div> */}
                                    <div className="row justify-content-center mr-0"> 
                                        <button type="button" onClick={() => this.OnSearch()} className="btn btn-primary mleft7">
                                            <div className="buttonloader"><div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>
                                                Search
                                            </button>
                                        <PrintComponents
                                          trigger={<button className="btn btn-primary bluebg"><div className="buttonloader"><div class={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><div className="preload"></div> </div></div>Print Details</button>}
                                        >
                                            <PrintAllClients clients={this.state.Details}/>
                                        </PrintComponents>
                                        <button type="submit" onClick={() => this.OnClear()} className="btn btn-primary">Clear</button> 
                                    </div>
                                {/* </form> */}
                            </div>
                            <div className="tablebox p-0">
                                <div className={`loader_wrap ${ this.state.pagelaoder ? 'loader_active' : '' }`}><center><div className="preload"></div></center></div>
                                <table className="table table-bordered table-responsive-sm"> 
                                    <thead>
                                        <tr>
                                            <th scope="col">View</th>
                                            <th scope="col">Id</th>
                                            <th scope="col">Client Number</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">Company Number</th>
                                            <th scope="col">Client Type</th>
                                            <th scope="col">Created on</th>
                                            <th scope="col">Status</th>
                                            {this.state.role ==='Super_Admin' || this.state.role ==='Team_Member' ?  
                                            <th>Action</th>
                                            :''
                                             }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { filterDetails.length > 0 ? filterDetails.map((data, index) => { 
                                            return (
                                                <tr>
                                                    <td className="text-center">
                                                        <Link  onClick={() => this.viewAction(data.client_type,data.client_name,data.client_no)} to={{ 
                                                            pathname: `client/view-client/${data.client_mid}`, 
                                                            state: {
                                                                clientId: data.client_mid,
                                                                relationAndOnlineaccess: data.client_type === 'Individual' ? true : false 
                                                                }
                                                        }}>
                                                        <button><i className="fa fa-search" /></button></Link> 
                                                    </td>
                                                    <td>{data.client_mid}</td>
                                                    <td>{data.client_no}</td>
                                                    <td>{data.client_name }</td>
                                                    <td>{data.company_name }</td>
                                                    <td>{data.company_number }</td>
                                                    <td>{data.client_type}</td>
                                                    <td>{format(new Date(data.created_at), "dd/MM/yyyy kk:mm:ss")}</td>
                                                    <td className="bggreen5">{data.status === 1 || data.status === '1' ?"Active":"Deactivate"}</td>
                                                    {this.state.role ==='Super_Admin' || this.state.role ==='Team_Member' ?  
                                                        <td className="text-center"> 
                                                                            <button onClick={() => this.deleteCheck(data.client_mid)}><i className="fa fa-trash" /></button>
                                                                        {data.get_corporate_master !==null ?
                                                                            <Link  to={{
                                                                                pathname: `/update-client/${data.client_mid}`, 
                                                                                state: {
                                                                                    clientId: data.client_mid 
                                                                                    
                                                                                }
                                                                            }}>
                                                                            <button><i className="fa fa-edit" /></button></Link>
                                                                            :""
                                                                        }
                                                        </td>
                                                    :''
                                                    } 
                                                </tr>
                                            )
                                        })
                                    :
                                    <tr>
                                        <td colSpan={5}>No result found</td>
                                    </tr>
                                    }     
                                    </tbody>
                                </table>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                        </div>
                                        <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                            <Pagination
                                                prevPageText='Prev'
                                                nextPageText='Next'
                                                firstPageText='First'
                                                lastPageText='Last'
                                                activePage={activePage}
                                                itemsCountPerPage={pageLimit}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                                itemclassName="page-item"
                                                linkclassName="page-link"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* lefeArea end here */}
                    </div>
                </div>
            </div>
 
        );
    }
}

import React, { Component } from "react";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import moment from "moment";
import { format } from 'date-fns';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class Team extends Component {


    state = { 
        email: '',
        password: '', 
        createdAt: '', 
        firstName: '',
        lastName: '', 
        middleName:'',
        validationErrors :{ 
            emailError: '',
           // createdAtError: '',
            passwordError: '',
            firstNameError: '',
            lastNameError: '',
            //middleNameError:'',
            phoneNumberError: '',
        },
 
        successMessage: '',
        success: false,
        errorMessage: '',
        error: false,
        emailShow: true,
        mobileShow: false,
        buttonData: false,
        Details: '',
        popularCategoryId: '',
        UpdateData: false,
        EditDetails: [],
        showSweetAlert: false,
        requestedId: '',
        filterDetails: [],
        showItems: '',
        modalDialog: '',
        modalStatus: false,
        sort: {
            column: null,
            direction: 'desc',
        },
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        paginationCountText: '',
        delteId: '',
        userId: '',
        setStartDate: '',
        setEndDate: '',
        searchEmail: '',
        searchFirstName: '',
        token:'',

        createForm:'',
        searchName:'',
        searchFromdate:'',
        searchTodate:'',


    }
    async componentDidMount() {
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        let apiHeader =  {
            headers: {
            'Accept': 'application/json',
            'Authorization':'Bearer '+checkToken.access_token
            }
        };
        // alert(checkToken.access_token);
        //
        let accessToken = checkToken.access_token;

        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token:accessToken
        })
        this.OnSearch();
    }
    }
    
    cancelAction = () => {
        const { buttonData, UpdateData } = this.state;
        if (buttonData) {
            this.setState({
                buttonData: false
            })
        }
        if (UpdateData) {
            this.setState({
                UpdateData: false
            })
        }
    }

    searchChangeSubmit = (e) => {
        e.preventDefault();
        const { Details, pageLimit, totalCount, activePage, setStartDate, setEndDate, searchEmail, searchFirstName, firstName, email } = this.state;
 
        if (setEndDate !== '') {
            var min = new Date(setStartDate).toISOString();
        }
        if (setEndDate !== '') {
            var max = new Date(setEndDate).toISOString();
        }

        let serachedData = Details;
        if (searchFirstName !== '') {
        serachedData = Details.filter((data) => {
            
            return data.first_name.toLowerCase().match(searchFirstName.toLowerCase());
        });
        }
        if(searchEmail !==''){
        serachedData = Details.filter((data) => {
           
            return data.email.toLowerCase().match(searchEmail.toLowerCase())
        });
        }

        if(setStartDate !=='' && setEndDate !==''){
            serachedData = Details.filter((data) => {
               
                return data.created_at >= min && data.created_at <= max
            });
        }
        
        //console.log(searchedCat,'searchedCat')
        const offset = (1 - 1) * pageLimit;
        const filterDetails = serachedData.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + Details.length >= pageLimit ? pageLimit : Details.length;
        const searchCount = serachedData.length;
        let paginationCountText = e.target.value !== "" ? 'Search Result Showing ' + searchCount + ' of ' + totalCount + ' Teams' : 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Teams';

        this.setState({ filterDetails, activePage: 1, showingFrom, showingTo, totalCount, paginationCountText });
    }


    onCancel() {
        this.setState({
            showSweetAlert: false,
            requestedId: ''
        })
    }
    confirmDelete(id) {
        this.setState({
            showSweetAlert: false
        }, () => {
            this.deleteFunc()
        })
    }

    deleteCheck = (delteId) => {
        this.setState({
            showSweetAlert: true,
            delteId: delteId,
        });
    };
    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    onSearchChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    searchChangeValueFirstName = (data) => {
        //const { name, value } = e.target; 

        this.setState({ searchFirstName: data.target.value })
    }
    searchChangeValueEmail = (data) => { 
        // const { name, value } = e.target; 
        this.setState({ searchEmail: data.target.value })
    }
    validate = () => { 
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let emailError = '';
        let passwordError = '';
        let firstNameError = '';
        let lastNameError = '';
        //let middleNameError = '';

        let formvalidateStatus = true; 

        if (this.state.email.length < 1 && this.state.emailShow === true) {
            emailError = "Please enter Email";
        } else if (!regex.test(this.state.email.trim()) && this.state.emailShow === true) {
            emailError = "Please enter a valid Email"
        }      
        if (emailError) { 
            this.setState(Object.assign(this.state.validationErrors,{emailError:emailError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{emailError:''}));
        }
          
        if (this.state.password.length < 1 && this.state.UpdateData === false) {
            passwordError = "Please enter Password";
        } else if (this.state.password.length < 6 && this.state.password.length >= 1) {
            passwordError = "Password should be > 5 characters ";
        }
        if (passwordError) {
            this.setState(Object.assign(this.state.validationErrors,{passwordError:passwordError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{passwordError:''}));
        }

 


        if (this.state.firstName.length < 1) {
            firstNameError = "Please Enter First Name";
        }else if (this.state.firstName.length > 10) {
            firstNameError = "Max 10 characters allowed";
        }
        if (firstNameError) {
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:firstNameError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:''}));
        }  


        if (this.state.lastName.length < 1) {
            lastNameError = "Please Enter Last Name";
        }else if (this.state.lastName.length > 10) {
            lastNameError = "Max 10 characters allowed";
        }

        if (lastNameError) {
            this.setState(Object.assign(this.state.validationErrors,{lastNameError:lastNameError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{lastNameError:''}));
        }
        
        // if (this.state.middleName.length < 1) {
        //     middleNameError = "Please Enter Middle Name";
        // }else if (this.state.middleName.length > 10) {
        //     middleNameError = "Middle name must be 10 characters";
        // }

        // if (middleNameError) {
        //     this.setState(Object.assign(this.state.validationErrors,{middleNameError:middleNameError}));
        //     formvalidateStatus = false;
        // }else{
        //     this.setState(Object.assign(this.state.validationErrors,{middleNameError:''}));
        // }
        return formvalidateStatus
    }


    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const { firstName, lastName,middleName, email, password, createdAt,token,userId } = this.state;



        const isValid = this.validate(); 
        if (isValid) { 
            let teamData = {
                first_name: firstName,
                last_name: lastName,
                middle_name : middleName,
                email: email,
                password: password,
                created_at: createdAt
            }
            let apiURL = `${window.$URL}team/insert`; 
            if(this.state.UpdateData){
                apiURL = `${window.$URL}team/update/`+userId;
                axios.put(apiURL, teamData,{ headers: {"Authorization" : `Bearer ${token}`}}) .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false,
                            createForm:'',
                            first_name: '',
                            last_name: '',
                            middle_name : '',
                            email: '',
                            password: '',
                            created_at: '' 
                        }); 
                        this.OnSearch();
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        });
                        this.setState(Object.assign(this.state.validationErrors,{emailError:response.data.message.email[0]}));                       
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
            }else{
                axios.post(apiURL, teamData,{ headers: {"Authorization" : `Bearer ${token}`}}) .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            success: true,
                            pageName: "",
                            buttonData: false,
                            UpdateData: false,
                            createForm:'',
                            first_name: '',
                            last_name: '',
                            middle_name : '',
                            email: '',
                            password: '',
                            created_at: '' 
                        }); 
                        this.OnSearch();
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong',                           
                        })
                        this.setState(Object.assign(this.state.validationErrors,{emailError:response.data.message.email[0]})); 
                    } 
                })
                .catch(error => {
                    console.error(error.data)
                })
            }   
        }
    } 

    deleteFunc = async () => {
        //alert(this.state.delteId);return false;
        const{token}=this.state;
        await axios.delete(`${window.$URL}team/remove/` + this.state.delteId,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then(response => {
                response.data.status ?
                    this.setState({
                        successMessage: response.data.message,
                        success: true,
                    }, () => {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    })
                    :
                    toast.error('Something Went Wrong', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            })
            .catch(error => {
                console.error(error.data)
            })
    }


    editForm = (userId) => {
        this.setState({
            buttonData: false,
            createForm:'show',
            emailShow:false, 
            first_name: '',
            last_name: '',
            middle_name : '',
            email: '',
            password: '',
            created_at: '' 
            
        }, () => {
            const { Details } = this.state;
            this.setState({
                UpdateData: false, 
            }, () => {
                const fildata = Details.filter(data => data.id === userId);
                this.setState({
                    EditDetails: fildata,
                    UpdateData: true,
                    userId,
                    firstName: (fildata[0].first_name) ? fildata[0].first_name : '',
                    lastName: ( fildata[0].last_name) ?  fildata[0].last_name : '',
                    middleName: (fildata[0].middle_name) ? fildata[0].middle_name : '',
                    email: fildata[0].email,
                    createdAt: moment(fildata[0].created_at).format('YYYY-MM-DD'), 
                })

            })
        })
    }

    cancelupdateAction = () => {
        this.setState({
            UpdateData: false,
            createForm:'',
        })
    } 

    onSort = (e, column) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';

        const sortedData = this.state.Details.sort((a, b) => {
            const nameA = a[column];
            const nameB = b[column];
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            filterDetails: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    handlePageChange(pageNumber) {
        const { Details, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const filterDetails = Details.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ filterDetails, activePage: pageNumber, showingFrom, showingTo });
    }
    async OnSearch()
    { 
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
        let checkToken = JSON.parse(checkTokenData);
        
        let accessToken = checkToken.access_token;
        let fromDate = '';
        let to_date = '';
        if(this.state.searchFromdate){
            fromDate = moment(moment(this.state.searchFromdate)).format('Y-MM-DD');
        }
        if(this.state.searchTodate){
            to_date = moment(moment(this.state.searchTodate)).format('Y-MM-DD');
        }
        var params ={
            name:this.state.searchName,
            email:this.state.searchEmail,
            from_date:fromDate,
            to_date:to_date,
        }
      
          //get listing
          //await axios.get(`${window.$URL}client/search`,{ data: params, headers: {"Authorization" : `Bearer ${accessToken}`}}) 
         // axios.post(`${window.$URL}client/search`, { data: params, headers: {"Authorization" : `Bearer ${accessToken}`}})
         axios({
            method: 'post',
            url: `${window.$URL}team/search`,
            data: params,
            headers: {"Authorization" : `Bearer ${accessToken}`}
            
            })
          .then(response => {
              
              if (response.data.status) {

                  let totalCount = response.data.data.length;
                  let showingFrom = response.data.data.length > 1 ? 1 : response.data.data.length;
                  let showingTo = response.data.data.length > 10 ? 10 : response.data.data.length;
                  this.setState({
                      Details: response.data.data,
                      filterDetails: response.data.data.slice(0, this.state.pageLimit),
                      pageCount: Math.ceil(response.data.data.length / this.state.pageLimit),
                      totalCount,
                      showingFrom,
                      showingTo,
                      paginationCountText: 'Showing ' + showingFrom + ' to ' + showingTo + ' of ' + totalCount + ' Users'
                  })

                  if(totalCount==0){
                    toast.success("No result found", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                  }
              }
          })
          .catch(error => {
              console.error(error.data)
          })
      //end listing

        }
    }
    
    async OnClear(){
        this.setState(
            {             searchName:"",
                searchEmail:"",
                searchFromdate:"",
                searchTodate:""
            });
            setTimeout(
                () => this.OnSearch(), 
                10
              );
        
    }
    async resetForm(){
        this.setState(
            {    
        email: '',
        password: '', 
        createdAt: '', 
        firstName: '',
        lastName: '', 
        middleName:'',
            });
            setTimeout(
                () => this.OnSearch(), 
                10
              );
        
    }

    getEndate = (time) => {
        //console.log(time.target.value,'getEndate'); 
        this.setState({
            setEndDate: time.target.value,


        })

    }
    getStartDate = (time) => {

        // console.log(time.target.value,'getStartDate');


        this.setState({
            setStartDate: time.target.value
        })

    }

    resetListing = async () => {

        this.setState({
            setEndDate: '',
            setStartDate: '',
            searchFirstName: '',
            searchEmail: '',
            addFormAction:'',

        }, () => {
            this.componentDidMount();
        })

    }
  
    addFormAction = (e) =>{
        e.preventDefault();
        if(this.state.createForm === 'show') { 
            this.setState({createForm: '',
                UpdateData:false,
                email: '',
                password: '', 
                createdAt: '', 
                firstName: '',
                lastName: '', 
                middleName:'', 
            });
        } else {
            this.setState({
                createForm: 'show',
                email: '',
                password: '', 
                createdAt: '', 
                firstName: '',
                lastName: '', 
                middleName:'',
            })
       }
    }
    handleDateChange = (name,date) => {  
        if(date){
          this.setState({
            [name]: moment(moment(date)).format('Y-MM-DD'), 
          })
        }else{
            this.setState({
                [name]: "", 
              })
        }
      }
    render() {
        const { showSweetAlert, modalDialog, name, role, filterDetails, activePage, totalCount, pageLimit, paginationCountText, firstNameError, lastNameError, emailError, passwordError, success, error, successMessage, errorMessage } = this.state;
        
        return (
            <div className="wrapper">
                <ToastContainer />
                {showSweetAlert ?
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Confirm you wish to delete this item?"
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    :
                    null
                } 
                <div className="mainheading text-center">Team View</div> 
                <div className="container mainArea clientteaminput" id="formpage">
                    <div className="row tableboxwrp">
                        {/* lefeArea start */}
                        <div className="lefeArea col-md-12">
                    <div className="text-right mb-2">
                        { this.state.createForm == '' && this.state.role === 'Super_Admin'  &&
                                    <button type="button" className="btn btn-primary greybtn" onClick={this.addFormAction}>Add Team</button>
                        }
                    </div>
                            <div className= {`collapse ${this.state.createForm}`} id="collapseExample">  
                                <h4 className="smallheading mb-0">
                                    New Team Member
                                    <button type="button" className="btn btn-primary greybtn right" onClick={this.addFormAction}>Close</button> 
                                </h4>  
                                <div className="tablebox ">
                                        <form onSubmit={this.onSubmit}> 
                                                <div className="row"> 
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-12">First Name <sup>*</sup></label>
                                                            <div className="col-sm-12">
                                                                <input type="text" value={this.state.firstName} className="form-control" onChange={this.onChange} name="firstName" id="inputEmail3" placeholder="First Name" /> 
                                                                <p className="error">{this.state.validationErrors.firstNameError}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-12">Middle Name </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" value={this.state.middleName} className="form-control" onChange={this.onChange} name="middleName" id="inputEmail3" placeholder="Middle Name" /> 
                                                            
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-12">Last name <sup>*</sup></label>
                                                            <div className="col-sm-12">
                                                                <input type="text" value={this.state.lastName} className="form-control" onChange={this.onChange} name="lastName" id="inputEmail3" placeholder="Last name" />
                                                                <p className="error">{this.state.validationErrors.lastNameError}</p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-12">Email <sup>*</sup></label>
                                                            <div className="col-sm-12">
                                                                <input type="email" value={this.state.email} className="form-control" name="email" onChange={this.onChange} id="inputEmail3" placeholder="Email"/>
                                                                <p className="error">{this.state.validationErrors.emailError}</p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-sm-4">
                                                        <div className="row">
                                                            <label htmlFor="inputEmail3" className="col-sm-12">Password</label>
                                                            <div className="col-sm-12">
                                                                <input type="password" value={this.state.password} className="form-control" name="password" onChange={this.onChange} id="inputEmail3" placeholder="Password" />
                                                                <p className="error">{this.state.validationErrors.passwordError}</p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div className="row justify-content-center mt-3"> 
                                                    <button type="submit" className="btn btn-primary">
                                                        Save
                                                    </button>
                                                    <button type="reset" onClick={() => this.resetForm()} className="btn btn-primary">Clear</button> 
                                                </div>
                                        </form>
                                    </div> 
                             </div> 
                            <h4 className="smallheading mb-0">
                                Team Information
                            </h4>  
                            <div className="tablebox mt-0">
                                {/* <form onSubmit={this.searchChangeSubmit}> */} 
                                    <div className="row"> 
                                        <div className="col-sm-3"> 
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Name</label>
                                                <div className="col-sm-12">
                                                    <input type="text" value={this.state.searchName} name="searchName" onChange={this.onSearchChange} className="form-control" id="inputEmail3" placeholder="Name" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col-sm-3">  
                                            <div className="row">
                                                <label htmlFor="inputEmail3" className="col-sm-12">Email</label>
                                                <div className="col-sm-12">
                                                    <input type="email" className="form-control" value={this.state.searchEmail} name="searchEmail" onChange={this.onSearchChange} id="inputEmail3" placeholder="Email" />
                                                </div>
                                            </div>
                                            </div>
                                    
                                            <div className="col-sm-3">
                                                <div className="row">
                                                    <label className="col-sm-12">Date From</label>
                                                    <div className="col-sm-12 customdatesm">
                                                        <input type="date" id="datepicker" value={this.state.searchFromdate} name="searchFromdate" className="form-control d-none" onChange={this.handleInputChange} placeholder="yyyy-mm-dd" />
                                                        <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" className="form-control " value={ this.state.searchFromdate } selected={this.state.searchFromdate && new Date(this.state.searchFromdate)} name="searchTodate"   onChange={(date) => {
                                                            this.handleDateChange('searchFromdate',date); 
                                                        }}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="row">
                                                    <label className="col-sm-12">to</label>
                                                    <div className="col-sm-12 customdatesm">
                                                        <input type="date" id="datepicker2" value={this.state.searchTodate} name="searchTodate" className="form-control d-none" onChange={this.handleInputChange} placeholder="yyyy-mm-dd" />
                                                        <DatePicker placeholderText="yyyy-mm-dd" dateFormat="yyyy-mm-dd" className="form-control" value={ this.state.searchTodate } selected={this.state.searchTodate && new Date(this.state.searchTodate)} name="searchTodate"    onChange={(date) => {
                                                            this.handleDateChange('searchTodate',date); 
                                                        }}  />
                                                    </div>
                                                </div>
                                            </div>
                                    </div>  
                                    <div className="row justify-content-center mt-3">
                                
                                        <button type="submit" onClick={() => this.OnSearch()} className="btn btn-primary mleft7">
                                            Search
                                        </button>
                                        <button type="button" onClick={() => this.OnClear()} className="btn btn-primary">Clear</button> 
                                    </div>
                                {/* </form>- */}
                            </div>   
                            <div className="tablebox p-0">
                                <table className="table table-bordered table-responsive-sm">
                                    <thead>
                                        <tr> 
                                            <th scope="col">Id</th>
                                            <th scope="col">Name</th> 
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Email id</th> 
                                            <th scope="col">Created on</th>
                                            {this.state.role ==='Super_Admin' || this.state.role ==='Sub_Admin' ?  
                                            <th scope="col">Action</th>
                                            :''
                                            }
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {
                                            filterDetails.length > 0 ?
                                                filterDetails.map((data, index) => {

                                                    return (
                                                        <tr>

                                                            <td>{index+1}</td>
                                                            <td>{data.first_name}</td>
                                                            {/* <td>lorem ipsum</td> */}

                                                            <td>{data.last_name}</td>
                                                            <td>{data.email}</td>
                                                            {/* <td>{data.password}</td> */}
                                                            {/* <td>{data.created_at}</td> */}
                                                            <td>{format(new Date(data.created_at), 'dd/MM/yyyy kk:mm:ss')}</td>
                                                            {this.state.role ==='Super_Admin' || this.state.role ==='Sub_Admin' ?  
                                                            <td className="text-center">
                                                                <button onClick={() => this.deleteCheck(data.id)}><i className="fa fa-trash" /></button>
                                                                <button onClick={() => this.editForm(data.id)}><i className="fa fa-edit" /></button>
                                                            </td>
                                                            :''
                                                            }
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>No result found</td>
                                                </tr>
                                        } 
                                    </tbody>
                                </table>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dataTables_info" role="status" aria-live="polite">{paginationCountText}</div>
                                        </div>
                                        <div className="col-md-6 pagination float-right" style={{ justifyContent: 'flex-end' }}>
                                            <Pagination
                                                prevPageText='Prev'
                                                nextPageText='Next'
                                                firstPageText='First'
                                                lastPageText='Last'
                                                activePage={activePage}
                                                itemsCountPerPage={pageLimit}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                                itemclassName="page-item"
                                                linkclassName="page-link"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* lefeArea end */}
                    </div>
                </div>
            </div> 
        );
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png";
 
export default class editProfile extends Component {


    state = {
        token:'',
        role:'',
        email: '', 
        firstName: '',
        lastName: '', 
        middleName:'', 
        phone:'', 
        validationErrors :{ 
            emailError: '', 
            firstNameError: '',
            lastNameError: '', 
            phoneNumberError: '',
        },

    }
   
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
            this.getUserDetails();
        } 
    } 
    async getUserDetails()
    { 
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
    
            let accessToken = checkToken.access_token;
    
            //get listing 
            await axios.get(`${window.$URL}view-profile`,{
                params: { 
                },
                headers: { "Authorization": `Bearer ${accessToken}` }
            })
            .then(response => {
                
                if (response.data.status) {  
                    this.setState({
                        email:response.data.data.email,
                        firstName:response.data.data.first_name,
                        lastName:response.data.data.last_name,
                        middleName:response.data.data.middle_name, 
                        phone: (response.data.data.phone) ? response.data.data.phone : '', 
                        }); 
                }
            })
            .catch(error => {
                console.error(error.data)
            })
                //end listing

        } 
    }
    onChange = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target; 
         const { user } = this.state;
         this.setState({ 
            [name]: value
        });
        
    } 
    validate = () => { 
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        const{ user } = this.state; 
        let emailError = '';
        let passwordError = '';
        let firstNameError = '';
        let lastNameError = '';
        //let middleNameError = '';

        let formvalidateStatus = true; 
  
        if (this.state.firstName.length < 1) {
            firstNameError = "Please Enter First Name";
        }else if (this.state.firstName.length > 10) {
            firstNameError = "Max 10 characters allowed";
        }
        if (firstNameError) {
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:firstNameError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{firstNameError:''}));
        }  


        if (this.state.lastName.length < 1) {
            lastNameError = "Please Enter Last Name";
        }else if (this.state.lastName.length > 10) {
            lastNameError = "Max 10 characters allowed";
        }

        if (lastNameError) {
            this.setState(Object.assign(this.state.validationErrors,{lastNameError:lastNameError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{lastNameError:''}));
        }

        if (this.state.phone.length < 1) { 
            this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:'Please Enter Phone Number'}));
             formvalidateStatus = false;
        }else if (this.state.phone.length < 10) {
                this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:'Please Enter Correct Phone Number'}));
                formvalidateStatus = false;
        }
        else{
            this.setState(Object.assign(this.state.validationErrors,{phoneNumberError:''}));
        } 
   
        return formvalidateStatus
    }
    onSubmit = (e) => {
        e.preventDefault();
        // alert('gii');return false;
        const { token,userId } = this.state;



        const isValid = this.validate(); 
        if (isValid) {
          
            let teamData = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                middle_name : this.state.middleName, 
                phone:this.state.phone, 
            };
            
                let apiURL = `${window.$URL}update-profile`;
                axios.post(apiURL, teamData,{ headers: {"Authorization" : `Bearer ${token}`}}) .then(response => {
                    
                    if (response.data.status) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            successMessage: response.data.message,
                            
                        }); 
                        this.props.history.push(`/profile`);
                      
                    } else {
                        toast.error('Something Went Wrong', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            error: true,
                            errormessage: 'Something Went Wrong'
                        });
                        this.setState(Object.assign(this.state.validationErrors,{emailError:response.data.message.email[0]}));                       
                    }
                })
                .catch(error => {
                    console.error(error.data)
                })
             
        }
    } 

    render() {
        const { 
            userId,
            documentType,            
            documentNumber,
            country,
            success,
            error,
            successMessage,
            errorMessage
        } = this.state;
        return (
            <div className="wrapper">
              <ToastContainer />  
              <div className="container newContainer mainArea pt-0" id="formpage">
                <div className="row justify-content-center"> 
                  <div className="lefeArea col-md-9 p-0">  
                    <div className="content-wrap">
                      <div className="row justify-content-center"> 
                        <div className="lefeArea col-md-6">  
                          <h4 className="smallheading mb-0">Profile Details</h4>
                          <div className="tablebox">  
                                <form onSubmit={this.onSubmit}> 
                                    <div className="form-group row">
                                        <label for="" className="col-sm-4 col-form-label">Email </label>
                                        <div className="col-sm-8">
                                            <input type="email" className="form-control" value={this.state.email} disabled/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="" className="col-sm-4 col-form-label">First Name <sup>*</sup></label>
                                        <div className="col-sm-8">
                                            <input type="text" name="firstName" onChange={this.onChange} className="form-control" value={this.state.firstName} />
                                            <p className="error">{this.state.validationErrors.firstNameError}</p>
                                        </div>
                                    </div>  
                                    <div className="form-group row">
                                        <label for="" className="col-sm-4 col-form-label">Middle Name </label>
                                        <div className="col-sm-8">
                                        <input type="text" name="middleName" onChange={this.onChange} className="form-control" value={this.state.middleName}/>
                                        </div>
                                    </div> 
                                    <div className="form-group row">
                                        <label for="" className="col-sm-4 col-form-label">Last Name <sup>*</sup> </label>
                                        <div className="col-sm-8">
                                            <input type="text" name="lastName" onChange={this.onChange} className="form-control" value={this.state.lastName}/>
                                            <p className="error">{this.state.validationErrors.lastNameError}</p>
                                        </div>
                                    </div> 
                                    <div className="form-group row">
                                        <label for="" className="col-sm-4 col-form-label">Phone <sup>*</sup> </label>
                                        <div className="col-sm-8">
                                            <input type="tel" name="phone" onChange={this.onChange} className="form-control" value={this.state.phone}/>
                                            <p className="error">{this.state.validationErrors.phoneNumberError}</p>
                                        </div>
                                    </div>
                                
                                <div className="form-group row text-center mt-2 ml-auto">
                                    <div className="col-sm-12">
                                        <div className="m-auto buttonwrp text-center">  
                                            <button type="submit" className="btn btn-primary greybtn">Update</button>  
                                        </div>
                                    </div>
                                </div>
                            </form>
                          </div> 
                        </div>  
                      </div>
                    </div> 
                  </div> 
                </div>
              </div> 
            </div> 
        );
    }
}
